import React from "react";
import { transformLimitToReadable } from "../../api/UserService";
import { useSelector } from "react-redux";
import { IStore } from "../../redux/store/IStore";

const WarnMessageContent = () => {
    const activeQuota = useSelector((store: IStore) => store.activeQuota);
    
    return (
        <div>
        {activeQuota && <p>{`Dosegli ste dogovorjeno količino časa uporabe storitve ${transformLimitToReadable(activeQuota.key, activeQuota.chunk.secondsLimit ? activeQuota.chunk.secondsLimit : 0)}.`}</p>}
        <p>Nadaljnja uporaba se obračunana po ceniku. <a className='warn-snackbar-mail' href="mailto:info@vitasis.si">info@vitasis.si</a></p>
        </div>
    )
}

export default WarnMessageContent;