import { IWordData } from '../components/Editor/IEditor';

export default function genStringFromWords(words: IWordData[]) {
  let newText = '';
  try {
    words.forEach(
      (w: IWordData, i: number) =>
        (newText += `${w.spaceBefore && i !== 0 ? ' ' : ''}${w.updatedText ? w.updatedText : w.text}`)
    );
  } catch (err) {
    return null;
  }

  return newText;
}
