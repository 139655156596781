import React from 'react';
import './../../styles/css/search.css';
import { convertStatusToSlovenian } from '../../shared/helpers';
import { SessionState } from './ISearch';
type IProps = {
  label: string;
  index: number;
  deleteCallback: (index: number) => void;
  filteringSessionsState: boolean;
};

const SearchToken = ({
  label,
  index,
  deleteCallback,
  filteringSessionsState
}: IProps) => {
  return (
    <div className="searchToken" onClick={() => deleteCallback(index)}>
      <span> {filteringSessionsState ? convertStatusToSlovenian(label as SessionState) : label}</span>
    </div>
  );
};

export default SearchToken;
