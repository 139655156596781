import React from 'react';
import { IContextString } from './ContextLine';

interface Props {
  contextString: IContextString;
}
export default function ContextString({ contextString }: Props) {
  return (
    <div className="ctx_string main_phrase_wrapper">
      <span className="main_phrase">
        <span className="context_left_phrase">{contextString.contextLeft} </span>
        <span style={{ color: '#2b8dff', fontWeight: 'bold' }}>{contextString.mainPhrase} </span>
        <span className="context_right_phrase">{contextString.contextRight}</span>
      </span>
    </div>
  );
}
