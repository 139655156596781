import React, { useState } from 'react';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Zoom from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleOutlineOutlined from '@material-ui/icons/AddCircleOutlineOutlined';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import { useDispatch, useSelector } from 'react-redux';
import { ISessionLabel, IStore } from '../../redux/store/IStore';
import { registerNewClientLabel, getClientLabels, patchClientLabel } from '../../api/SessionsService';
import { setSessionsLabels } from '../../redux/actions/actions';

export const useStylesEditMode = makeStyles(() =>
  createStyles({
    paddingoutline: {
      '& .MuiOutlinedInput-input': {
        padding: '8px',
      },
    },
    roundedcornes: {
      borderRadius: '5px !important',
    },
    paper: {
      zIndex: 30,
      background: '#FAFAFA',
    },
    menuItem: {
      display: 'flex',
      cursor: 'default',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    deleteButton: {
      width: 30,
      height: 30,
      marginLeft: 5,
    },
    deleteIcon: {
      '&:hover': {
        color: 'red',
      },
    },
  })
);

const tempColours = ['#264653', '#2a9d8f', '#e9c46a', '#f4a261', '#e76f51'];

const AddLabelsButton = () => {
  const classes = useStylesEditMode();
  const dispatch = useDispatch();

  const [newLabel, setNewLabel] = useState<string>('');
  const [anchorElAddLabel, setAnchorElAddLabel] = useState<null | HTMLElement>(null);

  const availableLabels = useSelector((state: IStore) => state.sessionsLabels) as ISessionLabel[];

  const addButtonOpen = Boolean(anchorElAddLabel);
  const addButtonId = addButtonOpen ? 'simple-popper' : undefined;

  const generateColor = () => {
    return tempColours[availableLabels.length % tempColours.length];
  };

  const clickAwayHandler = () => {
    if (anchorElAddLabel) setAnchorElAddLabel(null);
  };

  const addLabelClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorElAddLabel) {
      const target = event.currentTarget;
      getClientLabels().then(({ data }) => {
        dispatch(setSessionsLabels(data));
        setAnchorElAddLabel(target);
      });
    } else setAnchorElAddLabel(null);
  };

  const registerNewDefaultLabel = () => {
    const index = availableLabels.findIndex(({ code }) => code === newLabel);

    if (index !== -1) {
      patchClientLabel(availableLabels[index].id, { isDefault: true }).then(() => {
        setNewLabel('');
        getClientLabels().then(({ data }) => dispatch(setSessionsLabels(data)));
      });
    } else {
      registerNewClientLabel(newLabel, generateColor(), true).then(() => {
        setNewLabel('');
        getClientLabels().then(({ data }) => dispatch(setSessionsLabels(data)));
      });
    }
  };

  const deleteDefaultLabel = (id) => {
    patchClientLabel(id, { isDefault: false }).then(() => {
      getClientLabels().then(({ data }) => dispatch(setSessionsLabels(data)));
    });
  };

  return (
    <>
      <button  onClick={addLabelClick}>
        <img src="more.svg" alt="Info svg" style={{width: 24, height: 24}} />
      </button>
      <Popper
        id={addButtonId}
        open={addButtonOpen}
        anchorEl={anchorElAddLabel}
        placement="right"
        disablePortal={false}
        style={{ zIndex: 100 }}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
        }}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={clickAwayHandler}>
            <Zoom {...TransitionProps} timeout={350}>
              <Paper
                elevation={3}
                className="accordion-chip-popper-container"
                style={{ marginLeft: '-5px' }}
                square={false}
                classes={{ rounded: classes.roundedcornes, root: classes.paper }}
              >
                {availableLabels
                  .filter(({ isDefault }) => isDefault)
                  .map(({ id, code, color }) => (
                    <MenuItem
                      key={id}
                      dense
                      classes={{ root: classes.menuItem }}
                      onClick={(e) => e.stopPropagation()}
                      disableRipple
                    >
                      <div className="accordion-chip-popper-dot" style={{ backgroundColor: color }} />
                      {code}

                      <div style={{ flexGrow: 1 }} />

                      <Tooltip title={`Odstrani privzeto labelo '${code}'`}>
                        <IconButton
                          aria-label="delete"
                          onClick={() => deleteDefaultLabel(id)}
                          classes={{ root: classes.deleteButton }}
                        >
                          <DeleteOutlinedIcon fontSize="small" classes={{ root: classes.deleteIcon }} />
                        </IconButton>
                      </Tooltip>
                    </MenuItem>
                  ))}

                <div className="accordion-chip-popper-footer">
                  <AddCircleOutlineOutlined
                    fontSize="small"
                    className="accordion-chip-popper-footer-add"
                    style={{ color: generateColor() }}
                  />
                  <TextField
                    fullWidth
                    placeholder="Nova labela"
                    value={newLabel}
                    spellCheck={false}
                    onChange={(e) => setNewLabel(e.target.value)}
                    variant="outlined"
                    classes={{ root: classes.paddingoutline }}
                    onKeyPress={(ev) => {
                      if (ev.key === 'Enter') registerNewDefaultLabel();
                    }}
                  />
                </div>
              </Paper>
            </Zoom>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default AddLabelsButton;
