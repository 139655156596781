import React, { useState, useEffect } from 'react';
import TableHeader from './TableHeader';
import { ColumnTypes, ISessionEntry } from './IDashboard';
import TableRow from './TableRow';
import ColumnMenuContext from './ColumnMenuContext';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardRefreshState, IHeader, IHeaderColumn, IStore } from '../../redux/store/IStore';
import { IAppliedFilter } from '../DashboardHeader/ISearch';

import '../../styles/css/dashboard.css';
import { setDashboardHEaderFilter, setDashboardTableRefresh } from '../../redux/actions/actions';

interface IProps {
  rows: ISessionEntry[];
  handleRowClick: (id: number, sessionName: string) => void;
}

const Table = ({ rows, handleRowClick }: IProps) => {
  const dispatch = useDispatch();

  const [headerOrder, setHeaderOrder] = useState<{ id: string; type: ColumnTypes }[]>([]);

  const appliedFilters = useSelector((state: IStore) => state.dashboardFilters) as IAppliedFilter[];
  const header = useSelector((store: IStore) => store.dashboardHeader) as IHeader;
  const tableRefresh = useSelector((store: IStore) => store.dashboardTableRefresh) as DashboardRefreshState;

  useEffect(() => {
    dispatch(setDashboardHEaderFilter(appliedFilters));
  }, [appliedFilters, dispatch]);

  useEffect(() => {
    if (tableRefresh === DashboardRefreshState.NONE && headerOrder.length !== 0) return;

    if (!header) return;

    setHeaderOrder(
      (Object.values(header) as IHeaderColumn[])
        .filter(({ shown }: IHeaderColumn) => shown)
        .sort(({ order: a }, { order: b }) => a - b)
        .map(({ id, type }) => ({
          id,
          type,
        }))
    );

    dispatch(setDashboardTableRefresh(DashboardRefreshState.NONE));
  }, [tableRefresh, dispatch, header, headerOrder.length]);


  return (
    <div id='table' className="table-wrapper table-container">
      <ColumnMenuContext />
      <TableHeader />
      {rows.map((row) => <TableRow key={row.id} handleRowClick={handleRowClick} row={row} headerOrder={headerOrder} />)}
    </div>
  );
};

export default Table;
