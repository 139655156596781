import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IStore, UserRoleEnums } from '../redux/store/IStore';

export default function useIsRole(role: UserRoleEnums) {
  const roles = useSelector((state: IStore) => state.user?.userRoles);
  const [isRole, setIsRole] = useState<boolean>(false);

  useEffect(() => {
    if (!roles) return;

    setIsRole(roles.includes(role));
  }, [roles, role]);

  return [isRole];
}
