import React, { ChangeEvent } from "react";
import './speaker_modal_input.css';

interface ISpeakerModalInputProps {
    value: string;
    callback: (event: ChangeEvent<HTMLInputElement>) => void;
}
const SpeakerModalInput = ({
    value,
    callback
}: ISpeakerModalInputProps) => {
    return (
        <input
            className='speaker_setting_input'
            type='number'
            value={value}
            placeholder='neznano'
            min="0"
            onChange={callback} />
    )
}

export default SpeakerModalInput;