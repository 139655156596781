import { useRef } from 'react';
import { Editor as DraftEditor } from 'draft-js';

export default function useEditorRefs() {
  const editorRef = useRef<DraftEditor>(null);
  const topScrollerRef = useRef<HTMLDivElement>(null);
  const isInFindMode = useRef<boolean>(false);
  const trCounter = useRef<number>(0);
  const isNewRecordingSession = useRef<boolean>(true);
  const hasEditorContentChanged = useRef<boolean>(false);

  return {
    editorRef,
    topScrollerRef,
    isInFindMode,
    trCounter,
    isNewRecordingSession,
    hasEditorContentChanged,
  };
}
