import React from 'react';

export function DoubleArrow({ fill, height, width, ...props }: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : '21px'}
      height={height ? height : '19px'}
      viewBox="0 0 21.233 19"
    >
      <path
        id="chevron-double-right"
        d="M5.59,8.233,7.823,6l9.5,9.5L7.823,25,5.59,22.768,12.842,15.5,5.59,8.233m9.5,0L17.323,6l9.5,9.5-9.5,9.5L15.09,22.768,22.342,15.5Z"
        transform="translate(-5.59 -6)"
        fill={fill}
      />
    </svg>
  );
}
