import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../redux/store/IStore';

export default function useSelectedModelData() {
  const transcriptionLanguage = useSelector((state: IStore) => state.config?.stt.language.value);
  const transcriptionDomain = useSelector((state: IStore) => state.config?.stt.domain.value);
  const transcriptionModelVersion = useSelector((state: IStore) => state.config?.stt.model.value);

  return useMemo(() => {
    return transcriptionLanguage && transcriptionModelVersion && transcriptionDomain
      ? {
          language: transcriptionLanguage,
          modelVersion: transcriptionModelVersion,
          domain: transcriptionDomain,
        }
      : null;
  }, [transcriptionLanguage, transcriptionModelVersion, transcriptionDomain]);
}
