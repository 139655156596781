import { AxiosResponse } from "axios";
import { config } from "../config";
import { API, CLIENT, UPLOAD } from "./endpoints";
import axiosInstance from './axios';
import store from "../redux/store";

const backendUrl: string = config.backendApiUrl;
const baseUrl = `${backendUrl}/${API}/${CLIENT}/${UPLOAD}?async=true`;

export const processFile = (file: any, updateProgress: (value: number) => void): Promise<AxiosResponse<any>> => {
    var data = new FormData();
    data.append("file", file)
    const {
        numberOfSpeakers,
        minNumberOfSpeakers,
        maxNumberOfSpeakers
    } = store.getState().speakerSettingsModal
    const config = store.getState().config

    let queryParameters = ""
    if (config && config.stt.enableDiarization.value && numberOfSpeakers) {
        queryParameters += `&minSpeakers=${numberOfSpeakers}&maxSpeakers=${numberOfSpeakers}`
    } else if (config && config.stt.enableDiarization.value) {
        if (minNumberOfSpeakers) queryParameters += `&minSpeakers=${minNumberOfSpeakers}`
        if (maxNumberOfSpeakers) queryParameters += `&maxSpeakers=${maxNumberOfSpeakers}`
    }
    
    return axiosInstance.post(`${baseUrl}${queryParameters}`, data, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        //timeout: 1000 * 60 * 10,
        onUploadProgress: (event) => {
            updateProgress(event.loaded/event.total)
        },
    });
};