/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';

interface ErrorData {
  id: string;
  message?: string;
  timestamp: string;
}

interface ErrorsMap {
  [x: string]: { en: string; si: string };
}

const ignoredEp = ['regeneration/status', 'words/count', 'stt/status'];

const errorsMap: ErrorsMap = {
  // 400: { en: '404', si: '' },
  401: {
    en: 'Seja je potekla. Prosimo prijavite se znova.',
    si: 'Seja je potekla. Prosimo prijavite se znova.',
  },
  403: { en: 'Za izbrano zahtevo nimate pravic', si: 'Za izbrano zahtevo nimate pravic' },
  404: { en: 'Zahtevana vsebina ne obstaja', si: 'Zahtevana vsebina ne obstaja' },
  // 405: { en: 'Zahtevana vsebina ne obstaja', si: 'Zahtevana vsebina ne obstaja' },
  409: { en: 'Izbrana vsebina že obstaja', si: 'Izbrana vsebina že obstaja' },
  415: { en: 'Izbran tip media datoteke ni podprt', si: 'Izbran tip media datoteke ni podprt' },
  500: { en: 'Internal Server Error', si: 'Interna napaka v zalednem sistemu' },
};

const lang = 'si';

const validateEp = (url: string) => {
  if (!url.length) return;

  if (typeof url === 'string' && url.endsWith('/token'))
    return {
      ignoreError: true,
    };

  const s = url.split('/').slice(-2);

  if (s[0] === 'dmodels' || ignoredEp.includes(s.join('/'))) {
    return {
      ignoreError: true,
    };
  }

  return {
    ignoreError: false,
  };
};

export default function useAxiosErrorListener() {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const handleError = (e) => {
      const { detail } = e;
      // console.log(e);
      if (!detail) return;
      const { data, config, status } = detail;

      // console.log(JSON.parse(config.data));

      if (data as ErrorData) {
        // data.message && console.log(data.message);
        // data.id && console.log(data.id);
      }
      const validateData = validateEp(config.url);

      if (!validateData || validateData.ignoreError) return;

      if (errorsMap[status] && errorsMap[status][lang]) {
        enqueueSnackbar(`${errorsMap[status][lang]} ${data.id ? `Error ID: ${data.id}` : ''}`, {
          variant: 'error',
        });
        console.error(
          `${errorsMap[status][lang]} ${data.id ? `Error ID: ${data.id}` : ''}, ${
            typeof data?.error === 'string' ? data.error : ''
          }`
        );
      }
    };

    document.addEventListener('axios-error', handleError);

    return () => {
      document.removeEventListener('axios-error', handleError);
    };
  }, []);
}
