import React, { useState, useRef, Attributes } from 'react';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useSortable } from '@dnd-kit/sortable';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardRefreshState, IHeader, IHeaderColumn, ISortObject, IStore } from '../../redux/store/IStore';
import {
  setAddColumnAnchor,
  setDashboardHeader,
  setDashboardHeaderWidths,
  setDashboardRefresh,
  setDashboardSort,
  setDashboardTableRefresh,
} from '../../redux/actions/actions';
import { Resizable } from 'react-resizable';

import throttle from 'lodash/throttle';

import '../../styles/css/dashboard.css';
import ResizableRow from './ResizableRow';

interface IProps {
  id: string;
  column: IHeaderColumn;
  allowDeletingColumns: boolean;
  allowNewColumns: boolean;
}

const generateTranslationOffset = (tr: any | null): string => {
  // namesto CSS.Transform.toString(transform)
  // hocemo da se scaleX in scaleY ne preracunata
  return tr ? `translate3d(${tr.x}px, ${tr.y}px, 0)` : '';
};

const TableHeaderItem = ({ id, column, allowDeletingColumns, allowNewColumns }: IProps) => {
  const dispatch = useDispatch();

  const sortObject = useSelector((store: IStore) => store.dashboardSort) as ISortObject;
  const header = useSelector((store: IStore) => store.dashboardHeader) as IHeader;

  const [controls, setControls] = useState<boolean>(false);
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const [width, setWidth] = useState(column.width);

  const addButtonRef = useRef(null);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging, over } = useSortable({
    id,
    transition: {
      duration: 60,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
  });

  const handleColumnClick = () => {
    if (!column.sortable || isDragging) return;

    if (sortObject.columnName !== column.id) {
      dispatch(setDashboardSort({ columnName: column.id, type: 'asc' }));
    } else {
      dispatch(setDashboardSort({ columnName: column.id, type: sortObject.type === 'asc' ? 'desc' : 'asc' }));
    }
    dispatch(setDashboardRefresh(DashboardRefreshState.NORMAL));
  };

  // Throttle down resizing
  const delayedQuery = useRef(throttle((size) => updateWidth(size), 10)).current;

  const updateWidth = (widthR) => {
    const attr = column.id as Attributes as string;
    dispatch(setDashboardHeaderWidths({ [attr]: widthR }));
  };

  const hideColumnHeader = () => {
    const attr = column.id as Attributes as string;

    dispatch(setDashboardHeader({ [attr]: { ...header[attr], shown: false } }));
    dispatch(setDashboardTableRefresh(DashboardRefreshState.NORMAL));
  };

  const style: React.CSSProperties = {
    transform: generateTranslationOffset(transform),
    transition: transition || '',
    width: width - 15,
  };

  const onResize = (event, { size }) => {
    setWidth(size.width);
    delayedQuery(size.width);
  };

  const onResizesStop = () => {
    setIsResizing(false);
    updateWidth(width);
    if (!header) return;
    dispatch(setDashboardHeader({ [column.id]: { ...header[column.id], width } }));
  };

  return (
    <Resizable
      width={width}
      height={30}
      onResize={onResize}
      resizeHandles={['se']}
      axis="x"
      onResizeStart={() => setIsResizing(true)}
      onResizeStop={onResizesStop}
      handle={
        <div className="header-item-resizable-handle" style={{ opacity: over ? 0 : 1 }}>
          <div></div>
        </div>
      }
      minConstraints={[120, 10]}
      maxConstraints={[600, 10]}
    >
      <ResizableRow column={column} className="table-header-item-wrapper">
        <div className="table-header-item-wrapper"></div>
        <div
          ref={setNodeRef}
          style={style}
          className={`table-header-item ${
            isDragging ? 'table-header-item-dragging' : (over || isResizing) && 'table-header-item-over'
          } `}
          {...attributes}
          {...listeners}
          onMouseOver={() => setControls(true)}
          onMouseLeave={() => setControls(false)}
        >
          <div
            onClick={() => isDragging || handleColumnClick()}
            className={'table-header-label noselect'}
            style={{ cursor: column.sortable ? 'pointer' : 'move' }}
          >
            {column.label}
          </div>

          <div className="header-item-controls">
            {sortObject.columnName === column.id && (
              <KeyboardArrowDown
                onClick={() => isDragging || handleColumnClick()}
                style={{
                  cursor: 'pointer',
                  maxHeight: 24,
                  transform: sortObject.type === 'asc' ? 'rotate(180deg)' : '',
                  transition: 'transform 0.25s ease',
                }}
              />
            )}

            {column.filtered && <FilterListIcon className="table-header-icon-filter" />}

            <div style={{ flexGrow: 1 }}> </div>

            {controls && (
              <>
                {allowNewColumns && (
                  <AddCircleOutline
                    ref={addButtonRef}
                    className="table-header-icon"
                    onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                      dispatch(setAddColumnAnchor({ id, anchor: e.currentTarget }));
                    }}
                  />
                )}
                {allowDeletingColumns && (
                  <RemoveCircleOutline className="table-header-icon" onClick={hideColumnHeader} />
                )}
              </>
            )}
          </div>
        </div>
      </ResizableRow>
    </Resizable>
  );
};

export default TableHeaderItem;
