import React, { useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import { MenuItem, Select } from '@material-ui/core';
import { IFrequencyClasses, IPhonems, WordStatusEnum } from './IDictionaryModal';
import {
  getFrequencyClasses,
  getPhonems,
  getWordsAndPronaunsByText,
  IAddToDictWord,
  IPronunciation,
  postWord,
} from '../../api/DictionaryService';
import { ClearIcon } from '../Icons/ClearIcon';
import { useDispatch, useSelector } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IStore } from '../../redux/store/IStore';
import '../../styles/css/dictionary_modal.css';
import checkPronaunciationWithPhonems from '../../shared/checkPronaunciationsWithPhonems';
import { useSnackbar } from 'notistack';

export interface IProps {
  opened: boolean;
  word?: string;
  onClose: (saved?: boolean) => void;
  // phonems: IPhonems;
}

// remove pronunciationTypes
const AddToDictionaryModal = ({ opened, word, onClose }: IProps) => {
  const [isLoadingSavedPronaun, setIsLoadingSavedPronaun] = useState<boolean>(false);
  const [phonems, setPhonems] = useState<IPhonems>();
  const [classNames, setClassNames] = useState<string>('modal_overlay');
  const [dictionaryWord, setDictionaryWord] = useState<IAddToDictWord>();
  const [pronIndexesFailed, setPronIndexesFailed] = useState<number[]>([]);
  // const [currentWordPronaunFreq, setCurrentWordPronaunFreq] = useState<number>(2);
  const [pronunciations, setPronunciations] = useState<IPronunciation[]>([]);
  const [frequencyClasses, setFrequencyClasses] = useState<IFrequencyClasses[]>([]);
  const transcriptionLanguage = useSelector((state: IStore) => state.config?.stt.language.value);
  const transcriptionDomain = useSelector((state: IStore) => state.config?.stt.domain.value);
  const transcriptionModelVersion = useSelector((state: IStore) => state.config?.stt.model.value);
  const [wordIsNew, setWordIsNew] = useState<boolean>(false);
  const [modelData, setModelData] = useState<{
    language: string;
    modelVersion: string;
    domain: string;
  } | null>(null);
  // const [currInputChange, setCurrInputChange] = useState<number>(-1);
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    transcriptionLanguage && transcriptionModelVersion && transcriptionDomain
      ? setModelData({
          language: transcriptionLanguage,
          modelVersion: transcriptionModelVersion,
          domain: transcriptionDomain,
        })
      : setModelData(null);
  }, [transcriptionLanguage, transcriptionModelVersion, transcriptionDomain]);

  useEffect(() => {
    if (!modelData) return;

    const fetchPhonems = async () => {
      try {
        const data: IPhonems = (await getPhonems(modelData)).data;

        setPhonems(data);
      } catch (e) {
        console.log(e);
      }
    };

    fetchPhonems();
  }, [modelData]);

  // When the word is present - get the data
  useEffect(() => {
    if (!modelData) return;
    if (word !== undefined) {
      const getData = async () => {
        try {
          const fc: IFrequencyClasses[] = (await getFrequencyClasses()).data;
          setFrequencyClasses(fc);
        } catch (e) {
          enqueueSnackbar(
            'Dodajanje v slovar trenutno ni možno zaradi napake v povezavi z zalednim sistemom.',
            { variant: 'error' }
          );
          console.log(e);
        }

        let dictWord: IAddToDictWord | undefined = undefined;
        let errorMessage: string | null = null;

        // Get word
        try {
          setIsLoadingSavedPronaun(true);
          dictWord = (await getWordsAndPronaunsByText(word, modelData)).data[0] as IAddToDictWord;

          setPronunciations(
            dictWord.pronunciations
            // dictWord.pronunciations.map((p) => {
            //     return { ...p, text: `/${p.text}/` };
            // })
          );
          setIsLoadingSavedPronaun(false);
        } catch (e) {
          setIsLoadingSavedPronaun(false);
          errorMessage = e.response.data.message || null;

          dictWord = undefined;
        }

        // Word doesn't exist - create new one
        if (dictWord === undefined) {
          setWordIsNew(true);
          dictWord = undefined;

          enqueueSnackbar(errorMessage || 'Izbrana beseda ne ustreza zahtevam za dodajanje v slovar.', {
            variant: 'error',
          });
        }
        if (dictWord?.status === WordStatusEnum.IN_PROGRESS) {
          setWordIsNew(false);
        }

        if (dictWord) {
          setDictionaryWord(dictWord.frequencyClassId ? dictWord : { ...dictWord, frequencyClassId: 2 });
        } else {
          setDictionaryWord(dictWord);
        }
      };

      getData();
    }
  }, [word, modelData]);

  useEffect(() => {
    if (opened) {
      setTimeout(() => {
        setClassNames(classNames + ' show');
      }, 100);
    }

    if (!opened) {
      setTimeout(() => {
        setClassNames('modal_overlay');
      }, 100);
    }
  }, [opened]);

  const reset = () => {
    setDictionaryWord(undefined);
    setPronunciations([]);
    setFrequencyClasses([]);
    setWordIsNew(false);
    setPronIndexesFailed([]);
  };

  const handleSaveClick = async () => {
    let isOk = true;
    let indexesOfWrong: number[] = [];
    const isIn = frequencyClasses.find((fc) => fc.id === dictionaryWord?.frequencyClassId);
    if (!dictionaryWord?.frequencyClassId || dictionaryWord.frequencyClassId < 0 || !isIn) {
      enqueueSnackbar('Za uspešno shranjevanje izberite frekvenco.', { variant: 'error' });
      isOk = false;
      return;
    }

    if (!pronunciations || pronunciations.length === 0) {
      enqueueSnackbar('Za uspešno shranjevanje morate dodati vsaj 1 izgovorjavo.', { variant: 'error' });
      return;
    }

    pronunciations.forEach((p, i) => {
      if (phonems && p.text.length > 0) {
        if (!checkPronaunciationWithPhonems(p.text, phonems)) {
          indexesOfWrong = [...indexesOfWrong, i];
        }
      }
      if (!p.text || p.text === '') {
        isOk = false;
      }
    });

    if (indexesOfWrong.length > 0) {
      setPronIndexesFailed(indexesOfWrong);

      enqueueSnackbar('Za uspešno shranjevanje preverite izgovorjave.', {
        variant: 'error',
      });
      return;
    }

    if (!isOk || !dictionaryWord || !modelData) {
      enqueueSnackbar('Za uspešno shranjevanje besede in izgovorjav izpolnite vsa polja.', {
        variant: 'error',
      });
      return;
    }

    try {
      const np = pronunciations.map((p) => {
        return {
          text: p.text,
        };
      });

      const nw = {
        text: dictionaryWord.text,
        frequencyClassId: dictionaryWord.frequencyClassId || 2,
      };
      const newWord = { ...nw, pronunciations: np };

      await postWord({ word: newWord, modelData });
      enqueueSnackbar('Beseda uspešno shranjena.', {
        variant: 'success',
      });
      onClose();
    } catch (e) {
      enqueueSnackbar('Dodajanje v slovar trenutno ni možno zaradi napake v povezavi z zalednim sistemom.', {
        variant: 'error',
      });
      console.log(e);
    }
  };

  const handleCancelClick = () => {
    reset();
    onClose();
  };

  const handleAddPronunciationClick = () => {
    if (
      pronunciations.length !== 0 &&
      pronunciations[pronunciations.length - 1] &&
      pronunciations[pronunciations.length - 1].text === ''
    ) {
      enqueueSnackbar('Za dodajanje nove izgovorjave morate najprej izpolniti vse prejšnje.', {
        variant: 'error',
      });
      return;
    }
    // 16 is max
    if (pronunciations.length < 16) {
      setPronunciations([
        ...pronunciations,
        {
          text: '',
          saved: true,
        },
      ]);
    }
  };

  const handlePronaunciationTextChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    // setCurrInputChange(index);
    // Change pronunciation text
    setPronIndexesFailed((curr) => {
      return curr.filter((i) => i !== index);
    });
    let newValue = event.target.value;

    const pronunciationsCopy: IPronunciation[] = JSON.parse(JSON.stringify(pronunciations));
    if (pronunciationsCopy[index]) {
      pronunciationsCopy[index].text = newValue;
      setPronunciations(pronunciationsCopy);
    }
  };

  const handlePronunciationDelete = (index: number) => {
    // Delete pronunciation
    setPronIndexesFailed((curr) => {
      const f = curr.filter((c) => c !== index);
      return f.map((f) => {
        if (index < f) {
          return f - 1;
        }
        return f;
      });
    });
    const filteredPronauns = pronunciations.filter((_, i) => i !== index);
    setPronunciations(filteredPronauns);
  };

  // useEffect(() => {
  //     if (currInputChange === -1) return;
  //     setCurrInputChange(-1);

  //     const selectedInput = document.getElementById(`pron-input-${currInputChange.toString()}`) as HTMLInputElement;

  //     if (!selectedInput) return;
  //     if (selectedInput.selectionEnd === selectedInput.value.length) {
  //         selectedInput.setSelectionRange(selectedInput.value.length - 1, selectedInput.value.length - 1);
  //     }
  // }, [currInputChange]);

  useEffect(() => {
    const selectedInput = document.getElementById(
      `pron-input-${(pronunciations.length - 1).toString()}`
    ) as HTMLInputElement;

    selectedInput && selectedInput.focus();
  }, [pronunciations.length]);

  return opened && dictionaryWord && word !== undefined ? (
    // <div className={classNames}>
    //   <div className="dict_modal_container">
    <div
      className="dict_modal_content_wrapper"
      style={
        wordIsNew ||
        dictionaryWord.status === WordStatusEnum.NEW ||
        dictionaryWord.status === WordStatusEnum.IN_PROGRESS
          ? { paddingTop: '65px' }
          : {}
      }
    >
      <div className="dicst_modal_main_wrapper">
        <div className="selectedword_wrappper">
          {(wordIsNew || dictionaryWord.status === WordStatusEnum.NEW) && (
            <span className="new_word_dict status">nova beseda</span>
          )}
          {dictionaryWord.status === WordStatusEnum.IN_PROGRESS && (
            <span className="new_word_dict status">v dodajanju</span>
          )}
          <span className="selected_word">{dictionaryWord.text}</span>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <FormControl style={{ width: '100%' }}>
              <Select
                labelId="frequency-select-label"
                id="frequency-select"
                value={frequencyClasses.find((fc) => fc.id === dictionaryWord.frequencyClassId)?.id || ''}
                onChange={(e: any) => {
                  setDictionaryWord({ ...dictionaryWord, frequencyClassId: e.target.value });
                }}
              >
                {frequencyClasses.map((freq) => (
                  <MenuItem key={freq.id} value={freq.id}>
                    {freq.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="word_info_wrapper">
          <span className="title_dict">Izgovorjave</span>
          <div className="pronunciationa_wrapper">
            {isLoadingSavedPronaun && <LinearProgress style={{ width: '100%' }} color={'secondary'} />}

            {!isLoadingSavedPronaun &&
              pronunciations &&
              pronunciations.map((pronunciation, i) => (
                <div
                  className={`pronunciation_main${pronIndexesFailed.some((k) => k === i) ? ' error' : ''}`}
                  key={i.toString()}
                  style={i === pronunciations.length - 1 ? { marginBottom: '30px' } : {}}
                >
                  {pronunciation.saved === false && (
                    <span
                      className="new_word_dict"
                      style={{
                        position: 'absolute',
                        right: '28px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                    >
                      predlog
                    </span>
                  )}
                  {pronunciation.text.length > 0 && (
                    <span
                      className="pronunciation_input"
                      style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        width: '100%',
                        height: '100%',
                        maxWidth: 'calc(100% - 25px)',
                        padding: '1px 2px',
                        zIndex: -1,
                      }}
                    >
                      <span>/</span>
                      <span style={{ opacity: 0, visibility: 'hidden' }}>{pronunciation.text}</span>
                      <span>/</span>
                    </span>
                  )}
                  <input
                    id={`pron-input-${i.toString()}`}
                    // onFocus={(e) => handleFocus(e, i)}
                    type="text"
                    className="pronunciation_input"
                    style={{
                      maxWidth: pronunciation.saved === false ? 'calc(100% - 75px)' : 'calc(100% - 25px)',
                      paddingLeft: pronunciation.text.length > 0 ? '8px' : 0,
                    }}
                    value={pronunciation.text}
                    onChange={(e: any) => handlePronaunciationTextChange(e, i)}
                  />

                  <button className="delete_icon_wrapper_normal" onClick={() => handlePronunciationDelete(i)}>
                    <ClearIcon />
                  </button>
                </div>
              ))}
            {!isLoadingSavedPronaun && (
              <button onClick={handleAddPronunciationClick} className="addpronunciation_btn">
                <div />
                <div />
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="modal_buttons_wrapper">
        <button onClick={handleCancelClick} className="modal_button_disaggre">
          PREKLIČi
        </button>
        <button onClick={handleSaveClick} className="modal_button_aggre">
          SHRANI
        </button>
      </div>
    </div>
  ) : //   </div>
  // </div>
  null;
};

export default AddToDictionaryModal;
