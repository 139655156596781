import React, { CSSProperties, useEffect, useRef, useState } from 'react';

interface IProps {
  open: boolean;
  onClose: () => void;
  anchorEl: HTMLDivElement | HTMLElement | null;
  width?: string;
  children: any;
  backgroundColor?: string;
  leftInitial?: boolean;
  transform?: string;
  minWidth?: number;
  fullWidth?: boolean;
  customStyle?: CSSProperties;
  substractScrollbar?: boolean; // Scrollbar width is 21px
}

interface IPos {
  over: boolean;
  px: number;
}

interface IOut {
  left: IPos;
  right: IPos;
}

export const isOutOfViewport = (element?: HTMLElement | null) => {
  // Get element's bounding
  if (element) {
    let bounding = element.getBoundingClientRect();

    // Check if it's out of the viewport on each side
    let outObject: IOut = {
      left: { over: false, px: 0 },
      right: { over: false, px: 0 },
    };

    if (bounding.left < 0) {
      outObject.left.px = bounding.left;
      outObject.left.over = true;
    } else {
      outObject.left.px = bounding.left;
      outObject.left.over = false;
    }
    if (bounding.right > (window.innerWidth || document.documentElement.clientWidth)) {
      outObject.right.px = 0 - ((window.innerWidth || document.documentElement.clientWidth) - bounding.right);
      outObject.right.over = true;
    } else {
      outObject.right.px = bounding.right;
      outObject.right.over = false;
    }

    return outObject;
  }
};

const CustomPopover = ({
  open,
  onClose,
  anchorEl,
  children,
  width,
  backgroundColor,
  transform,
  minWidth,
  customStyle = {},
  fullWidth,
  leftInitial,
  substractScrollbar,
}: IProps) => {
  const popoverRef = useRef(null);
  const [isOverLeft, setIsOverLeft] = useState<number>(-1);
  const [isOverRight, setIsOverRight] = useState<number>(-1);

  const handleClickOutside = (event: any) => {
    // @ts-ignore
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  useEffect(() => {
    if (open) {
      let values = isOutOfViewport(popoverRef.current);
      if (values) {
        if (values.left.over) {
          let newLeft = 0 - values.left.px + 60;
          setIsOverLeft(newLeft);
        }
        if (values.right.over) {
          let newLeft = values.right.px + 60;
          setIsOverRight(newLeft);
        }
      }
    } else {
      setIsOverRight(-1);
      setIsOverLeft(-1);
    }
  }, [open]);

  const position: any = anchorEl
    ? anchorEl.getBoundingClientRect()
    : { top: 0, bottom: 0, left: 0, right: 0, width: 0, height: 0 };

  let left = leftInitial
    ? position.left - (position.width + 8)
    : isOverLeft > 0
    ? position.left + position.width / 2 + isOverLeft
    : isOverRight > -1
    ? position.left + position.width / 2 - isOverRight
    : position.left + position.width / 2;

  if (substractScrollbar) {
    left -= 25;
  }

  return (
    <>
      {open && (
        <div
          ref={popoverRef}
          style={{
            position: 'absolute',
            top: position.top + position.height,
            left,
            transform: transform ? transform : 'translateX(-50%)',
            padding: '0.5vw',
            minWidth: minWidth ? minWidth : 100,
            width: width ? width : fullWidth ? position.width * 4 + 26 : '12vw',
            maxWidth: 300,
            border: '1px solid #2B8DFF',
            borderRadius: 4,
            backgroundColor: backgroundColor ? backgroundColor : '#F2F2F2',
            boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
            zIndex: 20,
            ...customStyle,
          }}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default CustomPopover;
