export const DEFAULT_FONT_SIZE = 22;

export const convertFontSizeToString = (fontSize: number) => {
  return fontSize + "px";
};

export const convertFontSizeToPercent = (fontSize: number) => {
  return Math.floor(fontSize * 100 / DEFAULT_FONT_SIZE);
};

export const convertPercentToString = (percent: number) => {
  return percent + "%";
};

export const convertPercentToFontSize = (percent: number) => {
  return Math.floor(percent * DEFAULT_FONT_SIZE / 100);
};