import JwtDecode from 'jwt-decode';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTokens,
  GrantTypesEnums,
  keycloakTokensResponse,
  removeTokens,
  requestKeycloakTokens,
  setTokens,
} from '../api/AuthenticationService';
import { JWTDecoded } from '../components/Dashboard/IDashboard';
import { setRefetchTokens, setSnack, setUser } from '../redux/actions/actions';
import { IStore, UserRoleEnums } from '../redux/store/IStore';

export default function useCheckTokenAndSetUser(
  deps: any[] = [],
  rfTokens?: boolean,
  dontRunTheEffect?: boolean
) {
  const rUsername = useSelector((state: IStore) => state.user?.username);
  const refetchTokens = useSelector((state: IStore) => state.refetchTokens);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const time = refetchTokens.refetchTime;

    if (typeof time !== 'number' || !rfTokens) return;

    const timeout = setTimeout(() => {
      loginUserWithRefreshToken(rUsername);
    }, (time - 10) * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [refetchTokens, rfTokens]);

  const loginUserWithRefreshToken = useCallback(
    async (username?: string) => {
      try {
        const tokens = await requestKeycloakTokens(undefined, undefined, GrantTypesEnums.REFRESH_TOKEN);
        const data = tokens.data as keycloakTokensResponse;
        const decoded: JWTDecoded = JwtDecode(data.access_token);
        if (data) {
          setTokens({ access_token: data.access_token, refresh_token: data.refresh_token });

          /*if (!decoded.realm_access.roles.includes(UserRoleEnums.EDITOR_USER) || !decodedTokenData.realm_access.roles.includes(UserRoleEnums.API_USER)) {
            removeTokens();
            dispatch(setUser(null));
            dispatch(setRefetchTokens(null));

            enqueueSnackbar('Ta uporabniški račun nima dovoljenj za uporabo True-Bar platforme', {
              variant: 'error',
            });

            return;
          }*/

          dispatch(
            setUser({
              username: username ? username : rUsername ? rUsername : 'Unknown',
              accessToken: data.access_token,
              refreshToken: data.refresh_token,
              isAuthenticated: true,
              userRoles: decoded.realm_access.roles,
              isEditorUser: decoded.realm_access.roles.includes(UserRoleEnums.EDITOR_USER) && decoded.realm_access.roles.includes(UserRoleEnums.API_USER),
            })
          );
          dispatch(setRefetchTokens(data.expires_in));
        }
      } catch (e) {
        removeTokens();
        dispatch(setUser(null));
        dispatch(setRefetchTokens(null));
      }
    },
    [dispatch, rUsername]
  );

  React.useEffect(() => {
    if (dontRunTheEffect === true) return;
    const tokens = getTokens();
    if (!tokens) return;
    const { accessToken, refreshToken } = tokens;
    // console.log('token', token);
    if (!accessToken || accessToken === '') {
      dispatch(setUser(null));
      dispatch(setRefetchTokens(null));
      return;
    }

    const decodedAccess: JWTDecoded = jwt_decode(accessToken);
    const decodedRefresh: JWTDecoded = jwt_decode(refreshToken);
    const expireAccess = decodedAccess.exp;

    const now = moment().unix();
    if (now > expireAccess && now > decodedRefresh.exp) {
      removeTokens();
      dispatch(setUser(null));
      dispatch(setRefetchTokens(null));
      return;
      // window.location.reload();
    } else if (now > expireAccess && now <= decodedRefresh.exp) {
      loginUserWithRefreshToken(decodedAccess.preferred_username);
      return;
    }

    /*if (!decodedAccess.realm_access.roles.includes(UserRoleEnums.EDITOR_USER) || !decodedTokenData.realm_access.roles.includes(UserRoleEnums.API_USER)) {
      removeTokens();
      dispatch(setUser(null));
      dispatch(setRefetchTokens(null));
      enqueueSnackbar('Ta uporabniški račun nima dovoljenj za uporabo True-Bar platforme', {
        variant: 'error',
      });
      return;
    }*/

    dispatch(
      setUser({
        username: decodedAccess.preferred_username,
        isAuthenticated: true,
        accessToken,
        refreshToken,
        userRoles: decodedAccess.realm_access.roles,
        isEditorUser: decodedAccess.realm_access.roles.includes(UserRoleEnums.EDITOR_USER) && decodedAccess.realm_access.roles.includes(UserRoleEnums.API_USER),
      })
    );
    dispatch(setRefetchTokens(expireAccess - now));
  }, [...deps, dispatch, dontRunTheEffect]);

  return {
    loginUserWithRefreshToken,
  };
}
