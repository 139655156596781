import * as React from 'react';
import { useEffect, useState } from 'react';
import { getAbout } from '../../api/HomeService';
import { config } from '../../config';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store/IStore';

const { appName: projectName, appVersion: projectVersion, commitHash } = config;

interface IBackendAbout {
  name: string;
  version: string;
  buildDate: string;
}

const FooterInfo: React.FC = () => {
  const [backendAbout, setBackendAbout] = useState<IBackendAbout>();
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const user = useSelector((store: IStore) => store.user)

  useEffect(() => {
    getAbout().then((data: any) => {
      setBackendAbout(data.data);
    });
  }, []);

  const showFooterInfo = () => {
    setShowInfo(true);
  };

  const hideFooterInfo = () => {
    setShowInfo(false);
  };

  return (
    <>
      <div
        className="hidden-show-button"
        onMouseEnter={showFooterInfo}
        onMouseLeave={hideFooterInfo}
      />

      <div className="footer_info">
        {config.showLogo && (
            <img alt='FRI logo' id='fri_logo' src='./fri-logo.png' />
        ) || (
          <>
            © <b>Vitasis</b> & <b>Laboratorij za podatkovne tehnologije</b>, UL-FRI
          </>
        )}
      </div>


      {showInfo && (
        <div className="versions_info">
          {projectName} | {projectVersion} | {commitHash} {backendAbout && backendAbout.name} |{' '}
          {backendAbout && backendAbout.version} | {backendAbout && backendAbout.buildDate}
        </div>
      )}
    </>
  );
};

export default FooterInfo;
