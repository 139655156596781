import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  setDashboardExpendedRow,
  setDashboardFilters,
  setDashboardPagination,
} from '../redux/actions/actions';

const HomescreenProvider = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDashboardPagination({ pageNumber: 0 }));
    dispatch(setDashboardExpendedRow(-1));
  }, [dispatch]);

  return <>{children}</>;
};

export default HomescreenProvider;
