import { useSnackbar } from 'notistack';
import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTokens } from '../api/AuthenticationService';
import { API, CLIENT, EDITOR, SESSIONS } from '../api/endpoints';
import { IReceivedInfo, ReceivedMessageTypes } from '../components/Libraries/ILibraries';
import { config } from '../config';
import {
  setAnimButtonState,
  setAudioInfo,
  setConsumptionModal,
  setEditorMode,
  setHomeFlowState,
  setLoadContentToEditor,
} from '../redux/actions/actions';
import { AnimButtonStatesEnum, ConsumtionModalTemplate, EditorModeEnums, IStore } from '../redux/store/IStore';
import {
  IWsAction,
  IWsOptions,
  ReadyState,
  SendJsonMessage,
  SendMessage,
  WsActionObj,
  WsContextValue,
  WsProviderProps,
} from './wsTypes';
import { releaseAudio } from '../components/Libraries/newMicrophone';

const WsContext = createContext<WsContextValue>({
  sendMessage: () => {},
  sendJsonMessage: () => {},
  resetLastMessage: () => {},
  isConnected: false,
  lastSessionInfo: null,
  recordingsIdsInCurrentEditorSession: { current: [] },
  openWs: () => {},
  closeWs: () => {},
  waitingForInfoMessage: false,
  lastMessage: null,
  lastJsonMessage: null,
  transcriptionFinishedClose: false,
  updateAudioToken: false,
  resetUpdateAudioToken: () => {},
  isLoadingWsSession: false,
  setIsLoadingWsSession: () => {},
});

// 1000 - NORMAL
// 1001 - GOING_AWAY
// 1011 - SERVER_ERROR
// 4001 - LANGUAGE_NOT_AVAILABLE
// 4002 - DOMAIN_NOT_AVAILABLE
// 4003 - MODEL_NOT_AVAILABLE
// 4004 - MODEL_NOT_ALLOWED
// 4005 - WORKERS_NOT_AVAILABLE
// 4006 - SILENCE_TIMEOUT?
const getErrorCodeMapper = (code: number) => {
  switch(code) {
    case 1001: return "Zaledni sistem ni na voljo"
    case 1011: return "Interna napaka na zalednem sistemu"
    case 4000: return "Neveljavna operacija. Prosimo, preverite nastavitv."
    case 4001: return "Preveč časa je bila tišina."
    case 4002: return "Seja se je zaključila, model je v obnovi"
    case 4030: return "Nedovoljena operacija"
    case 4031: return "Izbrani model ni dovoljen"
    case 4041: return "Izbrani model ni na voljo"
    case 4291: return "Trenutno so vsi delavci zasedeni"
    case 4292: return "Dosegli ste zakupljeno količino"
    case 4293: return "Presegli ste dogovorjeno število sočasnih sej"
    default: return "Prišlo je do neznane napake"
  }
}

export const WEBSOCKET_URL = config.backendWsUrl;
export const CHUNK_SIZE = 8192; // 16384, 8192, 2048
export const NUM_OF_CHANNELS = 1;

export const urlBackend: string = config.backendApiUrl;

const shouldReconnect = false;
const MAX_RECONNECTS = 20;
const DEFAULT_RECONNECT_INTERVAL_MS = 5000;

export const WsProvider: React.FC<WsProviderProps> = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [waitingForInfoMessage, setWaitingForInfoMessage] = useState(false);
  const user = useSelector((state: IStore) => state.user);
  const [updateAudioToken, setUpdateAudioToken] = useState(false);
  const [transcriptionFinishedClose, setTranscriptionFinishedClose] = useState<boolean>(false);
  const [wsAction, setWsAction] = useState<WsActionObj>({ action: IWsAction.IDLE });
  const [lastSessionInfo, setLastSessionInfo] = useState<IReceivedInfo | null>(null);
  const [isLoadingWsSession, setIsLoadingWsSession] = useState(false);
  const [lastMessage, setLastMessage] = useState<WebSocketEventMap['message'] | null>(null);
  const [readyState, setReadyState] = useState<ReadyState>();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const lastJsonMessage = useMemo(() => {
    if (lastMessage) {
      try {
        return JSON.parse(lastMessage.data);
      } catch (e) {
        return null;
      }
    }
    return null;
  }, [lastMessage]);

  const webSocketRef = useRef<WebSocket | null>(null);
  // const startRef = useRef<() => void>(() => void 0);
  const reconnectCount = useRef<number>(0);
  // const messageQueue = useRef<WebSocketMessage[]>([]);

  const openWs = useCallback(
    (options?: IWsOptions) => {
      setWsAction({ action: IWsAction.CONNECT, options });
    },
    [setWsAction]
  );

  const resetLastMessage = useCallback(() => {
    setLastMessage(null);
  }, [setLastMessage]);

  const onTranscriptionFinishedClose = useCallback((callback: () => void) => {
    callback();
  }, []);

  const closeWs = useCallback(
    (action: IWsAction = IWsAction.CLOSE) => {
      setWsAction({ action });
    },
    [setWsAction]
  );

  const recordingsIdsInCurrentEditorSession = useRef<number[]>([]);

  useEffect(() => {
    if (wsAction.action === IWsAction.IDLE) return;
    setWsAction({ action: IWsAction.IDLE });

    let reconnectTimeout: number;
    if (wsAction.action === IWsAction.CONNECT) {
      setReadyState(ReadyState.CONNECTING);
      let wsUrl = WEBSOCKET_URL;
      if (wsAction.options) {
        const { sessionId, token } = wsAction.options;
        wsUrl += `?client_code=EDITOR&access_token=${token}${sessionId ? `&session_id=${sessionId}` : ''}`;
      }
      console.log('Will create new ws session', wsAction.options);

      webSocketRef.current = new WebSocket(wsUrl);

      const wsInstance = webSocketRef.current;
      if (!wsInstance) {
        throw new Error('WebSocket failed to be created');
      } else {
        wsInstance.onopen = (event: WebSocketEventMap['open']) => {
          // onOpenFn()
          console.log('Websocket onOpen', event);
          setTranscriptionFinishedClose(false);
          setWaitingForInfoMessage(true);
          reconnectCount.current = 0;
          setIsConnected(true);
          setReadyState(ReadyState.OPEN);
        };

        wsInstance.onmessage = (message: WebSocketEventMap['message']) => {
          //console.log('received msg:', message);
          // onMessageFn()
          const m = JSON.parse(message.data) as IReceivedInfo;
          // console.log('Low msg:', m);
          if (m.messageType === ReceivedMessageTypes.INFO) {
            setWaitingForInfoMessage(false);
          }

          if (m.messageType === ReceivedMessageTypes.INFO && wsAction.options) {
            if (m.recordingId) {
              recordingsIdsInCurrentEditorSession.current = [
                ...recordingsIdsInCurrentEditorSession.current,
                m.recordingId,
              ];
            }

            const { token } = wsAction.options;

            const aToken = getTokens()?.accessToken;
            const audioUrlPath = `${urlBackend}/${API}/${CLIENT}/${SESSIONS}/${
              m.sessionId
            }/audio.wav?access_token=${aToken ? aToken : token}&nocache=${new Date().getTime()}`;

            dispatch(
              setAudioInfo({
                url: audioUrlPath,
                loadNew: false,
              })
            );
            setUpdateAudioToken(true);
            setLastSessionInfo(m);
          }

          if (m.messageType === ReceivedMessageTypes.ERROR) {
            const isSilenceTimeout = m.message?.indexOf('Silence timeout');
            wsInstance.close(isSilenceTimeout ? 4006 : 3000, m.message);
          }
          setLastMessage(message);
        };

        wsInstance.onclose = (event: WebSocketEventMap['close']) => {
          // onCloseFn()
          setIsLoadingWsSession(false);
          console.log('Ws will close', event);
          dispatch(setHomeFlowState({ liveFlowInProgress: false, uploadFlowInProgress: false }));
          setReadyState(ReadyState.CLOSED);
          setIsConnected(false);
          setWaitingForInfoMessage(false);
          releaseAudio();
          console.log(event)
          const { code, reason } = event;
          if (code !== 1000) {
            setTranscriptionFinishedClose(true);
            setLastMessage(null);

            dispatch(
              setAudioInfo({
                loadNew: true,
              })
            );

            dispatch(
              setLoadContentToEditor({
                recFinishedStartLoadingNewEditorState: true,
                recStartedLoadTextFromEditor: false,
              })
            );
            dispatch(setAnimButtonState(AnimButtonStatesEnum.NORMAL));
            dispatch(setEditorMode(EditorModeEnums.EDIT_MODE));
            const isAudioError =
              reason.indexOf('Audio data can only be processed while session is in progress') >= 0;
            const translatedCode = isAudioError ? 4007 : code;
            enqueueSnackbar(
              `Seja se je zaključila: ${getErrorCodeMapper(translatedCode)}`,
              { variant: translatedCode === 4006 ? 'info' : 'error' }
            );

            if (code === 4292) {
              dispatch(setConsumptionModal({
                visible: true,
                template: ConsumtionModalTemplate.LIMIT
              })); 
            }
            wsInstance.close();
          }

          if (code === 1000) {
            setTranscriptionFinishedClose(true);
            setLastMessage(null);
            dispatch(
              setAudioInfo({
                loadNew: true,
              })
            );
            dispatch(
              setLoadContentToEditor({
                recFinishedStartLoadingNewEditorState: true,
                recStartedLoadTextFromEditor: false,
              })
            );
            dispatch(setAnimButtonState(AnimButtonStatesEnum.NORMAL));
            dispatch(setEditorMode(EditorModeEnums.EDIT_MODE));

            wsInstance.close();
          } else {
            setTranscriptionFinishedClose(false);
            setLastMessage(null);
            dispatch(
              setAudioInfo({
                loadNew: true,
              })
            );
            dispatch(
              setLoadContentToEditor({
                recFinishedStartLoadingNewEditorState: true,
                recStartedLoadTextFromEditor: false,
              })
            );
            dispatch(setAnimButtonState(AnimButtonStatesEnum.NORMAL));
            dispatch(setEditorMode(EditorModeEnums.EDIT_MODE));
            dispatch(
              setAudioInfo({
                loadNew: true,
              })
            );
            if (code === 4292) {
              dispatch(setConsumptionModal({
                visible: true,
                template: ConsumtionModalTemplate.LIMIT
              })); 
            }
            wsInstance.close();
          }

          if (shouldReconnect) {
            if (reconnectCount.current < MAX_RECONNECTS) {
              reconnectTimeout = window.setTimeout(() => {
                reconnectCount.current++;
                openWs();
              }, DEFAULT_RECONNECT_INTERVAL_MS);
            } else {
              // onReconnectStop()
              console.warn(`Max reconnect attempts of ${reconnectCount} exceeded`);
            }
          }
        };

        wsInstance.onerror = (error: WebSocketEventMap['error']) => {
          // onEerrorFn()
          setIsLoadingWsSession(false);
          enqueueSnackbar('Prislo je do napake v webSocket povezavi.', {
            variant: 'error',
          });
          console.log('WS error:', error);
        };
      }
    } else if (wsAction.action === IWsAction.CLOSE || wsAction.action === IWsAction.FORCE_CLOSE) {
      const wsInstance = webSocketRef.current;
      if (wsInstance && wsInstance.readyState === wsInstance.OPEN) {
        setReadyState(ReadyState.CLOSING);

        if (wsAction.action === IWsAction.FORCE_CLOSE) {
          wsInstance.close(3000, 'Force close due to backend inactivity');
        } else {
          wsInstance.close();
        }
        setIsConnected(false);
      }
    }

    return () => window.clearTimeout(reconnectTimeout);
  }, [wsAction]);

  useEffect(() => {
    return () => {
      setReadyState(ReadyState.CLOSING);
      // cancelReconnectOnClose();
      // cancelReconnectOnError();

      webSocketRef.current && webSocketRef.current.close();
      // if (interval) clearInterval(interval);
    };
  }, []);

  const sendMessage: SendMessage = useCallback((message) => {
    if (webSocketRef.current && webSocketRef.current.readyState === ReadyState.OPEN) {
      // console.log('WILL SEND MESSAGE:', message);
      webSocketRef.current.send(message);
    }

    // else {
    //   messageQueue.current.push(message);
    // }
  }, []);

  const sendJsonMessage: SendJsonMessage = useCallback(
    (message) => {
      sendMessage(JSON.stringify(message));
    },
    [sendMessage]
  );

  const resetUpdateAudioToken = useCallback(() => {
    setUpdateAudioToken(false);
  }, [setUpdateAudioToken]);

  useEffect(() => {
    if (!updateAudioToken) return;
    if (!user || !user.accessToken) return;
    if (!lastSessionInfo?.sessionId) return;

    const audioUrlPath = `${urlBackend}/${API}/${CLIENT}/${SESSIONS}/${
      lastSessionInfo.sessionId
    }/audio.wav?access_token=${user.accessToken}&nocache=${new Date().getTime()}`;

    dispatch(
      setAudioInfo({
        url: audioUrlPath,
        loadNew: true,
      })
    );
  }, [user?.accessToken]);

  const memoValue = useMemo(() => {
    return {
      isConnected,
      lastMessage,
      lastJsonMessage,
      sendMessage,
      sendJsonMessage,
      openWs,
      closeWs,
      lastSessionInfo,
      transcriptionFinishedClose,
      resetLastMessage,
      updateAudioToken,
      resetUpdateAudioToken,
      recordingsIdsInCurrentEditorSession,
      isLoadingWsSession,
      setIsLoadingWsSession,
      waitingForInfoMessage,
    };
  }, [
    isConnected,
    lastMessage,
    lastJsonMessage,
    sendMessage,
    sendJsonMessage,
    openWs,
    waitingForInfoMessage,
    closeWs,
    lastSessionInfo,
    transcriptionFinishedClose,
    resetLastMessage,
    updateAudioToken,
    resetUpdateAudioToken,
    recordingsIdsInCurrentEditorSession,
    isLoadingWsSession,
    setIsLoadingWsSession,
  ]);

  return <WsContext.Provider value={memoValue}>{children}</WsContext.Provider>;
};

export default WsContext;
