import React from "react";

import MicIon from "../components/Icons/MicIcon";
import PlayIcon from "../components/Icons/MicIcon";
import StopIcon from "../components/Icons/MicIcon";

export enum ButtonIcons {
  RECORD = "RECORD",
  PLAY = "PLAY",
  STOP = "STOP",
}

export enum Animations {
  FIRST = "FIRST",
  SECOND = "SECOND",
  BREATHE = "BREATHE",
}

interface Props {
  icon: ButtonIcons;
  running: boolean;
  animation?: Animations;
  onClick: () => void;
  noMargin?: boolean;
  light?: boolean;
  disabled?: boolean;
}

// new red rgb(255, 102, 102)

function AnimatedButton({
  icon,
  running,
  animation,
  onClick,
  noMargin,
  light,
  disabled,
}: Props) {
  let outerCircleClassName = "outer_circle";
  if (animation === Animations.FIRST) {
    outerCircleClassName += " animation_one";
  } else if (animation === Animations.SECOND) {
    outerCircleClassName += " animation_two";
  } else if (animation === Animations.BREATHE) {
    outerCircleClassName += " recording";
  }

  return (
    <button
      className="button_holder"
      style={noMargin ? { margin: 0 } : {}}
      onClick={onClick}
      disabled={disabled}
    >
      <div
        className={outerCircleClassName}
        style={{
          borderColor:
            animation === Animations.BREATHE && running
              ? "#F80534"
              : light
              ? "#70707082"
              : "#333333",
        }}
      />

      {icon === ButtonIcons.PLAY && (
        <PlayIcon
          className="mic_icon"
          height="48px"
          //   width="48px"
          style={{ transform: "translate(-40%, -50%)" }}
          fill={running ? "#F80534" : light ? "#70707082" : "#333333"}
        />
      )}

      {icon === ButtonIcons.RECORD && (
        <MicIon
          className="mic_icon"
          height="48px"
          fill={running ? "#F80534" : "#333333"}
        />
      )}

      {icon === ButtonIcons.STOP && (
        <StopIcon fill={running ? "#F80534" : "#333333"} />
      )}
    </button>
  );
}

export default AnimatedButton;
