import { Dispatch } from 'react';

export interface SettingsPopupProps {
  handleCloseSettings: () => void;
  show: boolean;
  fileUploadIsInProgress?: boolean;
}

export interface ITranscriptOptions {
  language: string;
  model: string;
  endpointing: string;
  punctuation: boolean;
  capitalize: boolean;
  normalize: boolean;
  dictation: boolean;
}

export interface ITranslationOptions {
  language: string;
  interimResults: string;
}

export interface ISpeechOptions {
  language: string;
  voice: string;
}

export interface IModelMetadata {
  workers?: {
    available: number,
    active: number,
  },
  info?: {
    framework: string,
    am: string,
    lx: string,
    lm: string,
    src: string,
  },
  features?: {
    transcription: {
      casing: string,
      form: string,
    }
  }
}

export interface IModel {
  code: string;
  isAllowed: boolean;
  isAvailable: boolean;
  isRealtime: boolean;
  diarizationSupport: boolean;
  isUpdatable: boolean;
  isUpdating: boolean;
  dictationCommandsSupport: boolean;
  metadata?: IModelMetadata;
}

export interface IDomain {
  isAllowed: boolean;
  code: string;
  models: IModel[];
}
export interface ILanguage {
  code: string;
  domains: IDomain[];
  isAllowed: boolean;
}
export interface IFramework {
  code: string;
  languages: ILanguage[];
  isAllowed: boolean;
}
export interface ISttStatus {
  frameworks: IFramework[]
}
export interface clientLang {
  languageCode: string;
  available: boolean;
}

export enum DictStatesEnum {
  NOT_RUNNING = 'NOT_RUNNING',
  RUNNING = 'RUNNING',
  FAILED = 'FAILED',
}

export interface DictProps {
  version: string;
  domain: string;
  language: string;
  isModelUpdatable: boolean;
  isModelUpdating: boolean;
}

//Reimplement
export enum Framework {
  KALDI = "KALDI",
  VOSK = "VOSK",
  RIVA = "RIVA",
  NEMO = "NEMO",
  GOOGLE = "GOOGLE",
}

interface IPonctuation {
  enabled: {
    isAllowed: boolean,
    value: boolean,
  },
  model: {
    isAllowed: boolean,
    value: string,
  },
  punctuateInterims: {
    isAllowed: boolean,
    value: boolean,
  },
  enableRealFinals: {
    isAllowed: boolean,
    value: boolean,
}
}
interface IDenormalization {
  isAllowed: boolean,
  value: boolean,
}
interface ITranslation {
  enabled: {
    isAllowed: boolean,
    value: boolean,
  },
  language: {
    isAllowed: boolean,
    value: string,
  }
}
interface INlpConfig {
  punctuation: IPonctuation;
  enableDenormalization: IDenormalization;
  translation: ITranslation;
  enableTruecasing: {
    isAllowed: boolean,
    value: boolean
  },
}
interface ISttConfig {
  framework: {
    isAllowed: boolean,
    value: Framework | undefined
  },
  language: {
    isAllowed: boolean,
    value: string,
  },
  domain: {
    isAllowed: boolean,
    value: string,
  },
  model: {
    isAllowed: boolean,
    value: string,
  },
  enableInterimTranscripts: {
    isAllowed: boolean,
    value: boolean,
  },
  enableDiarization: {
    isAllowed: boolean,
    value: boolean,
  },
  enableDiarizationSpeakerIdentification: {
    isAllowed: boolean,
    value: boolean,
  }
  enableDictatedPunctuations: {
    isAllowed: boolean,
    value: boolean,
  },
  enableDictatedCommands: {
    isAllowed: boolean,
    value: boolean,
  },
  enableUnks: {
    isAllowed: boolean,
    value: boolean,
  }
}

//Not used currently
interface ITtsConfig {
  modifiable: boolean;
}
export interface IConfig {
  stt: ISttConfig;
  nlp: INlpConfig;
  //ttsConfig: ITtsConfig; reserved for future use.
}



interface ISttConfigPatch {
  framework?: {
    isAllowed?: boolean,
    value?: Framework | undefined
  },
  language?: {
    isAllowed?: boolean,
    value?: string,
  },
  domain?: {
    isAllowed?: boolean,
    value?: string,
  },
  model?: {
    isAllowed?: boolean,
    value?: string,
  },
  enableInterimTranscripts?: {
    isAllowed?: boolean,
    value?: boolean,
  },
  enableDiarization?: {
    isAllowed?: boolean,
    value?: boolean,
  },
  enableDiarizationSpeakerIdentification?: {
    isAllowed?: boolean,
    value?: boolean,
  }
  enableDictatedPunctuations?: {
    isAllowed?: boolean,
    value?: boolean,
  },
  enableDictatedCommands?: {
    isAllowed?: boolean,
    value?: boolean,
  },
  enableUnks?: {
    isAllowed?: boolean,
    value?: boolean,
  }
}

interface IPonctuationPatch {
  enabled?: {
    isAllowed?: boolean,
    value?: boolean,
  },
  model?: {
    isAllowed?: boolean,
    value?: string,
  },
  punctuateInterims?: {
    isAllowed?: boolean,
    value?: boolean,
  },
  enableRealFinals?: {
    isAllowed?: boolean,
    value?: boolean,
}
}

interface IDenormalizationPatch {
  isAllowed?: boolean,
  value?: boolean,
}
interface ITranslationPatch {
  enabled?: {
    isAllowed?: boolean,
    value?: boolean,
  },
  language?: {
    isAllowed?: boolean,
    value?: string,
  }
}
interface INlpConfigPatch {
  punctuation?: IPonctuationPatch;
  enableDenormalization?: IDenormalizationPatch;
  translation?: ITranslationPatch;
  enableTruecasing?: {
    isAllowed?: boolean,
    value?: boolean
  },
}
export interface IConfigPatch {
  stt?: ISttConfigPatch;
  nlp?: INlpConfigPatch;
}