import React, { useEffect, useRef, useState } from 'react';

type UseHoverType<T extends HTMLElement> = [
  React.RefObject<T>,
  { value: boolean; setValue: React.Dispatch<React.SetStateAction<boolean>> }
];

export default function useHover<T extends HTMLElement>(): UseHoverType<T> {
  const [value, setValue] = useState(false);

  const ref = useRef<T>(null);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener('mouseenter', handleMouseOver);
        node.addEventListener('mouseleave', handleMouseOut);

        return () => {
          node.removeEventListener('mouseenter', handleMouseOver);
          node.removeEventListener('mouseleave', handleMouseOut);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );

  return [ref, { value, setValue }];
}
