import React from 'react';
import { useHistory } from 'react-router';
import { withStyles } from '@material-ui/styles';

import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import IconButton from '@material-ui/core/IconButton';

const BackButtonCustom = withStyles({
  root: {
    marginRight: '24px',
  },
})(IconButton);

const BackButton = () => {
  const history = useHistory();

  const back = () => {
    history.push('/');
  };

  return (
    <BackButtonCustom aria-label="back" onClick={back}>
      <KeyboardBackspaceRoundedIcon />
    </BackButtonCustom>
  );
};

export default BackButton;
