import { useRef } from 'react';
import { IWordData, IWordDataLive } from '../components/Editor/IEditor';

export default function useLiveEditorRefs() {
  const isInSubModeOfFindMode = useRef<'repl' | 'insa' | 'insb' | 'fix' | 'spell' | false>(false);
  const currentReplaceTextRef = useRef<{ finals: IWordData[]; lastInterim: IWordData[] }>({
    finals: [],
    lastInterim: [],
  });
  const liveWordDataRef = useRef<IWordDataLive>({
    finalsBlocks: [],
    lastInterim: '',
  });

  const capitalizeNextLetter = useRef<boolean>(false);
  const capitalizeNextWordCommand = useRef<boolean>(false);
  const upperCaseAllNext = useRef<boolean>(false);
  const lowerCaseAllNext = useRef<boolean>(false);
  const isInFindMode = useRef<boolean>(false);
  const trCounter = useRef<number>(0);
  //   const isNewRecordingSession = useRef<boolean>(true);

  const ucSpelling = useRef(false);
  const lcSpelling = useRef(false);
  const ccSpelling = useRef(false);
  const LCSpelling = useRef(false);

  const ucaInsertion = useRef(false);
  const lcaInsertion = useRef(false);
  const cnInsertion = useRef(false);

  const boldOn = useRef(false);
  const italicOn = useRef(false);
  const underlineOn = useRef(false);
  const boldNext = useRef(false);
  const italicNext = useRef(false);
  const underlineNext = useRef(false);

  const liveWordDataContainsInputs = useRef(false);

  return {
    boldNext,
    boldOn,
    italicNext,
    italicOn,
    underlineNext,
    underlineOn,
    isInSubModeOfFindMode,
    currentReplaceTextRef,
    capitalizeNextLetter,
    capitalizeNextWordCommand,
    upperCaseAllNext,
    lowerCaseAllNext,
    isInFindMode,
    trCounter,
    ucSpelling,
    lcSpelling,
    ccSpelling,
    LCSpelling,
    ucaInsertion,
    lcaInsertion,
    cnInsertion,
    liveWordDataRef,
    liveWordDataContainsInputs,
  };
}
