import React, { useEffect, useState, useCallback } from 'react';
import Table from './Table';
import { ISessionEntry } from './IDashboard';
import DashboardHeader from '../DashboardHeader/DashboardHeader';
import LinearProgress from '@material-ui/core/LinearProgress';
import AppliedFilters from './AppliedFilters';
import { IAppliedFilter, SessionState } from '../DashboardHeader/ISearch';
import { useSelector, useDispatch, batch } from 'react-redux';
import {
  setAudioInfo,
  setEditorMode,
  setSessionName,
  setSessionsLabels,
  setTbFormat,
  setValidRedirect,
  setDashboardRefresh,
  setDashboardExpendedRow,
  setDashboardPagination,
  setDashboardFilters,
  setIsSessionDiscarded,
  setDiarization,
  setRowOpening,
} from '../../redux/actions/actions';
import {
  DashboardRefreshState,
  EditorModeEnums,
  IPageable,
  ISortObject,
  IStore,
  IUser,
  UserRoleEnums,
} from '../../redux/store/IStore';
import '../../styles/css/dashboard.css';
import Pagination from '@material-ui/lab/Pagination';
import {
  getClientLabels,
  getEditorContent,
  getSessionRecordings,
  getSessions,
  getSessionTranscripts,
} from '../../api/SessionsService';
import { transformNotSavedSessionTranscriptsToLiveWordData } from '../../shared/DataConverters';
import { useHistory } from 'react-router-dom';
import { getTokens } from '../../api/AuthenticationService';
import { urlBackend } from '../../providers/WsProvider';
import { API, CLIENT, SESSIONS } from '../../api/endpoints';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import useInterval from '../../hooks/useInterval';
import { config } from '../../config';
import { IContent, IReceivedTranscript, ReceivedMessageTypes } from '../Libraries/ILibraries';
import { IFinal, ITranscriptWord } from '../Editor/IEditor';
import { paragraphTokens } from '../../shared/paragrapher';
import SpeakerSettingsModal from '../SpeakerSettings/SpeakerSettingsModal';


// A helper function for viewing applied filters.
// const appliedFilterConsoleLog = (appliedFilters: IAppliedFilter[]): void => {
//   const out = appliedFilters
//     .map((filter, index) =>
//       [`Filter ${index}:`]
//         .concat(filter.map(({ label, field }) => (field === 'value' ? `"${label}"` : field)))
//         .join(' ')
//     )
//     .join('\n');

//   console.log(
//     `%cFilters have changed, they're:\n${out}\n`,
//     'background: #222; color: #bada55',
//     appliedFilters
//   );
// };

// Parse String of form 23.3.2021 (dd.mm.yyyy) into Date object
const parseSloDate = (dateStr: string): Date | null => {
  const arr = dateStr.split('.');
  if (arr.length !== 3) return null;
  const numArr = arr.map((v) => Number(v));
  let date = new Date(numArr[2], numArr[1] - 1, numArr[0])
  date = new Date(date.getTime() + (-1 * date.getTimezoneOffset() * 60000))
  return date;
};

const MaterialPagination = withStyles({
  root: {
    margin: '8px',
  },
})(Pagination);

const transformFilters = (appliedFilters: IAppliedFilter[]): URLSearchParams => {
  const urlParams: URLSearchParams = new URLSearchParams()

  appliedFilters.forEach((filter: IAppliedFilter) => {

    switch (filter[0].field) {
      case 'createdAt':
        if (filter.length === 3) {//user passed specific day or open interval at one side
          let date = parseSloDate(filter[2].label);
          if (!date) throw new Error(`Invalid date filter: value ${filter[2].label}`)

          if (filter[1].field === "to") {
            date = new Date(date.getTime() + 60 * 60 * 24 * 1000);
          }
          const dateStr = date.toISOString();

          switch(filter[1].field) {
            case 'to':
              urlParams.append('created-at', `;${dateStr}`)
              break;
            case 'from':
              urlParams.append('created-at', `${dateStr};`)
              break;
            case 'is':
              const dateEnd = new Date(date.getTime() + 60 * 60 * 24 * 1000).toISOString();
              urlParams.append('created-at', `${dateStr};${dateEnd}`)
              break;
            default: throw new Error(`Unknown date filter operation field: ${filter[1].field}`)
          }
        } else if (filter.length === 5) {//user passed closed interval on both sides
          let dateOne = parseSloDate(filter[2].label)
          let dateTwo = parseSloDate(filter[4].label)

          if (!dateOne) throw new Error(`Invalid date filter: value ${filter[2].label}`)
          if (!dateTwo) throw new Error(`Invalid date filter: value ${filter[4].label}`)

          if (filter[1].field === "from") {
            dateTwo = new Date(dateTwo.getTime() + 60 * 60 * 24 * 1000);
          } else {
            dateOne = new Date(dateOne.getTime() + 60 * 60 * 24 * 1000);
          }
          const startDate = filter[1].field === "from" ? dateOne.toISOString() : dateTwo.toISOString()
          const endDate = filter[1].field === "to" ? dateOne.toISOString() : dateTwo.toISOString()

          urlParams.append('created-at', `${startDate};${endDate}`)
        } else throw new Error('Date filters should be of length 3 or 5.')
        break;
      case 'labels':
        let labelFilterValue = filter[2].label
        if (filter[1].field === "contains") labelFilterValue = `%${labelFilterValue}%`
        else if (filter[1].field === "startsWith") labelFilterValue = `${labelFilterValue}%`
        urlParams.append('label', labelFilterValue)
        break;

      case 'name':
        let namelFilterValue = filter[2].label
        if (filter[1].field === "contains") namelFilterValue = `%${namelFilterValue}%`
        else if (filter[1].field === "startsWith") namelFilterValue = `${namelFilterValue}%`
        urlParams.append('name', namelFilterValue)
        break;

      case 'search':
        urlParams.append('name', `${filter[1].label}%`)
        break;

      case 'user':
        // admin only
        let userFilterValue = filter[2].label
        if (filter[1].field === "contains") userFilterValue = `%${userFilterValue}%`
        else if (filter[1].field === "startsWith") userFilterValue = `${userFilterValue}%`
        urlParams.append('created-by-username', userFilterValue)
        break;
      case 'sessionSource':
        // admin only
        let sessionSourceFilterValue = filter[2].label
        if (filter[1].field === "contains") sessionSourceFilterValue = `%${sessionSourceFilterValue}%`
        else if (filter[1].field === "startsWith") sessionSourceFilterValue = `${sessionSourceFilterValue}%`
        urlParams.append('source', sessionSourceFilterValue)
        break;
      case 'sessionStatus':
        // admin only
        console.log(filter)
        let sessionStatusFilterValue = filter[2].label
        let sessionStatusFilterField = filter[1].field

        if (sessionStatusFilterValue === SessionState.DELETED) {
          if (sessionStatusFilterField === "isNot") {
            urlParams.append('is-discarded', 'false')
          } else if (sessionStatusFilterField === "is") {
            urlParams.append('is-discarded', 'true')
          } else {
            throw new Error(`Unknown uperation for filter SessionState. operation -> ${sessionStatusFilterField}`)
          }
        } else {
          if (sessionStatusFilterField === "isNot") {
            Object.keys(SessionState).forEach(sessionState => {
              if (sessionState !== sessionStatusFilterValue && sessionState !== SessionState.DELETED) {
                urlParams.append('status', sessionState)
              }
            })
          } else if (sessionStatusFilterField === "is") {
            urlParams.append('status', sessionStatusFilterValue)
          } else {
            throw new Error(`Unknown uperation for filter SessionState. operation -> ${sessionStatusFilterField}`)
          }
        }
        break;
      default:
        break;
    }
  });
  return urlParams;
};

const useStyles = makeStyles(() =>
  createStyles({
    snackSuccess: { backgroundColor: '#3D96FE' },
  })
);

const Viewer = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [rows, setRows] = useState<ISessionEntry[]>([]);
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);
  const [initLoad, setInitLoad] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  // Redux selectors
  const dashboardRefresh = useSelector((state: IStore) => state.dashboardRefresh) as DashboardRefreshState;
  const sortObject = useSelector((state: IStore) => state.dashboardSort) as ISortObject;
  const pagination = useSelector((state: IStore) => state.dashboardPagination) as IPageable;
  const appliedFilters = useSelector((state: IStore) => state.dashboardFilters) as IAppliedFilter[];
  const user = useSelector((state: IStore) => state.user) as IUser;

  const loadRows = useCallback(
    (pageNumber: number, appFilters: IAppliedFilter[], scrollToTop: boolean) => {
      const fetchData = async () => {
        const filters: URLSearchParams = transformFilters(appFilters);
        console.log(`transformedFilters: ${filters}`)
        

        getSessions(pageNumber, pagination.pageSize, filters, sortObject)
          .then(({ data }) => {
            const {
              content,
              pageable,
              totalElements,
            }: { content: ISessionEntry[]; pageable: any; totalElements: number } = data;
            const { offset, paged, pageNumber } = pageable;
            dispatch(setDashboardPagination({ offset, paged, totalElements, pageNumber }));
            setRows(() => content);
          })
          .catch(() => {
            setRows([]);
            dispatch(setDashboardPagination({ offset: 0, pageNumber: 1, totalElements: 0 }));
          })
          .finally(() => {
            setInitLoad(() => true);
            setDataIsLoading(false);
            dispatch(setDashboardRefresh(DashboardRefreshState.NONE));
            if (scrollToTop) {
              const tableElement = document.getElementById('table')
              if (tableElement) tableElement.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            }
          });

        getClientLabels().then(({ data }) => {
          dispatch(setSessionsLabels(data));
        });
      };

      setDataIsLoading(true);
      fetchData();
    },
    [dispatch, sortObject, pagination.pageSize]
  );
  
  useEffect(() => {
    if (dashboardRefresh === DashboardRefreshState.NONE) return;
    loadRows(dashboardRefresh === DashboardRefreshState.NORMAL ? pagination.pageNumber : 0, appliedFilters, true);
  }, [dashboardRefresh, loadRows, pagination.pageNumber, appliedFilters]);

  useEffect(() => {
    dispatch(setDashboardRefresh(DashboardRefreshState.NORMAL));
  }, [dispatch]);

  const handleChangePage = (event: any, pageNumber: number) => {
    dispatch(setDashboardExpendedRow(-1));
    loadRows(pageNumber, appliedFilters, true);
  };

  useInterval(() => {
    loadRows(pagination.pageNumber, appliedFilters, false)
  }, config.historyRefreshIntervalMs, [pagination.pageNumber, appliedFilters, sortObject], dataIsLoading)

  const handleRowClick = async (matchId: number, sessionName: string) => {
    const item = rows.filter(({ id }) => id === matchId);

    if (item.length === 0) return;
    const { id, isSaved, allRecordingsDiscarded } = item[0];

    const name = sessionName;

    // Current implementatin of fetching session-content and loading to Editor. Might change in future
    // TO-DO: loading indicator should be displayed when session data is being loaded and transfored into editor content
    // because it might take longer for bigger files.

    if (typeof name === 'string') {
      dispatch(setSessionName(name));
    }
    dispatch(setIsSessionDiscarded(allRecordingsDiscarded));

    try {
      if (isSaved) {
        const r = await getEditorContent(id);
        const { rawContentState } = JSON.parse(r.data.content);

        if (rawContentState) {
          dispatch(
            setTbFormat({
              rawContentState: rawContentState,
              sessionId: id,
            })
          );
        } else {
          dispatch(
            setTbFormat({
              editorStateStringified: r.data.content as string,
              sessionId: id,
            })
          );
        }

        dispatch(setEditorMode(EditorModeEnums.EDIT_MODE));
        dispatch(setValidRedirect(true));
        const aToken = getTokens()?.accessToken;
        const audioUrlPath = `${urlBackend}/${API}/${CLIENT}/${SESSIONS}/${id}/audio.wav?access_token=${aToken}&nocache=${new Date().getTime()}`;
    
        dispatch(
          setAudioInfo({
            url: audioUrlPath,
            loadNew: true,
          })
        );
        console.log("pushing")
        history.push('/editor');
        dispatch(setRowOpening(false));
      } else {
        const res = await getSessionTranscripts(id);

        const mockMessage: IReceivedTranscript = {
          messageType: ReceivedMessageTypes.TRANSCRIPT,
          isFinal: true,
          decoded: 1,
          transcript: {
            id: 1,
            content: (res.data as IContent[]).flatMap(transcriptWord => {
              return (typeof transcriptWord.content === 'string' ? JSON.parse(transcriptWord.content) : transcriptWord.content ) as ITranscriptWord[]
            })
          }
        }
        
        const sessionRecordingPagination = await getSessionRecordings(id);
        if (sessionRecordingPagination.data.content.length < 1) {
          throw new Error("Session has no recordings")
        }

        const {
          transcriptMessage
          } = paragraphTokens(mockMessage);
          let mappedFinal = [{id: transcriptMessage.transcript.id, content: transcriptMessage.transcript.content as ITranscriptWord[]}]
        console.log(sessionRecordingPagination.data.content[0].transcriptionDoDiarization ? "DIARIZATION" : "NO DIARIZATION")
        if (sessionRecordingPagination.data.content[0].transcriptionDoDiarization) {
          mappedFinal = [{id: mockMessage.transcript.id, content: mockMessage.transcript.content as ITranscriptWord[]}]
          dispatch(setDiarization(true))
        }

        let text = ''
        let speaker: string | undefined = undefined
        mappedFinal.forEach(transcript => {
          transcript.content.forEach(word => {
            if (word.speakerCode === speaker) {
              if (word.spaceBefore) {
                text += " "
              }
              text += word.text
            } else {
              console.log(`[${speaker}]: ${text}`)
              speaker = word.speakerCode
              text = ""
              if (word.spaceBefore) {
                text += " "
              }
              text += word.text
            }
          })
        })
        
        const { newEditorState } = await transformNotSavedSessionTranscriptsToLiveWordData(mappedFinal);

        batch(() => {
          dispatch(
            setTbFormat({
              editorState: newEditorState,//updatedState,
              sessionId: id,
            })
          );
          dispatch(setValidRedirect(true));
          dispatch(setEditorMode(EditorModeEnums.TB_UPLOAD_MODE));
      
          const aToken = getTokens()?.accessToken;
          const audioUrlPath = `${urlBackend}/${API}/${CLIENT}/${SESSIONS}/${id}/audio.wav?access_token=${aToken}&nocache=${new Date().getTime()}`;
      
          dispatch(
            setAudioInfo({
              url: audioUrlPath,
              loadNew: true,
            })
          );
        })
    
        history.push('/editor');
        dispatch(setRowOpening(false));
      }
    } catch (e) {
      console.log(e)
      enqueueSnackbar(
        //@ts-ignore
        e.response && e.response.data && e.response.data.message
          ? //@ts-ignore
            e.response.data.message
          : `Izbrana seja trenutno ni na voljo.`,
        { variant: 'error' }
      );
      dispatch(setRowOpening(false));
    }
  };

  return (
    <>
      <article>
        <DashboardHeader />

        {initLoad && (
          <>
            <AppliedFilters />
            <Table rows={rows} handleRowClick={handleRowClick} />
          </>
        )}

        {dataIsLoading && <LinearProgress />}

        <div className="pagination-wrapper">
          <MaterialPagination
            page={pagination.pageNumber + 1}
            onChange={(e, pn) => handleChangePage(e, pn - 1)}
            count={Math.ceil(pagination.totalElements / pagination.pageSize)}
            variant="outlined"
            shape="rounded"
            color="primary"
          />
        </div>
      </article>

      <SpeakerSettingsModal />
    </>
  );
};

export default Viewer;
