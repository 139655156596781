import axiosInstance from './axios';
import { AxiosResponse } from 'axios';
import { API, CLIENT } from './endpoints';
import { IEntry } from '../components/Editor/IEditor';
import { config } from '../config';

const backendUrl: string = config.backendApiUrl;
const baseUrl = `${backendUrl}/${API}/${CLIENT}/entries`;

export const getEntries = (): Promise<AxiosResponse<any>> => {
  return axiosInstance.get(`${baseUrl}`);
};
export const getEntry = (id: number): Promise<AxiosResponse<any>> => {
  return axiosInstance.get(`${baseUrl}/${id}`);
};
export const postEntry = (entry: Partial<IEntry>): Promise<AxiosResponse<any>> => {
  const { description, content } = entry;
  if (!description || !content) throw new Error('Missing data');
  return axiosInstance.post(
    `${baseUrl}`,
    { description, content },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};
export const deleteEntry = (id: number): Promise<AxiosResponse<any>> => {
  return axiosInstance.delete(`${baseUrl}/${id}`);
};
export const patchEntry = (entry: Partial<IEntry>): Promise<AxiosResponse<any>> => {
  const { id, description, content } = entry;
  if (!id) throw new Error('Missing id');
  return axiosInstance.patch(
    `${baseUrl}/${entry.id}`,
    { description, content },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};
export const getEntriesVariables = (): Promise<AxiosResponse<any>> => {
  return axiosInstance.get(`${baseUrl}/variables`);
};
