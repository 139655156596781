import React, { useEffect, useMemo, useRef, useState } from 'react';
import { debounce, Grid } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import AudioControls from '../AudioPlayer/AudioControls';
import { ShrinkIcon } from '../Icons/ShrinkIcon';
import { WordConfidenceButton } from './WordConfidenceButton';
import { FontSizeButton } from './FontSizeButton';
import '../../styles/css/header.css';
import '../../styles/css/session_info.css';
import { DownloadButton } from './DownloadButton';
import EntryIcon from '@material-ui/icons/FeaturedPlayListSharp';
import { EditorModeEnums, IStore } from '../../redux/store/IStore';
import { useDispatch, useSelector } from 'react-redux';
import { setHeaderIsExtended, setSessionName } from '../../redux/actions/actions';
import { convertSecondsToTimeString } from '../../shared/DataConverters';
import useInterval from '../../hooks/useInterval';
import moment from 'moment';
import { differenceInMinutes, format } from 'date-fns';
import { ILastSaved } from '../Editor/IEditor';
import { patchSessionInfo } from '../../api/SessionsService';
import { isSelectedVersionRealTime } from '../Home/helpers';
import { CopyButton } from './CopyButton';
import { useSnackbar } from 'notistack';

interface ITime {
  startTime: number;
  currentTime: number;
}

interface IProps {
  editorMode: EditorModeEnums | null;
  spellCheck: boolean;
  lastSavedTs?: number | null;
  sessionId?: number | null;
  audioCanPlay: boolean;
  audioIsPlaying: boolean;
  audioIsInError: boolean;
  audioIsInErrorMessage?: string;
  playbackRate: number;
  audioDuration: number | null | undefined;
  onEntriesClick: () => void;
  toggleSpellCheck: () => void;
  handlePlayBackRate: () => void;
  handleDynamicPlaybackRate: (playbackRate: number) => void;
  handlePlaybackRateLower: () => void;
  handlePlaybackRateHigher: () => void;
  togglePlayPause: (e: React.SyntheticEvent) => void;
}

const notRealtimeMessage = '';

const Header = ({
  editorMode,
  toggleSpellCheck,
  spellCheck,
  handlePlayBackRate,
  playbackRate,
  audioIsPlaying,
  audioCanPlay,
  audioDuration,
  sessionId,
  onEntriesClick,
  handleDynamicPlaybackRate,
  handlePlaybackRateLower,
  handlePlaybackRateHigher,
  lastSavedTs,
  togglePlayPause,
  audioIsInError,
  audioIsInErrorMessage
}: IProps) => {
  const extended = useSelector<IStore, boolean>((state) => state.headerIsExtended);
  const diarization = useSelector<IStore, boolean>((state) => state.diarization);
  const cfg = useSelector((state: IStore) => state.config);
  const sttStatus = useSelector((state: IStore) => state.sttStatus);

  const modelIsRealtime = useMemo(
    () => (!cfg || !sttStatus ? false : isSelectedVersionRealTime(cfg, sttStatus)),
    [cfg, sttStatus]
  );
  const showContent =
    editorMode === EditorModeEnums.EDIT_MODE ||
    editorMode === EditorModeEnums.PLAYING_MODE ||
    editorMode === EditorModeEnums.TB_UPLOAD_MODE;

  const dispatch = useDispatch();

  const onExtend = () => {
    dispatch(setHeaderIsExtended(!extended));
  };

  const Extender = (
    <ShrinkIcon
      onClick={onExtend}
      style={{
        width: 30,
        position: 'absolute',
        left: '50%',
        transform: !extended ? 'translate(-50%) rotate(180deg)' : 'translate(-50%)',
        bottom: !extended ? '-20px' : '0px',
        cursor: 'pointer',
        zIndex: 11,
      }}
    />
  );

  return (
    <div id="header" className="header_main_wrapper">
      <Collapse in={extended}>
        <Grid
          className="header_extender_content_wrapper"
          container
          alignItems="center"
          justifyContent="space-between"
          style={{ flexWrap: 'nowrap' }}
        >
          {editorMode === EditorModeEnums.RECORDING_MODE && (
            <Grid item>
              <LiveTimeString editorMode={editorMode} />
            </Grid>
          )}

          {diarization && (
            <span
              style={
                /*editorMode === EditorModeEnums.RECORDING_MODE
                  ? { marginLeft: 40, textAlign: 'left' }
                  : */{ textAlign: 'center' }
              }
              className="notrealtime_message"
            >
              Poteka razbijanje na govorce. Prosimo počakajte.
              {<span className="animating_dots">...</span>}
            </span>
          )}

          {(editorMode === EditorModeEnums.TRANSCRIBING_UPLOAD_MODE || !modelIsRealtime) && !showContent && (
            <span
              style={
                editorMode === EditorModeEnums.RECORDING_MODE
                  ? { marginLeft: 40, textAlign: 'left' }
                  : { textAlign: 'center' }
              }
              className="notrealtime_message"
            >
              {editorMode === EditorModeEnums.RECORDING_MODE
                ? 'Audio se transkribira, končni transkript bo viden, ko zaključite snemanje'
                : 'Datoteka se transkribira. Prosimo počakajte'}{' '}
              {<span className="animating_dots">...</span>}
            </span>
          )}

          {showContent && extended && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  position: 'relative',
                  pointerEvents: audioCanPlay && !audioIsInError ? 'all' : 'none',
                }}
              >
                <div style={{ opacity: audioCanPlay && !audioIsInError ? 1 : 0.4 }}>
                  <AudioControls
                    audioCanPlay={audioCanPlay}
                    handlePlayBackRate={handlePlayBackRate}
                    togglePlayPause={togglePlayPause}
                    audioIsPlaying={audioIsPlaying}
                    playbackRate={playbackRate}
                    handleDynamicPlaybackRate={handleDynamicPlaybackRate}
                    handlePlaybackRateLower={handlePlaybackRateLower}
                    handlePlaybackRateHigher={handlePlaybackRateHigher}
                  />
                </div>
                {audioIsInError && (
                  <span
                    style={{
                      position: 'absolute',
                      top: '100%',
                      left: 15,
                      fontFamily: 'Roboto',
                      color: '#d32f2fed',
                      fontSize: '14px',
                      width: '400px'
                    }}
                  >
                    {audioIsInErrorMessage || 'S posnetkom je nekaj narobe'}
                  </span>
                )}
              </div>

              {/* <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                    <SessionInfo lastSavedTs={lastSavedTs} />
                  </div> */}

              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    {editorMode && <AudioTimeString audioDuration={audioDuration} editorMode={editorMode} />}
                  </Grid>

                  <Grid item>
                    <FontSizeButton />
                  </Grid>
                  <Grid item>
                    <button
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      onClick={onEntriesClick}
                    >
                      <EntryIcon
                        className="icon_button"
                        style={{
                          padding: '0 5px',
                          fill: false ? '#2B8DFF' : '#CCCCCC',
                        }}
                      />
                    </button>
                  </Grid>
                  <Grid item>
                    <WordConfidenceButton toggleSpellCheck={toggleSpellCheck} spellCheck={spellCheck} />
                  </Grid>
                  <Grid item>
                    <CopyButton />
                  </Grid>
                  <Grid item>
                    <DownloadButton />
                  </Grid>

                  {/* <Grid item>
                      <StrikeThroughIcon
                        onClick={toggleStrikethrough}
                        className="icon_button"
                        viewBox="0 0 48 48"
                        style={{
                          fill: strikethrough ? "#2B8DFF" : "#CCCCCC"
                        }}
                      />
                    </Grid>

                    <Grid item>
                      <PersonPinIcon
                        onClick={toggleSpeakers}
                        className="icon_button"
                        viewBox="0 0 48 48"
                        style={{
                          fill: showSpeakers ? "#2B8DFF" : "#CCCCCC"
                        }}
                      />
                    </Grid>

                    <Grid item>
                      <DownloadButton
                        handleDownloadDocx={handleDownloadDocx}
                        handleDownloadSrt={handleDownloadSrt}
                        handleDownloadTb={handleDownloadTb}
                      />
                    </Grid>
                    */}
                </Grid>
              </Grid>
            </div>
          )}
        </Grid>
      </Collapse>

      {Extender}
    </div>
  );
};

export default React.memo(Header);

const LastSavedIndicator = ({ lastSaved, titleSaved }: { lastSaved: ILastSaved; titleSaved: boolean }) => {
  const { isSaving, lastSavedTs, lastSaveSuccess } = lastSaved;
  const [minAgo, setMinAgo] = useState<number>(0);
  const [currTime, setCurrTime] = useState(Date.now());
  useInterval(() => {
    setCurrTime(Date.now());
  }, 1000 * 60);

  useEffect(() => {
    if (!lastSavedTs || !currTime) return;
    const d = differenceInMinutes(currTime, lastSavedTs);

    setMinAgo(d);
  }, [lastSavedTs, currTime]);

  return (
    <div
      style={{
        fontFamily: 'Roboto',
        fontSize: '11px',
        color: '#707070',
        opacity: lastSavedTs || titleSaved ? 1 : 0,
      }}
    >
      {/* {minAgo < 1 ? 'Last saved: just now' : `Last saved: ${minAgo} minutes ago`} */}
      {isSaving
        ? 'Saving changes...'
        : lastSaveSuccess && titleSaved
        ? 'All changes saved'
        : lastSaveSuccess && !titleSaved
        ? 'Last editor changes saved'
        : titleSaved
        ? 'Title saved'
        : lastSavedTs
        ? `Last saved ${format(lastSavedTs, 'd. MMM, H:mm')}`
        : '______'}
    </div>
  );
};

const SessionInfoNormal = ({
  currentTitle = 'Untitled session',
  lastSaved,
  sessionId,
  footerWidth,
}: {
  currentTitle: string;
  lastSaved: ILastSaved;
  sessionId?: number | null;
  footerWidth?: number;
}) => {
  const sessionName = useSelector((state: IStore) => state.sessionName);
  const [sessionTitle, setSessionTitle] = useState(sessionName);
  const [titleSaved, setTitleSaved] = useState(false);
  const [inputWidth, setInputWidth] = useState(sessionName.length);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const saveCurrentSessionName = React.useCallback(
    debounce(async (newName: string, sessionId?: number) => {
      if (newName === '') return;
      dispatch(setSessionName(newName));
      try {
        if (sessionId && newName) {
          await patchSessionInfo({ name: newName }, sessionId);
          setTitleSaved(true);
        }
      } catch (e) {
        setTitleSaved(false);
      }
    }, 1000),
    []
  );

  useEffect(() => {
    saveCurrentSessionName(sessionTitle, sessionId || undefined);
  }, [sessionTitle, sessionId]);

  useEffect(() => {
    if (sessionName === '') {
      setSessionTitle('Untitled session');
    }
  }, [sessionName]);

  const inputRef = useRef<HTMLInputElement>(null);

  const inputW = footerWidth ? Math.floor(footerWidth / 3) : 300;

  return (
    <div className="session_info_wrapper" style={{ width: '100%', maxWidth: inputW }}>
      {/* <input value={sessionTitle} type="text" onChange={(e) => setSessionTitle(e.target.value)} /> */}
      {/* <div> */}
      <input
        onBlur={() => {
          if (sessionTitle === '') {
            setSessionTitle('Untitled session');
          }
        }}
        onClick={() => {
          if (sessionTitle === 'Untitled session') {
            setSessionTitle('');
          }
        }}
        ref={inputRef}
        value={sessionTitle}
        // variant="outlined"
        // inputRef={replaceRef}
        // error={stringError ? true : false}
        style={{
          // width: `${inputWidth}ch`,
          width: inputW,
          minWidth: '20px',
          // maxWidth: '100%',
          fontFamily: 'Roboto',
          fontSize: '18px',
          color: 'rgb(112, 112, 112)',
          padding: 0,
          marginBottom: 5,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        // placeholder="Vnesite zamenjavo"
        // margin="dense"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.value.length > 256) {
            enqueueSnackbar(`Maksimalna dolžina imena seje je 256 znakov.`, {
              variant: 'error',
              autoHideDuration: 4000,
            });
            return;
          }
          setSessionTitle(e.target.value);
          setInputWidth(e.target.value.length);
        }}
        // helperText={stringError || ''}
      />
      {/* </div> */}
      <LastSavedIndicator lastSaved={lastSaved} titleSaved={titleSaved} />
    </div>
  );
};
export const SessionInfo = React.memo(SessionInfoNormal);

const AudioTimeStringNormal = ({
  audioDuration,
  editorMode,
}: {
  audioDuration?: number | null;
  editorMode: EditorModeEnums;
}) => {
  const [audioTime, setAudioTime] = useState<number>(0);
  const currentTime = useSelector<IStore, number>((state) => state.currentTime);

  useInterval(
    () => {
      currentTime !== audioTime && setAudioTime(currentTime);
    },
    100,
    [],
    editorMode === EditorModeEnums.TRANSCRIBING_UPLOAD_MODE
  );

  return (
    <span className="time_string">
      {convertSecondsToTimeString(audioTime, false, true)}
      {audioDuration ? ' / ' + convertSecondsToTimeString(audioDuration, false, true) : ''}
    </span>
  );
};
const AudioTimeString = React.memo(AudioTimeStringNormal);

const LiveTimeStringNormal = ({ editorMode }: { editorMode: EditorModeEnums }) => {
  const timeToAdd = useRef<number>(0);
  const [time, setTime] = useState<ITime>({ startTime: 0, currentTime: 0 });
  useEffect(() => {
    if (editorMode === EditorModeEnums.RECORDING_MODE) {
      timeToAdd.current = time.currentTime;
      setTime({
        ...time,
        startTime: new Date().getTime(),
      });
    }
  }, [editorMode]);

  useInterval(
    () => {
      const currentTime = moment(new Date().getTime()).diff(moment(time.startTime), 'milliseconds');
      setTime((time) => {
        return {
          ...time,
          currentTime: currentTime / 1000 + timeToAdd.current,
        };
      });
    },
    100,
    [],
    editorMode !== EditorModeEnums.RECORDING_MODE
  );
  return (
    <div
      color="primary"
      style={{
        fontFamily: 'Roboto Mono',
        fontSize: 24,
        userSelect: 'none',
        color: '#707070',
        minWidth: 130,
      }}
    >
      {convertSecondsToTimeString(time.currentTime, true)}
    </div>
  );
};
const LiveTimeString = React.memo(LiveTimeStringNormal);
