import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { PlayIcon } from '../Icons/PlayIcon';
import { PauseIcon } from '../Icons/PauseIcon';
import { PrevIcon } from '../Icons/PrevIcon';
import { NextIcon } from '../Icons/NextIcon';
import { PlaybackRateButton } from '../Header/PlaybackRateButton';
import { useDispatch, useSelector } from 'react-redux';
import { EditorModeEnums, IStore } from '../../redux/store/IStore';
import { setClickedTime } from '../../redux/actions/actions';
import '../../styles/css/header.css';

interface IProps {
  audioIsPlaying: boolean;
  playbackRate: number;
  audioCanPlay: boolean;
  handlePlayBackRate: () => void;
  handlePlaybackRateLower: () => void;
  handlePlaybackRateHigher: () => void;
  togglePlayPause: (e: React.SyntheticEvent) => void;
  handleDynamicPlaybackRate: (playbackRate: number) => void;
}

const AudioControls = ({
  audioIsPlaying,
  playbackRate,
  handleDynamicPlaybackRate,
  handlePlaybackRateLower,
  handlePlaybackRateHigher,
  togglePlayPause,
}: IProps) => {
  const [playMode, setPlayMode] = useState<boolean>(false);

  const dispatch = useDispatch();
  const editorMode = useSelector((state: IStore) => state.editorMode);
  const currentTime = useSelector<IStore, number>((state) => state.currentTime);

  useEffect(() => {
    if (audioIsPlaying) {
      setPlayMode(true);
    }
  }, [audioIsPlaying]);

  const handleForwardClick = () => {
    if (editorMode === EditorModeEnums.PLAYING_MODE) {
      dispatch(setClickedTime(currentTime + 5));
    }
  };

  const handleBackwardClick = () => {
    if (editorMode === EditorModeEnums.PLAYING_MODE) {
      if (currentTime - 5 <= 0) {
        dispatch(setClickedTime(0));
      }
      dispatch(setClickedTime(currentTime - 5));
    }
  };

  return (
    <Grid item>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <button onClick={togglePlayPause}>
            {audioIsPlaying ? (
              <PauseIcon
                className="icon_button"
                viewBox="0 0 48 48"
                style={{
                  fill: '#2B8DFF',
                }}
              />
            ) : (
              <PlayIcon
                className="icon_button"
                viewBox="0 0 48 48"
                style={{
                  fill: playMode ? '#2B8DFF' : '#ccc',
                }}
              />
            )}
          </button>
        </Grid>
        <Grid item>
          <PlaybackRateButton
            handleDynamicPlaybackRate={handleDynamicPlaybackRate}
            playbackRatio={playbackRate}
            handlePlaybackRateLower={handlePlaybackRateLower}
            handlePlaybackRateHigher={handlePlaybackRateHigher}
          />
        </Grid>
        <Grid item>
          <button onClick={handleBackwardClick}>
            <PrevIcon className="icon_button" viewBox="0 0 48 48" />
          </button>
        </Grid>
        <Grid item>
          <button onClick={handleForwardClick}>
            <NextIcon className="icon_button" viewBox="0 0 48 48" />
          </button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AudioControls;
