import { IPhonems } from '../components/DictionaryModal/IDictionaryModal';

export default function checkPronaunciationWithPhonems(pronaunciation: string, phones: IPhonems) {
  let silentP = phones.silentPhones;
  let nonSilentP = phones.nonsilentPhones;

  let phoneList;
  let pronaunciationPhones: string[] = [];

  let newP = '';

  const nonSilentArr = nonSilentP.split(' ');
  if (silentP) {
    const silentArr = silentP.split(' ');
    phoneList = [...nonSilentArr, ...silentArr].sort((a, b) => b.length - a.length);
  } else {
    phoneList = nonSilentArr.sort((a, b) => b.length - a.length);
  }

  let i = 0;
  while (i < pronaunciation.length) {
    let phoneFound = false;

    for (let k = 0; k < phoneList.length; k++) {
      if (phoneList[k] === pronaunciation.slice(i, i + phoneList[k].length)) {
        pronaunciationPhones.push(phoneList[k]);
        i += phoneList[k].length;
        phoneFound = true;
        break;
      }
    }

    if (!phoneFound) {
      return false;
    }
  }
  newP = pronaunciationPhones.join(' ');

  return newP;
}
