import React, { useState } from 'react';
import { ShrinkIcon } from '../Icons/ShrinkIcon';
import { Grid } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import '../../styles/css/header.css';
import '../../styles/css/dashboard.css';

const HeaderWrapper = ({ children }) => {
  const [extended, setExtended] = useState<boolean>(true);

  return (
    <div id="header" className="header-wrapper-dashboard" style={{ position: 'relative', zIndex: 10 }}>
      <Collapse in={extended}>
        <Grid
          className="header_extender_content_wrapper"
          justifyContent="space-between"
          alignItems="center"
          container
        >
          {children}
        </Grid>
      </Collapse>

      <Extender extended={extended} setExtended={setExtended} />
    </div>
  );
};

const Extender = ({ extended, setExtended }) => (
  <ShrinkIcon
    onClick={() => setExtended(!extended)}
    className={`header_extender ${extended ? 'extended' : ''}`}
  />
);

export default HeaderWrapper;
