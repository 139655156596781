// const getLanguagesFromConfig = (opt: ITranscriptionLanguageOption[]) => {
//   let currL = '';
//   let newL: clientLang[] = [];
//   opt.forEach((o) => {
//     let langAllowed = false;
import { clientLang, IConfig, ILanguage, ISttStatus } from './ISettings';

//     if (o.languageCode !== currL) {
//       opt
//         .find((op) => op.languageCode === o.languageCode)
//         ?.domains.forEach((d, i, arr) => {
//           if (d.allowed === true) {
//             langAllowed = true;
//           }
//           if (i === arr.length - 1 && langAllowed) {
//             langAllowed = false;
//             d.models.forEach((m) => {
//               if (m.available === true && m.allowed) {
//                 langAllowed = true;
//               }
//             });
//           }
//         });

//       if (langAllowed) {
//       }

//       newL = [...newL, { languageCode: o.languageCode, available: langAllowed }];
//       currL = o.languageCode;
//     }
//   });

//   return newL;
// };

export const isSelectedVersionRealTime = (config: IConfig, sttStatus: ISttStatus) => {
  const languages = sttStatus.frameworks.map((framework) => framework.languages).flat();
  const ver = config.stt.model.value;
  const lan = config.stt.language.value;
  const dom = config.stt.domain.value;

  for (const language of languages.filter((l) => l.code === lan)) {
    for (const domain of language.domains.filter((d) => d.code === dom)) {
      const model = domain.models.find((m) => m.code === ver);
      if (!!model) {
        return model.isRealtime;
      }
    }
  }

  return false;
};

export const getLanguagesFromConfig = (options: ILanguage[]) => {
  let newOptions: clientLang[] = [];

  for (let i = 0; i < options.length; i++) {
    const option = options[i];

    // If language is not allowed just continue to the next
    if (!option.isAllowed) {
      newOptions.push({ languageCode: option.code, available: false });
      continue;
    }

    let langAvailable = false;

    // Try to find at least 1 available model version
    for (let j = 0; j < option.domains.length; j++) {
      const domain = option.domains[j];

      // If domain is not allowed you will definitely not find an available model
      if (!domain.isAllowed) continue;

      for (let k = 0; k < domain.models.length; k++) {
        const model = domain.models[k];

        // Found a model that may be used
        if (model.isAvailable && model.isAllowed) {
          langAvailable = true;
          break;
        }
      }

      // If we found a model we can stop searching
      if (langAvailable) break;
    }

    newOptions.push({ languageCode: option.code, available: langAvailable });
  }

  return newOptions;
};

// const generateDomainVersion = (opt: ITranscriptionLanguageOption[], currLang: string, currDomain: string) => {
//     const correctOpts = opt.find((opt) => opt.languageCode === currLang)?.domains;
//     if (!correctOpts) return [];
//     const correctDomain = correctOpts.find((o) => o.domainCode === currDomain);
//     return correctDomain ? correctDomain.models : [];
// };

/*export const generateDomains = (opt: ILanguage[], currLang: string) => {
  const correctDomain = opt.find((opt) => opt.code === currLang);
  return correctDomain ? correctDomain.domains : [];
};*/
