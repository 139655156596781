const convertSecondsToTimeString = (secondsAndMilliseconds: number, alwaysShowMillis: boolean) => {
  const allSeconds = Math.floor(secondsAndMilliseconds);
  const milliseconds = secondsAndMilliseconds - allSeconds;

  const hours = Math.floor(allSeconds / 3600);

  const remainingSeconds = allSeconds - 3600 * hours;
  const minutes = Math.floor(remainingSeconds / 60);

  let seconds = remainingSeconds - 60 * minutes;
  seconds += milliseconds;
  seconds = Math.round((seconds + 0.00001) * 100) / 100;

  let timeString = '';

  if (hours > 0) {
    timeString += hours < 10 ? '0' + hours : hours;
    timeString += ':';
  }

  timeString += minutes < 10 ? '0' + minutes : minutes;
  timeString += ':';

  timeString += seconds < 10 ? '0' + seconds : seconds;

  if (alwaysShowMillis && Math.floor(seconds) === seconds) {
    timeString += '.0';
  }

  return timeString;
};

export default convertSecondsToTimeString;
