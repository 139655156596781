import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import ShareIcon from '@material-ui/icons/Share';
import format from 'date-fns/format';
import React, { useEffect, useState } from 'react';
import convertSecondsToTimeString from '../../shared/convertSecondsToTimeString';
import '../../styles/css/dashboard.css';
import { ColumnTypes, ISessionEntry } from './IDashboard';
import { convertStatusToSlovenian } from '../../shared/helpers';

interface IProps {
  type: ColumnTypes;
  data: any;
  row: ISessionEntry;
  sources: string[];
}

const TableRowItem = ({ type, data, row, sources }: IProps) => {
  const [display, setDisplay] = useState('');

  useEffect(() => {
    if (type === ColumnTypes.USER) setDisplay(data.username);
    else if (type === ColumnTypes.DATE) setDisplay(format(new Date(data), 'dd.M.yyyy HH:mm:ss'));
    else if (type === ColumnTypes.DATE_TIME) {
      setDisplay(format(new Date(data), 'dd.M.yyyy HH:mm:ss'));
    } else if (type === ColumnTypes.TIME_FLOAT) setDisplay(convertSecondsToTimeString(data, true));
    else if (type === ColumnTypes.SOURCE)
      setDisplay(sources.length ? sources.map((s) => s).join(' ') : data ? 'Editor' : 'API');
    else if (type === ColumnTypes.STATUS) {
      setDisplay(convertStatusToSlovenian(data));
    }
    else setDisplay(data);
  }, [data, type, row, sources]);

  const styleProps = {};

  if (row.isDiscarded) styleProps['color'] = '#f55f6c';
  else if (type === ColumnTypes.TITLE) styleProps['color'] = '#1E62B2';

  const showSharedWithMeIcon = type === ColumnTypes.TITLE && row.sharedWithMe;
  const showSharedWithOthers = type === ColumnTypes.TITLE && row.sharedWithOthers;

  if (showSharedWithOthers || showSharedWithMeIcon) styleProps['paddingLeft'] = '30px';

  return (
    <div className="row-individual-attribute" style={styleProps}>
      {(showSharedWithMeIcon || showSharedWithOthers) && (
        <Box style={{ position: 'absolute', width: '30px', left: 0, top: '2px' }}>
          <Tooltip
            title={
              showSharedWithMeIcon
                ? `Uporabnik '${row.createdBy.username}' deli ta posnetek z vami`
                : 'Posnetek je v skupni rabi'
            }
          >
            {showSharedWithMeIcon ? <PeopleOutlineIcon /> : <ShareIcon style={{ padding: '2px' }} />}
          </Tooltip>
        </Box>
      )}
      {display}
    </div>
  );
};

export default TableRowItem;
