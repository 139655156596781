import React from 'react';
import '../../styles/css/header.css';
import CustomPopover from '../../shared/CustomPopover';
import { DownloadIcon } from '../Icons/DownloadIcon';
import useDownload from '../../hooks/useDownload';

export const DownloadButton = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const { handleDownloadSrt, handleDownloadTb, handleDownloadAudio, handleDownloadDocx } = useDownload();

  const onMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onMouseLeave = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onMouseLeave={onMouseLeave}
      >
        <DownloadIcon
          aria-describedby={id}
          className="icon_button"
          onMouseEnter={onMouseEnter}
          viewBox="0 0 48 48"
        />
        <CustomPopover open={open} anchorEl={anchorEl} onClose={onMouseLeave} width="100px">
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div className="download_button_option_box" onClick={handleDownloadDocx}>
                <div className="download_button_option">.docx</div>
              </div>
              <div className="download_button_option_box" onClick={handleDownloadSrt}>
                <div className="download_button_option">.srt</div>
              </div>
              <div className="download_button_option_box" onClick={handleDownloadTb}>
                <div className="download_button_option">.tb</div>
              </div>
              <div className="download_button_option_box" onClick={handleDownloadAudio}>
                <div className="download_button_option">.wav</div>
              </div>
            </div>
          </div>
        </CustomPopover>
      </div>
    </>
  );
};
