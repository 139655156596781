export const readFile = (file: any) => {
  if (!file) return null;
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = (e: any) => {
      resolve(e.target.result);
    };

    fileReader.readAsArrayBuffer(file);
  });
};
