import React, { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, { defaultSuggestionsFilter } from '@draft-js-plugins/mention';
import { MentionData } from '@draft-js-plugins/mention';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';

export interface EntryVarData extends MentionData {
  code: string;
  description: string;
  value: string;
}

export function Entry(props: any) {
  const { mention } = props;

  return (
    <span className="entry_badge">
      {mention.code ? mention.code : mention.variableCode ? mention.variableCode : 'N/A'}
    </span>
  );
}

function EntryItem(props: any): ReactElement {
  const {
    mention,
    theme,
    searchValue, // eslint-disable-line @typescript-eslint/no-unused-vars
    isFocused, // eslint-disable-line @typescript-eslint/no-unused-vars
    ...parentProps
  } = props;

  return (
    <div {...parentProps}>
      <div className={theme?.mentionSuggestionsEntryContainer}>
        <div className={theme?.mentionSuggestionsEntryContainerRight}>
          <div className={theme?.mentionSuggestionsEntryText}>{mention.description}</div>
        </div>
      </div>
    </div>
  );
}

function SimpleEntriesEditor({ editorState, setEditorState, entriesVars }): ReactElement {
  const ref = useRef<Editor>(null);
  const [open, setOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [suggestions, setSuggestions] = useState<EntryVarData[]>(entriesVars || []);

  const { MentionSuggestions, plugins, Toolbar } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'SEGMENTED',
      supportWhitespace: false,
      mentionTrigger: ['{'],
      mentionComponent: (mentionProps) => {
        return <Entry {...mentionProps} />;
      },
    });

    const staticToolbarPlugin = createToolbarPlugin();
    const { Toolbar } = staticToolbarPlugin;
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin, staticToolbarPlugin];
    return { plugins, MentionSuggestions, Toolbar };
  }, []);

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);
  const onSearchChange = useCallback(
    ({ value }: { value: string }) => {
      setSuggestions(defaultSuggestionsFilter(value, entriesVars) as EntryVarData[]);
    },
    [entriesVars]
  );

  return (
    <>
      <div className="entries_modal__editor__toolbar_wrapper">
        <Toolbar />
      </div>
      <div
        className={'entries_modal__editor_wrapper'}
        style={{
          borderColor: isFocused ? '#2b8dff' : '#ddd',
          transition: 'border-color 100ms ease-in-out',
          // height: 'calc(100% - 125px)',
          height: '100%',
        }}
        onClick={() => {
          ref.current!.focus();
        }}
      >
        <Editor
          editorKey={'editor'}
          editorState={editorState}
          onChange={setEditorState}
          plugins={plugins}
          ref={ref}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          placeholder="Vnesite besedilo vnosa"
        />
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
          onAddMention={() => {
            // get the mention object selected
          }}
          entryComponent={EntryItem}
        />
      </div>
    </>
  );
}

export default React.memo(SimpleEntriesEditor);
