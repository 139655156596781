import React, { useEffect, useRef, useState } from 'react';
import SettingsDrawer from './SettingsDrawer';
import FooterInfo from './FooterInfo';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import {
  setAudioInfo,
  setTbFormat,
  setSessionName,
  setValidRedirect,
  setEditorMode,
  setHomeFlowState,
  setConsumptionModal,
  setDashboardFilters,
  setSpeakerSettingsModal,
} from '../../redux/actions/actions';
import { ConsumtionModalTemplate, EditorModeEnums, IStore, UserRoleEnums } from '../../redux/store/IStore';
import separateExtension from '../../shared/separateExtension';
import { isAuthenticated } from '../../api/AuthenticationService';
import '../../styles/css/home.css';
import useAudioSocket from '../../hooks/useAudioSocket';
import { motion } from 'framer-motion';
import { readFile } from '../../shared/readFile';
import { CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import useWs from '../../hooks/useWs';
import { processFile } from '../../api/uploadService';
import WarnMessageContent from '../CustomNotistack/WarnMessageContent';
import { checkIfSelectedOptionsAreAvailable, isRealtime, isUpdating } from '../../shared/helpers';
import SpeakerSettingsModal from '../SpeakerSettings/SpeakerSettingsModal';

enum FileExtensions {
  WAV = 'wav',
  TB = 'tb',
}


const Home = () => {
  const [isMounted, setIsMounted] = useState(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const user = useSelector((state: IStore) => state.user);
  const fontsLoaded = useSelector((state: IStore) => state.fontsLoaded);
  const speakerSettingsModal = useSelector((state: IStore) => state.speakerSettingsModal);
  const liveFlowInProgress = useSelector((state: IStore) => state.homeFlowStates.liveFlowInProgress);
  const uploadFlowInProgress = useSelector((state: IStore) => state.homeFlowStates.uploadFlowInProgress);
  const history = useHistory();
  const fileUploadRef = React.useRef<HTMLInputElement>(null);
  const uploadLabelRef = React.useRef<HTMLLabelElement>(null);
  const progressRef = React.useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { startRecordingLive, startSendingChunks } = useAudioSocket();
  const { enqueueSnackbar } = useSnackbar();
  const { isConnected } = useWs();
  const sttStatus = useSelector((state: IStore) => state.sttStatus);
  const config = useSelector((state: IStore) => state.config);
  const activeQuota = useSelector((state: IStore) => state.activeQuota);

  useEffect(() => {
    dispatch(setDashboardFilters([]));
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!isMounted) return;
    if (!user) {
      setShowSettings(true);
    }
  }, [user, isMounted]);

  const handleNotAuthFlow = () => {
    setShowSettings(true);

    enqueueSnackbar('Pred uporabo storitev se morate avtenticirati.', { variant: 'error' });
  };

  const handleLiveClick = async () => {
    if (liveFlowInProgress || uploadFlowInProgress) return;

    dispatch(setHomeFlowState({ liveFlowInProgress: true }));

    if (!isAuthenticated() || !user || !user.isAuthenticated) {
      handleNotAuthFlow();
      dispatch(setHomeFlowState({ liveFlowInProgress: false }));
      return;
    }

    if (!user.userRoles.includes(UserRoleEnums.LIVE_STREAMER)) {
      enqueueSnackbar('Za snemanje v živo nimate dovoljenja.', { variant: 'error' });
      dispatch(setHomeFlowState({ liveFlowInProgress: false }));
      return;
    }

    /*const { isAvailable, message } = await checkIfSelectedOptionsAreAvailable();
    if (!isAvailable) {
      enqueueSnackbar(message, { variant: 'error' });
      dispatch(setHomeFlowState({ liveFlowInProgress: false }));
      return;
    }*/

    
    try {
      await startRecordingLive({ redirectToEditor: true });
    } catch (error) {
      console.log(error)
    }
  };

  const updateProgress = (value: number) => {
    if (progressRef.current) {
      progressRef.current.style.width = 383 * value + "px";
    }
  }

  const handleFileChange = async (event: any) => {
    dispatch(setHomeFlowState({ uploadFlowInProgress: true }));
    if (!isAuthenticated() || !user) {
      handleNotAuthFlow();
      dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      return;
    }

    if (!user.userRoles.includes(UserRoleEnums.FILE_UPLOADER)) {
      enqueueSnackbar('Za nalaganje nimate dovoljenja', { variant: 'error' });
      dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      return;
    }

    const { isAvailable, message } = await checkIfSelectedOptionsAreAvailable();
    if (!isAvailable) {
      enqueueSnackbar(message, { variant: 'error' });
      dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      return;
    }

    const files: FileList = event.target.files;

    let tbIndex: number | null = null;
    let wavIndex: number | null = null;

    // Check for existence of files
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (file) {
        //@ts-ignore
        const [fileName, fileExtension] = separateExtension(file.name);

        // Found a .tb file
        if (fileExtension === FileExtensions.TB) {
          // If it has not yet been found its ok
          if (tbIndex === null) {
            tbIndex = i;
          }

          // If it has already been found its a duplicate
          else {
            enqueueSnackbar('You can only upload 1 .tb file!', { variant: 'error' });
            dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
            return;
          }
        }

        // Found a .wav file
        if (fileExtension === FileExtensions.WAV) {
          // If it has not yet been found its ok
          if (wavIndex === null) {
            wavIndex = i;
          }

          // If it has already been found its a duplicate
          else {
            enqueueSnackbar('You can only upload 1 .wav file!', { variant: 'error' });
            dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
            return;
          }
        }
      } else {
        dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      }
    }

    // Error
    // if (tbIndex === null && wavIndex === null) {

    //   // resetUploadButton();
    //   setIsUploading(false);
    //   setDidUpload(false);
    //   return;
    // }

    // Error
    if (tbIndex !== null && wavIndex === null) {
      enqueueSnackbar('Please, upload the corresponding .wav file as well!', { variant: 'error' });
      dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      return;
    }

    // Flow for only wav
    else if (tbIndex === null) {
      //@ts-ignore
      const file = files.item(wavIndex);
      if (file) {
        //@ts-ignore

        /*const duration = file.size / (16000 * 1 * 2)
        if (
          activeQuota &&
          activeQuota.chunk.secondsLimit &&
          activeQuota.chunk.secondsLimit - activeQuota.chunk.secondsConsumed < duration
        ) {
          //Alert user
          enqueueSnackbar('Količina zakupljenih sekund je premajhna. Datoteka je predolga.', { variant: 'error' });
          dispatch(setConsumptionModal({
            visible: true,
            template: ConsumtionModalTemplate.UPLOAD_LIMIT
          }))
          dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
          return;
        }

        if (activeQuota && activeQuota.chunk.secondsWarn && activeQuota.chunk.secondsWarn <= activeQuota.chunk.secondsConsumed + duration) {
          enqueueSnackbar(<WarnMessageContent />, {variant: "warn"})
        }*/
        
        
        const [fileName, fileExtension] = separateExtension(file.name);
        dispatch(setSessionName(fileName));

        let loadedWavFile: any;

        try {
          loadedWavFile = await readFile(file);
          
        } catch (err) {
          console.log(err);

          enqueueSnackbar('Z zvočnim posnetkom je nekaj narobe. Prosimo izberite drugega.', {
            variant: 'error',
          });
          dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
          return;
        }

        // const allowedChannels = [1];
        // if (!allowedChannels.includes(waveFile.numChannels)) {
        //   // setLoadedWavFile(undefined);

        //   return;
        // }

        // const allowedSampleRates = [16000];
        // if (!allowedSampleRates.includes(waveFile.sampleRate)) {
        //   // setLoadedWavFile(undefined);

        //   return;
        // }


        
        try {
          await processFile(file, updateProgress)
          updateProgress(0)
          dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
          const elements = document.getElementsByClassName("button_history")
          if (elements.length > 0) {
            const button = elements[0] as HTMLButtonElement
            button.click()
          }
        } catch (error) {
          updateProgress(0)
          dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
          console.log(error)
          enqueueSnackbar('Med nalaganjem zvočnega posnetka je prišlo do napake.', {
            variant: 'error',
          });
        }
      } else {
        dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      }
    }

    // Flow for both .tb and .wav
    else if (wavIndex !== null && tbIndex !== null) {
      //@ts-ignore
      const tbFile = files.item(tbIndex);
      //@ts-ignore
      const wavFile = files.item(wavIndex);

      let tbFinished = false;
      let wavFinished = false;

      if (tbFile && wavFile) {
        //@ts-ignore
        const [fileName, fileExtension] = separateExtension(wavFile.name);
        const tbReader = new FileReader();
        tbReader.onload = (e: any) => {
          tbFinished = true;
          let tbFormat: any = JSON.parse(e.target.result);

          if (tbFormat.transcript && tbFormat.transcript[0] && tbFormat.transcript[0].text) {
            tbFormat = { ...tbFormat, transcript: [[...tbFormat.transcript]] };
          } else if (tbFormat.blocks) {
            tbFormat = { editorStateStringified: JSON.stringify(tbFormat) };
          }
          dispatch(setTbFormat(tbFormat));
          dispatch(setValidRedirect(true));
          dispatch(setEditorMode(EditorModeEnums.TB_UPLOAD_MODE));
          dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
          wavFinished && history.push('/editor');
        };

        //@ts-ignore
        tbReader.readAsText(tbFile);

        const wavReader = new FileReader();
        wavReader.onload = (e: any) => {
          wavFinished = true;
          dispatch(setSessionName(fileName));
          dispatch(setAudioInfo({ url: e.target.result, loadNew: true }));
          dispatch(setValidRedirect(true));
          dispatch(setEditorMode(EditorModeEnums.TB_UPLOAD_MODE));
          dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
          tbFinished && history.push('/editor');
        };
        //@ts-ignore
        wavReader.readAsDataURL(wavFile);
      } else {
        dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      }
    }
  };

  const handleClickSettings = () => {
    setShowSettings(true);
  };

  const handleCloseSettings = () => {
    setShowSettings(false);
  };

  const handleOnHistoryClick = () => {
    if (uploadFlowInProgress || liveFlowInProgress) return;
    if (!isAuthenticated()) {
      handleNotAuthFlow();
      return;
    }

    history.push('/dashboard');
  };

  const checkIfCanceledInput = () => {
    if (!fileUploadRef.current) throw new Error('focus should not trigger when fileUploadRef is null!');

    //it is always empty
    if (fileUploadRef.current.value === '') {
      dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      window.removeEventListener('focus', checkIfCanceledInput);
    }
  };

  let areAllSecondsConsumedOrNotLoaded = false
  if (activeQuota && activeQuota.chunk.secondsLimit && activeQuota.chunk.secondsConsumed >= activeQuota.chunk.secondsLimit) {
    areAllSecondsConsumedOrNotLoaded = true
  }

  const disabledLive = !config || isUpdating(sttStatus, config) || uploadFlowInProgress || liveFlowInProgress || isConnected || !isRealtime(sttStatus, config) || areAllSecondsConsumedOrNotLoaded || (user && !user.isEditorUser) || !user;
  const disabledUpload = !config || isUpdating(sttStatus, config) || uploadFlowInProgress || liveFlowInProgress || areAllSecondsConsumedOrNotLoaded || (user && !user.isEditorUser) || !user;

  const handleUploadLabelClick = async (event: any) => {
    event.preventDefault();
    if (uploadFlowInProgress || liveFlowInProgress) return;
    dispatch(setHomeFlowState({ uploadFlowInProgress: true }));

    
    if (config && config.stt.enableDiarization.isAllowed && config.stt.enableDiarization.value) {
      dispatch(setSpeakerSettingsModal({visible: true}));
      return;
    }
    //check here if diarization is enabled
    handleAfterSpeakerSettingsClick();
  };

  
  const isModelUpdating = config && isUpdating(sttStatus, config)
  const isUserNotAllowed =  user && !user.isEditorUser
  
  const handleCloseSpeakerSettingsModal = () => {
    dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
  }

  const handleAfterSpeakerSettingsClick = () => {
    if (!isAuthenticated) {
      handleNotAuthFlow();
      dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      return;
    }

    if (!fileUploadRef.current) {
      dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      return;
    }
    
    window.addEventListener('focus', checkIfCanceledInput);
    fileUploadRef.current.value = '';
    fileUploadRef.current.click();
  }

  const initialRef = useRef<boolean>(true);
  useEffect(() => {
    if (initialRef.current) {
      initialRef.current = false;
      return;
    }
    if (!speakerSettingsModal.visible && uploadFlowInProgress) handleAfterSpeakerSettingsClick();
  }, [speakerSettingsModal])

  return (
    <>
      {/*<div id="test_click" style={{backgroundColor: '#FF000061'}}>
        <input ref={fileUploadRef} onChange={handleFileChange} type="file" id="fileElem" accept=".wav" style={{display: "none"}} />
        <button onClick={handleUploadLabelClick} id="fileSelect" style={{width: '100%', height: "50px"}}></button>
  </div>*/}
      <div className="home">

        <SettingsDrawer handleCloseSettings={handleCloseSettings} show={showSettings} />
        {fontsLoaded && (
          <motion.div
            className="buttons_container"
            initial={{ opacity: 0.2, x: '-2%' }}
            animate={{ opacity: 1, x: '0%', transition: { delay: 0.3 } }}
          >
            <div className="live_settings_container">
              <div className="live_settings_buttons">
                <button
                  className={disabledLive ? 'disabled_live' : 'button_live'}
                  onClick={disabledLive ? () => {} : handleLiveClick}
                  style={{
                    cursor: disabledLive ? 'default' : 'pointer',
                    // opacity: disabledLive ? 0.8 : 1,
                    ...(disabledLive ? { backgroundColor: '#CCCCCC' } : {}),
                  }}
                >
                  <div className='button_live_animating_square'/>
                  {liveFlowInProgress ? <CircularProgress color="inherit" /> : <span>V ŽIVO</span>}
                </button>
                <button
                  onClick={disabledUpload ? () => {} : handleUploadLabelClick}
                  //htmlFor={authenticated ? "button-file" : undefined}
                  className={disabledUpload ? "upload_disabled" : "button_upload"}
                  id="fileSelect"
                >
                  <div className='upload_button_animating_square'/>
                  <div className="upload_tittle_container">
                    <span>NALOŽI</span>
                  </div>
                  <div id="progress" ref={progressRef} />

                  {/*<button id="fileSelect" style={{ display: 'none' }} />*/}
                </button>
                <input
                  ref={fileUploadRef}
                  onChange={handleFileChange}
                  type="file"
                  multiple
                  id="fileElem"
                  // accept=".wav" we should accept any extensions
                  style={{ display: 'none' }}
                />
              </div>
              <button
                className="button_history"
                onClick={handleOnHistoryClick}
                disabled={uploadFlowInProgress || liveFlowInProgress}
                style={{
                  cursor: uploadFlowInProgress || liveFlowInProgress ? 'default' : 'pointer',
                  opacity: uploadFlowInProgress || liveFlowInProgress ? 0.8 : 1,
                }}
              >
                ZGODOVINA
              </button>
            </div>
          </motion.div>
        )}
        {fontsLoaded && (
          <motion.div
            style={{
              position: 'absolute',
              right: '25px',
              bottom: '25px',
              left: 0,
              width: '100%',
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <FooterInfo />
          </motion.div>
        )}
        <button
          className="button_menu_settings"
          onClick={handleClickSettings}
          style={{ width: 48, height: 48, zIndex: isUpdating(sttStatus, config) ?  1001 : 30, display: showSettings ? 'none' : 'block' }}
        >
          <MenuIcon fontSize="large" />
        </button>

        {(isModelUpdating || isUserNotAllowed) && (
        <div className='not-editor-user-blur-container'>
          <div className='not-editor-user-blur-content-container'>
            <p className='not-editor-user-text'>
              {isUserNotAllowed ?
              "Za uporabo storitev nimate ustreznih dovoljenj. Preverite pri skrbniku sistema."
              :
              "Izbrani model je trenutno v prenovi. Prosimo počakajte ali izberite drug model."
              }
            </p>
          </div>
        </div>
      )}
      </div>

      <SpeakerSettingsModal handleCloseModal={handleCloseSpeakerSettingsModal} />

    </>
  );
};

export default Home;