import axios from './axios';
import {
  API,
  DICTIONARY,
  FREQUENCY_CLASSES,
  PRONUNCIATIONS,
  WORDS,
  CLIENT,
  LANGUAGES,
  DOMAINS,
  DMODELS,
} from './endpoints';
import { IPhonems, WordStatusEnum } from '../components/DictionaryModal/IDictionaryModal';
import { AxiosResponse } from 'axios';
import { config } from '../config';

const backendUrl: string = config.backendApiUrl;
const baseUrl = `${backendUrl}/${API}/${CLIENT}/${DICTIONARY}`;

export interface IPronunciation {
  text: string;
  saved?: boolean;
}

export interface IPronunciationWithData extends IPronunciation {
  id?: number;
}
export interface IAddToDictWord {
  text: string;
  frequencyClassId?: number;
  id?: number;
  status?: WordStatusEnum;
  pronunciations: IPronunciation[];
}

export interface ICorpusSentence {
  leftContext: string;
  mainPhrase: string;
  numOccurrences: number;
  rightContext: string;
}

export interface ICorpusInfoByText {
  alvailableContextSize: number;
  content: ICorpusSentence[];
  empty: boolean;
  first: boolean;
  last: boolean;
  numSentences: number;
  number: number;
  numberOfElements: number;
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: any;
    unpaged: boolean;
  };
  size: number;
  sort: any;
  totalElements: number;
  totalPages: number;
}

interface Model {
  language: string;
  domain: string;
  modelVersion: string;
}

export const getDmodelStatus = (modelData: Model) => {
  return axios.get(
    `${baseUrl}/${LANGUAGES}/${modelData.language}/${DOMAINS}/${modelData.domain}/${DMODELS}/${modelData.modelVersion}`
  );
};

export const getDmodelStats = (modelData: Model) => {
  return axios.get(
    `${baseUrl}/${LANGUAGES}/${modelData.language}/${DOMAINS}/${modelData.domain}/${DMODELS}/${modelData.modelVersion}/stats`
  );
};

export const getDmodelCorpusStats = (modelData: Model) => {
  return axios.get(
    `${backendUrl}/${API}/${CLIENT}/corpus/${LANGUAGES}/${modelData.language}/${DOMAINS}/${modelData.domain}/${DMODELS}/${modelData.modelVersion}/stats`
  );
};

// -editor-backend.true-bar.si/api/client/corpus/languages/sl-SI/domains/ON-NT/dmodels/20211201-2220/sentences?text=bla&limit=5
export const getCorpusInfoByText = ({
  text,
  modelData,
  caseSensitive = false,
  contextSize = 2,
  page = 0,
  size = 1,
  sort,
}: {
  text: string;
  modelData: Model;
  caseSensitive: boolean;
  contextSize: number;
  page: number;
  size: number;
  sort?: string[];
}): Promise<AxiosResponse<ICorpusInfoByText>> => {
  const encodedText = encodeURIComponent(text);
  return axios.get(
    `${backendUrl}/${API}/${CLIENT}/corpus/${LANGUAGES}/${modelData.language}/${DOMAINS}/${
      modelData.domain
    }/${DMODELS}/${
      modelData.modelVersion
    }/sentences?text=${encodedText}&case-sensitive=${caseSensitive}&context-size=${contextSize}&page=${page}&size=${size}&sort=${
      sort || 'string'
    }`
  );
};

export const getWordsAndPronaunsByText = (
  word: string,
  modelData: Model,
  searchBy: 'word' | 'pronunciation' = 'word'
): Promise<AxiosResponse<IAddToDictWord[]>> => {
  const encodedText = encodeURIComponent(word);
  return axios.get(
    `${baseUrl}/${LANGUAGES}/${modelData.language}/${DOMAINS}/${modelData.domain}/${DMODELS}/${modelData.modelVersion}/${WORDS}?search-by=${searchBy}&text=${encodedText}`
  );
};

export const getWordSubs = (isDirect: boolean, word: string, modelData?: Model) => {
  if (isDirect) {
    let wordToFetch = word.length <= 1 ? 'aparatus' : word;
    return axios.post(
      ` https://cors-anywhere.herokuapp.com/corsdemo/http://true-bar.si:6677/v1/selected_substitutes/?token=${wordToFetch}&model=med`
    );
  }
  if (!modelData) return;
  const encodedText = encodeURIComponent(word);
  return axios.get(
    `${baseUrl}/${LANGUAGES}/${modelData.language}/${DOMAINS}/MED/${DMODELS}/${modelData.modelVersion}/word_substitutes?text=${encodedText}`
  );
};

export const getSpelledWordSubs = (word: string, limit: number, modelData?: Model) => {
  if (!modelData) return;
  const encodedText = encodeURIComponent(word);
  return axios.get(
    `${baseUrl}/${LANGUAGES}/${modelData.language}/${DOMAINS}/MED/${DMODELS}/${modelData.modelVersion}/prefixed_words?prefix=${encodedText}&limit=${limit}`
  );
};

export const postWord = (data: { word: IAddToDictWord; modelData: Model }): Promise<AxiosResponse<any>> => {
  const { language, domain, modelVersion } = data.modelData;
  return axios.post(
    `${baseUrl}/${LANGUAGES}/${language}/${DOMAINS}/${domain}/${DMODELS}/${modelVersion}/${WORDS}`,
    data.word,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

// export const patchWord = (wordId: number, wordData: Word) => {
//     return axios.patch(`${baseUrl}/${WORDS}/${wordId}`, wordData);
// };

export const getWordPronunciations = (wordId: number) => {
  return axios.get(`${baseUrl}/${WORDS}/${wordId}/${PRONUNCIATIONS}`);
};

export const postWordPronunciation = (wordId: number, pronunciation: IPronunciationWithData) => {
  return axios.post(`${baseUrl}/${WORDS}/${wordId}/${PRONUNCIATIONS}`, {
    text: pronunciation.text,
  });
};

export const putWordPronunciation = (pronunciation: IPronunciationWithData) => {
  return axios.patch(`${baseUrl}/${PRONUNCIATIONS}/${pronunciation.id}`, {
    text: pronunciation.text,
  });
};

export const deleteWordPronunciation = (id: number) => {
  return axios.delete(`${baseUrl}/${PRONUNCIATIONS}/${id}`);
};

// export const getPronunciationTypes = () => {
//     return axios.get(`${baseUrl}/${dModelId}/${PRONUNCIATIONS}/${TYPES}`);
// };

export const getFrequencyClasses = () => {
  return axios.get(`${baseUrl}/${FREQUENCY_CLASSES}`);
};

export const getDictionaryWordsCount = (data: Model) => {
  const { language, domain, modelVersion } = data;
  return axios.get(
    `${baseUrl}/${LANGUAGES}/${language}/${DOMAINS}/${domain}/${DMODELS}/${modelVersion}/words/count`
  );
};

export const startModelRegeneration = (data: Model) => {
  const { language, domain, modelVersion } = data;
  return axios.post(
    `${baseUrl}/${LANGUAGES}/${language}/${DOMAINS}/${domain}/${DMODELS}/${modelVersion}/regeneration/regenerate`
  );
};

export const getModelRegenerationStatus = (data: Model) => {
  const { language, domain, modelVersion } = data;
  return axios.get(
    `${baseUrl}/${LANGUAGES}/${language}/${DOMAINS}/${domain}/${DMODELS}/${modelVersion}/regeneration/status`
  );
};

export const getPhonems = (data: Model): Promise<AxiosResponse<IPhonems>> => {
  const { language, domain, modelVersion } = data;
  return axios.get(
    `${baseUrl}/${LANGUAGES}/${language}/${DOMAINS}/${domain}/${DMODELS}/${modelVersion}/phone-set`
  );
};
