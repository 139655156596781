import { LinearProgress } from '@material-ui/core';
import { motion } from 'framer-motion';
import React, { CSSProperties, useState } from 'react';
import useEditor from '../../hooks/useEditor';
import { IFixSpellCommandMode, ICommandModeSubs, ICommandSub } from '../Editor/IEditor';
import { CloseBox } from '../Icons/CloseBox';
import { DoubleArrow } from '../Icons/DoubleArrow';

interface Props {
  // updateSelectedWordWithSub: (sub: string) => void;
  commandModeSubs: ICommandModeSubs;
  fixSpellCommandMode: IFixSpellCommandMode;
  cancelFixCommandMode: () => void;
  footerWidth: number;
  // handleSpellClickCallback: () => void;
  moveLeftOrRight: 'L' | 'R' | null;
  currentSelectedSub?: ICommandSub;
}

const nextPrevBtnsStyle: CSSProperties = {
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  cursor: 'pointer',
};

export const AudioCommandsFooter: React.FC<Props> = ({
  // updateSelectedWordWithSub,
  commandModeSubs,
  moveLeftOrRight,
  footerWidth,
  // handleSpellClickCallback,
  fixSpellCommandMode,
  cancelFixCommandMode,
}) => {
  const { subsState, subs, selectedSubText } = commandModeSubs;
  const [numOfSubsToShow, setNumOfSubsToShow] = useState(-1);
  const [subsRange, setSubsRange] = useState([0, numOfSubsToShow]);
  const [pages, setPages] = useState<number>(1);
  const [currPage, setCurrPage] = useState<number>(0);
  const { insCommandMode } = useEditor();

  React.useEffect(() => {
    if (footerWidth <= 350) {
      setNumOfSubsToShow(1);
    } else if (footerWidth <= 580) {
      setNumOfSubsToShow(2);
    } else if (footerWidth <= 800) {
      setNumOfSubsToShow(3);
    } else if (footerWidth <= 1000) {
      setNumOfSubsToShow(4);
    } else {
      setNumOfSubsToShow(5);
    }
  }, [footerWidth]);

  React.useEffect(() => {
    setCurrPage(0);
  }, [
    fixSpellCommandMode.isSpellingOn,
    fixSpellCommandMode.currentSpelledWord,
    footerWidth,
    insCommandMode.isOn,
  ]);

  React.useEffect(() => {
    if (!numOfSubsToShow) return;
    if (!subs || subs.length === 0) return;
    if (numOfSubsToShow === -1) return;
    setPages(subs.length / numOfSubsToShow);
  }, [numOfSubsToShow, subs]);

  React.useEffect(() => {
    if (!subs) return;
    if (numOfSubsToShow === -1) return;
    if (currPage === 0) {
      setSubsRange([0, numOfSubsToShow]);
    } else {
      setSubsRange([
        currPage * numOfSubsToShow,
        numOfSubsToShow + currPage * numOfSubsToShow < subs?.length
          ? numOfSubsToShow + currPage * numOfSubsToShow
          : subs?.length,
      ]);
    }
  }, [currPage, numOfSubsToShow, subs]);

  React.useEffect(() => {
    if (!moveLeftOrRight || pages === 1) return;

    if (moveLeftOrRight === 'L' && currPage > 0) {
      setCurrPage(currPage - 1);
    } else if (moveLeftOrRight === 'R' && currPage < pages - 1) {
      setCurrPage(currPage + 1);
    }
  }, [moveLeftOrRight]);

  const handleShowMoreOrLessClick = (isLess?: boolean) => {
    if (isLess) {
      setCurrPage(currPage - 1);
    } else {
      setCurrPage(currPage + 1);
    }
  };

  return (
    <motion.div
      className="footer__audio_commands_wrapper_outer"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {pages > 1 && (
        <>
          {currPage > 0 && (
            <button
              style={{
                ...nextPrevBtnsStyle,
                left: footerWidth <= 660 ? '5px' : '25px',
                transform: 'translateY(-50%) rotate(180deg)',
              }}
              onClick={() => handleShowMoreOrLessClick(true)}
            >
              <DoubleArrow fill="#ffffff" />
            </button>
          )}
          {currPage < pages - 1 && (
            <button
              style={{ ...nextPrevBtnsStyle, right: footerWidth <= 660 ? '5px' : '25px' }}
              onClick={() => handleShowMoreOrLessClick()}
            >
              <DoubleArrow fill="#ffffff" />
            </button>
          )}
        </>
      )}
      {subsState === 'loading' && !fixSpellCommandMode.isSpellingOn ? (
        <LinearProgress
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            backgroundColor: '#ffffff',
            color: 'white',
          }}
          color="secondary"
        />
      ) : (
        <>
          <div
            className="footer__audio_commands_wrapper"
            style={{
              justifyContent:
                !subs || subs.length < 3 || (numOfSubsToShow && numOfSubsToShow < 3)
                  ? 'flex-start'
                  : 'flex-start',
            }}
          >
            {((subs && subs.length === 0) || subsState === 'error') &&
              !fixSpellCommandMode.isSpellingOn &&
              !insCommandMode.isOn && (
                <p>Za izbrano besedo ni na voljo nobene substitucije. Poskusite črkovati</p>
              )}
            {((subs && subs.length === 0) || subsState === 'error') && insCommandMode.isOn && (
              <p>Trenutno ni na voljo nobenega vnosa.</p>
            )}
            {subs &&
              subs.length > 0 &&
              numOfSubsToShow > -1 &&
              subsRange &&
              subs.slice(subsRange[0], subsRange[1]).map((s) => (
                <SubstitutionButton
                  isSelected={selectedSubText && selectedSubText === s.subText}
                  key={s.id}
                  sub={s}
                  // onClick={() => {
                  //   updateSelectedWordWithSub(s.subText);
                  // }}
                />
              ))}
            {/* <AnimatePresence>
              {isSpellingOn && (
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    // overflow: 'hidden',
                    justifyContent: 'center',
                    alignItems: 'center',

                    maxWidth: '80%',
                  }}
                >
                  {currentSpelledWord &&
                    currentSpelledWord.length > 0 &&
                    currentSpelledWord.map((letter, i) => (
                      <motion.span
                        key={`spelled-letter${i}`}
                        initial={{
                          opacity: 0,
                        }}
                        exit={{
                          opacity: 0,
                        }}
                        animate={{
                          opacity: 1,
                        }}
                        className="spelled_letter"
                      >
                        {letter}
                      </motion.span>
                    ))}
                </div>
              )}
            </AnimatePresence> */}
          </div>
          {/* <div className="footer__commands_options_wrapper">
            <button
              className="footer__audio_commands__sub_wrapper spell_button"
              onClick={() => {
                updateSelectedWordWithSub('');
              }}
            >
              <div>POTRDI</div>
            </button>
            <button
              className="footer__audio_commands__sub_wrapper spell_button"
              onClick={cancelFixCommandMode}
            >
              <div>RAZVELJAVI</div>
            </button>
            <AnimatePresence>
              {!isSpellingOn && (
                <motion.button
                  className="footer__audio_commands__sub_wrapper spell_button"
                  onClick={handleSpellClickCallback}
                  initial={{
                    opacity: 0,
                  }}
                  exit={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                  }}
                >
                  <div>ČRKUJEM</div>
                </motion.button>
              )}
            </AnimatePresence>
          </div> */}
        </>
      )}
      <button
        onClick={cancelFixCommandMode}
        style={{ position: 'absolute', top: 0, left: '50%', transform: 'translateX(-50%)', padding: '5px' }}
      >
        <CloseBox />
      </button>
    </motion.div>
  );
};

const SubstitutionButton = ({ sub, isSelected }) => {
  const { keys, subText, id } = sub;
  const mainColor = isSelected ? '#ffffff' : '#CCCCCC';
  return (
    <div className="footer__audio_commands__sub_wrapper">
      <div style={{ backgroundColor: mainColor }}>{id}</div>
      <span style={{ color: mainColor }}>{subText}</span>
    </div>
  );
};
