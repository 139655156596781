import { cloneDeep, words } from 'lodash';
import { capitalize, generateNewLiveInterimString } from '../../../shared/DataConverters';
import { allSubsStringsConverted } from '../constants';
import { Commands, IFinalBlock, ICommandSub, ITranscriptWord, IWordData, IWordDataLive } from '../IEditor';

export function isNumericRegex(value: string) {
  return /^-?\d+$/.test(value);
}

export const checkIfWordIsSelected = (
  selectedWordId: number[] | null,
  pI: number,
  wI: number,
  numOfWordToSelect: number,
  isSelectCommand?: boolean
) => {
  if (!selectedWordId) return false;

  if (selectedWordId && selectedWordId[0] === pI && selectedWordId[1] === wI) return true;

  if (
    selectedWordId[0] === pI &&
    wI >= selectedWordId[1] - numOfWordToSelect + 1 &&
    wI <= selectedWordId[1]
  ) {
    return true;
  }

  return false;
};

export const getIndexesOfFirstOccuranceInLiveWordData = (
  currentLiveWordDataBlocks: IWordData[][],
  mode: 'find' | 'fix',
  text?: string
) => {
  let counterW = 1;
  let counterP = 1;
  let found = false;
  while (!found) {
    const finalBI = currentLiveWordDataBlocks.length - counterP;
    const finalWI = currentLiveWordDataBlocks[finalBI].length - counterW;
    if (!currentLiveWordDataBlocks[finalBI] && !!currentLiveWordDataBlocks[finalBI][finalWI]) {
      return null;
    }

    if (currentLiveWordDataBlocks[finalBI] && !currentLiveWordDataBlocks[finalBI][finalWI]) {
      counterP += 1;
      counterW = 1;
    } else {
      const currText = currentLiveWordDataBlocks[finalBI][finalWI].text;
      const currUpdatedText = currentLiveWordDataBlocks[finalBI][finalWI].updatedText;

      if (mode === 'find') {
        if ((currUpdatedText && currUpdatedText === text) || currText === text) {
          return {
            counterP,
            counterW,
          };
        }
      } else if (mode === 'fix') {
        if (!allSubsStringsConverted.includes(currUpdatedText ? currUpdatedText : currText)) {
          found = true;
          return { counterP, counterW };
        }
      }

      counterW += 1;
    }
  }

  return { counterP, counterW };
};

export const checkIfSubOrCommandForFixModeIsInReceivedTr = (
  parsedContent: ITranscriptWord[],
  availableSubs: ICommandSub[] | null,
  isSpellingOn: boolean,
  currentSpelledWordArr: string[],
  upperCaseAllNext: boolean,
  lowerCaseAllNext: boolean,
  capNext: boolean,
  lowerCaseNextWord: boolean
) => {
  let spellingArr: string[] = [...currentSpelledWordArr];
  let currentData: IWordData | null = null;

  let uc = upperCaseAllNext;
  let lc = lowerCaseAllNext;
  let cc = capNext;
  let LC = lowerCaseNextWord;

  for (let i = 0; i < parsedContent.length; i++) {
    const currWord = parsedContent[i].text;
    currentData = parsedContent[i];
    if (currWord === Commands.CONFIRM) {
      return true;
    } else if (currWord === Commands.CANCEL) {
      return false;
    } else if (currWord === Commands.SPELL && !isSpellingOn) {
      return Commands.SPELL;
    } else if (currWord === Commands.SELECT) {
      if (!availableSubs || !parsedContent[i + 1]) return null;

      if (!isNumericRegex(parsedContent[i + 1].text)) return null;
      let found = false;
      let j = 0;
      while (!found && j < availableSubs.length) {
        const isCorrect = availableSubs[j].keys.includes(parsedContent[i + 1].text);
        if (isCorrect) {
          found = true;
          return availableSubs[j].subText;
        }
        j++;
      }
    } else if (currWord === Commands.SHOW_MORE || currWord === Commands.SHOW_LESS) {
      return currWord;
    }

    if (isSpellingOn) {
      if (currWord === Commands.DELETE_WORD && spellingArr.length > 0) {
        //dell prev letter
        spellingArr.splice(-1, 1);
      } else if (currWord === Commands.UPPER_CASE) {
        // upper case all next
        uc = true;
        lc = false;
      } else if (currWord === Commands.END_UPPER_CASE) {
        // end upper case
        uc = false;
      } else if (currWord === Commands.LOWER_CASE) {
        // lower case all next
        lc = true;
        uc = false;
        cc = false;
      } else if (currWord === Commands.END_LOWER_CASE) {
        // end lowercase
        lc = false;
      } else if (currWord === Commands.CAPITALIZE_NEXT) {
        // upper case only next letter
        cc = true;
      } else if (currWord === Commands.UPPER_CASE_NEXT_WORD) {
        // upper case only next letter

        cc = true;
        LC = false;
      } else if (currWord === Commands.LOWER_CASE_NEXT_WORD) {
        // cc = false;
        LC = true;
        cc = false;
      } else {
        let letter = currWord.charAt(0);
        if (letter !== '<') {
          if ((lc && !cc) || LC) {
            letter = letter.toLowerCase();
          } else {
            letter = letter.toUpperCase();
          }

          if (cc) {
            cc = false;
          }

          if (LC) {
            LC = false;
          }

          spellingArr = [...spellingArr, letter];
        }
      }
    }
  }

  if (isSpellingOn) {
    return { spellingArr, uc, lc, cc, LC, currentData: currentData };
  }

  return null;
};

export const checkIfSubOrCommandForInsModeIsInReceivedTr = (
  parsedContent: ITranscriptWord[],
  availableSubs: ICommandSub[] | null,
  callbacks: {
    onSelect?: (sub: ICommandSub) => void;
    onCancel?: () => void;
    onConfirm?: () => void;
    onShowMoreLess?: (show: Commands.SHOW_LESS | Commands.SHOW_MORE) => void;
  }
) => {
  const { onSelect, onCancel, onConfirm, onShowMoreLess } = callbacks;

  try {
    for (let i = 0; i < parsedContent.length; i++) {
      const currWord = parsedContent[i].text;

      if (currWord === Commands.CONFIRM) {
        onConfirm && onConfirm();
        return true;
      } else if (currWord === Commands.CANCEL) {
        onCancel && onCancel();
        return false;
      } else if (currWord === Commands.SELECT) {
        if (!availableSubs || !parsedContent[i + 1]) return null;
        if (!isNumericRegex(parsedContent[i + 1].text)) return null;
        let found = false;
        let j = 0;
        while (!found && j < availableSubs.length) {
          const isCorrect = availableSubs[j]?.keys.includes(parsedContent[i + 1].text);
          if (isCorrect) {
            found = true;
            onSelect && onSelect(availableSubs[j]);
            return availableSubs[j];
          }
          j++;
        }
      } else if (currWord === Commands.SHOW_MORE || currWord === Commands.SHOW_LESS) {
        onShowMoreLess && onShowMoreLess(currWord);
        return currWord;
      }
    }

    return null;
  } catch (err) {
    return null;
  }
};

export const findSearchedOrNextWordInLiveWordData = ({
  blocks,
  searchedText,
  startIndexes = null,
  directionToSearch = 'left',
  findAll = false,
  jumpNumber,
  caseSensitive,
}: {
  blocks: IFinalBlock[];
  searchedText: string;
  startIndexes: number[] | null;
  directionToSearch: 'left' | 'right';
  findAll: boolean;
  caseSensitive?: boolean;
  jumpNumber?: number;
}) => {
  if (!blocks || blocks.length === 0 || (blocks && blocks.length === 1 && blocks[0].words.length === 0))
    return null;
  if (!startIndexes && !findAll) {
    const lastBlockI = blocks.length - 1;
    // const lastWordI = words[lastBlockI].length - 1;
    for (let i = lastBlockI; i >= 0; i--) {
      const lastBlockWordI = blocks[i].words.length - 1;
      for (let j = lastBlockWordI; j >= 0; j--) {
        if (!blocks[i].words[j]) return null;
        const correctText = blocks[i].words[j].updatedText
          ? blocks[i].words[j].updatedText
          : blocks[i].words[j].text;
        if (correctText?.toLowerCase() === searchedText.toLowerCase()) {
          return { wordData: { ...blocks[i].words[j] }, wordIndexes: [i, j] };
        }
      }
    }
  }

  if (startIndexes) {
    const selectedBlockI = startIndexes[0];
    const selectedWordI = startIndexes[1];

    if (directionToSearch === 'left') {
      let jumpNumberDifference = 1;
      for (let i = selectedBlockI; i >= 0; i--) {
        for (
          let j =
            i === selectedBlockI
              ? selectedWordI - (jumpNumber && jumpNumber > 1 ? 0 : 1)
              : blocks[i].words.length - 1;
          j >= 0;
          j--
        ) {
          if (!jumpNumber && !blocks[i].words[j]) return null;
          const correctText = blocks[i].words[j].updatedText
            ? blocks[i].words[j].updatedText
            : blocks[i].words[j].text;
          if (searchedText === '' && typeof jumpNumber === 'number' && blocks[i].words[j]) {
            if (j - (jumpNumberDifference < 0 ? -jumpNumberDifference : jumpNumber) < 0) {
              jumpNumberDifference = j - (jumpNumberDifference < 0 ? -jumpNumberDifference : jumpNumber);
              break;
            }
            const minusLeft = jumpNumberDifference < 0 ? -1 * jumpNumberDifference - 1 : jumpNumber;
            if (blocks[i].words[j - minusLeft]) {
              return {
                wordData: { ...blocks[i].words[j - minusLeft] },
                wordIndexes: [i, j - minusLeft],
              };
            }
          } else if (searchedText === '' && blocks[i].words[j]) {
            return { wordData: { ...blocks[i].words[j] }, wordIndexes: [i, j] };
          }
          if (correctText?.toLowerCase() === searchedText.toLowerCase()) {
            return { wordData: { ...blocks[i].words[j] }, wordIndexes: [i, j] };
          }
        }
      }
    } else {
      let jumpNumberDifference = -1;
      for (let i = selectedBlockI; i < blocks.length; i++) {
        for (
          let j = i === selectedBlockI ? selectedWordI + (jumpNumber && jumpNumber > 1 ? 0 : 1) : 0;
          j < blocks[i].words.length;
          j++
        ) {
          if (!jumpNumber && !blocks[i].words[j]) return null;
          const correctText = blocks[i].words[j].updatedText
            ? blocks[i].words[j].updatedText
            : blocks[i].words[j].text;
          if (searchedText === '' && typeof jumpNumber === 'number' && blocks[i].words[j]) {
            if (
              j + (jumpNumberDifference > 0 ? jumpNumberDifference - 1 : jumpNumber) >
              blocks[i].words.length - 1
            ) {
              jumpNumberDifference =
                (jumpNumberDifference !== -1 ? jumpNumberDifference : jumpNumber) -
                (blocks[i].words.length - 1 - j);

              break;
            }
            const diff = jumpNumberDifference !== -1 ? jumpNumberDifference : jumpNumber;
            const minusLeft = jumpNumberDifference !== -1 ? 1 : 0;

            if (blocks[i].words[j + diff - minusLeft]) {
              return {
                wordData: { ...blocks[i][j + diff - minusLeft] },
                wordIndexes: [i, j + diff - minusLeft],
              };
            }
          } else if (searchedText === '' && blocks[i].words[j]) {
            return { wordData: { ...blocks[i].words[j] }, wordIndexes: [i, j] };
          } else if (correctText?.toLowerCase() === searchedText.toLowerCase()) {
            return { wordData: { ...blocks[i].words[j] }, wordIndexes: [i, j] };
          }
        }
      }
    }
  }

  return null;
};

export const checkIfCommandForFindModeIsInReceivedTr = (
  parsedContent: ITranscriptWord[],
  flags: { isInFindMode: boolean; isInSelectMode: boolean }
) => {
  if (!parsedContent || parsedContent.length === 0) return null;
  const { isInFindMode, isInSelectMode } = flags;
  let newFindCommandFound = isInFindMode ? true : false;
  let selectCommandFound = isInSelectMode ? true : false;
  let newTextToFind = '';

  for (let i = 0; i < parsedContent.length; i++) {
    const currWordText = parsedContent[i].text;
    if (newFindCommandFound) {
      if (!currWordText.startsWith('<') && !currWordText.endsWith('>')) {
        newTextToFind = currWordText;
      }
    } else if (currWordText === Commands.FIND_WORD) {
      newFindCommandFound = true;
      // check if n + 1 !== command / if not wait for first word in next tr.
    } else if (
      currWordText === Commands.NEXT ||
      currWordText === Commands.PREV ||
      currWordText === Commands.CANCEL ||
      currWordText === Commands.INSERT_AFTER ||
      currWordText === Commands.INSERT_BEFORE ||
      currWordText === Commands.REPLACE ||
      currWordText === Commands.CONFIRM ||
      currWordText === Commands.FIX ||
      currWordText === Commands.SPELL
    ) {
      return currWordText;
    } else if (currWordText === Commands.SELECT) {
      const nextWord = parsedContent[i + 1];
      if (nextWord && isNumericRegex(nextWord.text)) {
        // found n
        return {
          command: Commands.SELECT,
          numOfWords: parseInt(nextWord.text),
        };
      }
      // select only 1 ???
    } else if (
      currWordText === Commands.LEFT ||
      currWordText === Commands.RIGHT ||
      currWordText === Commands.DELETE_WORD
    ) {
      const nextWord = parsedContent[i + 1];
      if (nextWord && isNumericRegex(nextWord.text)) {
        // found n
        return {
          command: currWordText,
          numOfWords: parseInt(nextWord.text),
        };
      } else {
        // select only 1 ???
        return {
          command: currWordText,
          numOfWords: 1,
        };
      }
    }
  }

  if (newFindCommandFound && newTextToFind !== '') {
  } else if (newFindCommandFound) {
  }

  return null;
};

export const generateNewReplaceWords = (parsedContent: ITranscriptWord[]) => {
  const wordsToReturn: IWordData[] = [];
  parsedContent.forEach((w, i) => {
    if (!w.text.startsWith('<')) {
      // textToReturn += `${w.spaceBefore ? ' ' : ''}${w.text}`;
      wordsToReturn.push(w);
    }
  });

  return wordsToReturn;
};

export const generateNewLiveWordDataWithInsertion = (
  parsedContent: ITranscriptWord[],
  currentLiveWordData: IWordDataLive,
  isFinal: boolean,
  insertingBeforeOrAfter: 'before' | 'after',
  flags: {
    uca: boolean;
    lca: boolean;
    cn: boolean;
  },

  startIndexes?: number[] | null
) => {
  if (!parsedContent || parsedContent.length === 0 || !startIndexes) return null;
  const liveWordDataCopy = cloneDeep(currentLiveWordData);
  const wordDataToMergeIn: IWordData[] = [];
  let addedLength: number = 0;
  const { uca, lca, cn } = flags;
  let upperCaseAll = uca;
  let lowerCaseAll = lca;
  let capitalizeNext = cn;
  let deleteLastWord = false;

  for (let i = 0; i < parsedContent.length; i++) {
    const currWord = parsedContent[i];
    const currWordText = currWord.text;
    let addWord = true;

    if (currWordText.startsWith('<')) {
      if (isFinal) {
        if (currWordText === Commands.LOWER_CASE) {
          lowerCaseAll = true;
          upperCaseAll = false;
        } else if (currWordText === Commands.UPPER_CASE) {
          upperCaseAll = true;
          lowerCaseAll = false;
        } else if (currWordText === Commands.CAPITALIZE_NEXT) {
          capitalizeNext = true;
        } else if (currWordText === Commands.END_LOWER_CASE) {
          if (lowerCaseAll) {
            lowerCaseAll = false;
          }
        } else if (currWordText === Commands.END_UPPER_CASE) {
          if (upperCaseAll) {
            upperCaseAll = false;
          }
        } else if (currWordText === Commands.DELETE_WORD) {
          if (wordDataToMergeIn.length > 0) {
            wordDataToMergeIn.pop();
          } else {
            deleteLastWord = true;
            const selectedBlockI = startIndexes[0];
            const swi = startIndexes[1];
            liveWordDataCopy.finalsBlocks[selectedBlockI].words.splice(
              swi - (insertingBeforeOrAfter === 'before' ? 1 : 0),
              1
            );
            addedLength = -1;
            return {
              liveWordDataCopy,
              addedLength,
              flags: { lowerCaseAll, upperCaseAll, capitalizeNext },
            };
          }
        }
      }
    } else {
      if (addWord) {
        let wordToAdd = { ...currWord };
        if (capitalizeNext) {
          wordToAdd.text = capitalize(wordToAdd.text);
          if (isFinal) {
            capitalizeNext = false;
          }
        } else if (upperCaseAll) {
          wordToAdd.text = wordToAdd.text.toUpperCase();
        } else if (lowerCaseAll) {
          wordToAdd.text = wordToAdd.text.toLowerCase();
        }
        wordDataToMergeIn.push(wordToAdd);
      }
    }
  }

  const selectedBlockI = startIndexes[0];
  const swi = startIndexes[1];

  if (liveWordDataCopy.finalsBlocks[selectedBlockI] && isFinal) {
    liveWordDataCopy.finalsBlocks[selectedBlockI].words.splice(
      swi + (insertingBeforeOrAfter === 'after' ? 1 : 0),
      0,
      ...wordDataToMergeIn
    );
    liveWordDataCopy.lastInterim = '';
    addedLength = wordDataToMergeIn.length;
  } else {
    const newLiveInerimString = generateNewLiveInterimString(wordDataToMergeIn, false);
    liveWordDataCopy.lastInterim = newLiveInerimString;
    addedLength = 0;
  }

  return {
    liveWordDataCopy,
    addedLength,
    flags: { lowerCaseAll, upperCaseAll, capitalizeNext },
  };
};
