import React from "react";
import FasterIcon from "@material-ui/icons/AddCircleOutline";
import SlowerIcon from "@material-ui/icons/RemoveCircleOutline";

interface IProps {
  defaultValue?: number;
  minValue?: string;
  maxValue?: string;
  step?: string;
  handleValueChange: (playbackRate: number) => void;
  handleIncreaseClick: () => void;
  handleDecreaseClick: () => void;
}

const Slider = ({
  defaultValue,
  minValue,
  maxValue,
  step,
  handleDecreaseClick,
  handleIncreaseClick,
  handleValueChange,
}: IProps) => {
  function handleOnChange(event: any) {
    const num = parseFloat(event.target.value);
    handleValueChange(num);
  }

  return (
    <div className="playbackrate_slider_container">
      <SlowerIcon
        htmlColor="#CCCCCC"
        fontSize="small"
        onClick={handleDecreaseClick}
      />
      <input
        type="range"
        min={minValue ? minValue : "0.20"}
        max={maxValue ? maxValue : "3"}
        step={step ? step : "0.01"}
        value={defaultValue}
        className="playbackrate_slider"
        id="myRange"
        onChange={handleOnChange}
      />
      <FasterIcon
        htmlColor="#CCCCCC"
        fontSize="small"
        onClick={handleIncreaseClick}
      />
    </div>
  );
};

export default Slider;
