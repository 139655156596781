import { MutableRefObject, ReactNode } from 'react';
import { IReceivedInfo } from '../components/Libraries/ILibraries';

export enum ReadyState {
  UNINSTANTIATED = -1,
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3,
}

export interface QueryParams {
  [key: string]: string | number;
}

export interface Options {
  fromSocketIO?: boolean;
  queryParams?: QueryParams;
  protocols?: string | string[];
  share?: boolean;
  onOpen?: (event: WebSocketEventMap['open']) => void;
  onClose?: (event: WebSocketEventMap['close']) => void;
  onMessage?: (event: WebSocketEventMap['message']) => void;
  onError?: (event: WebSocketEventMap['error']) => void;
  onReconnectStop?: (numAttempts: number) => void;
  shouldReconnect?: (event: WebSocketEventMap['close']) => boolean;
  reconnectInterval?: number;
  reconnectAttempts?: number;
  filter?: (message: WebSocketEventMap['message']) => boolean;
  retryOnError?: boolean;
}

export type ReadyStateState = {
  [url: string]: ReadyState;
};

export type WebSocketMessage = string | ArrayBuffer | SharedArrayBuffer | Blob | ArrayBufferView;

export type SendMessage = (message: WebSocketMessage) => void;
export type SendJsonMessage = (jsonMessage: any) => void;

export type Subscriber<T = WebSocketEventMap['message']> = {
  setLastMessage: (message: T) => void;
  setReadyState: (readyState: ReadyState) => void;
  optionsRef: MutableRefObject<Options>;
  reconnectCount: MutableRefObject<number>;
  reconnect: MutableRefObject<() => void>;
};

export type WebSocketHook<T = WebSocketEventMap['message']> = {
  sendMessage: SendMessage;
  sendJsonMessage: SendJsonMessage;
  lastMessage: T | null;
  lastJsonMessage: any;
  readyState: ReadyState;
  getWebSocket: () => WebSocket | null;
};

export enum IWsAction {
  IDLE = 'IDLE',
  CONNECT = 'CONNECT',
  CLOSE = 'CLOSE',
  FORCE_CLOSE = 'FORCE_CLOSE',
}

export enum WsContextActions {
  SET_IS_CONNECTED = 'SET_IS_CONNTECTED',
  SET_LAST_RECEIVED_MSG = 'SET_LAST_RECEIVED_MSG',
  SET_LAST_SENT_MSG = 'SET_LAST_SENT_MSG',
}

export interface WsState {
  isConnected: boolean;
  lastMessage: WebSocketEventMap['message'] | null;
  lastJsonMessage: any;
  lastSessionInfo: IReceivedInfo | null;
  transcriptionFinishedClose: boolean;
  updateAudioToken: boolean;
  recordingsIdsInCurrentEditorSession: MutableRefObject<number[]>;
  waitingForInfoMessage: boolean;
  isLoadingWsSession: boolean;
}

export interface IWsOptions {
  sessionId?: number | string;
  audioChannels?: number;
  audioSampleRate?: number;
  token?: string;
}
export interface WsActionObj {
  action: IWsAction;
  options?: IWsOptions;
}

export interface WsContextValue extends WsState {
  sendMessage: (message: WebSocketMessage) => void;
  openWs: (options?: IWsOptions) => void;
  closeWs: (action?: IWsAction) => void;
  resetLastMessage: () => void;
  sendJsonMessage: (message: any) => void;
  resetUpdateAudioToken: () => void;
  setIsLoadingWsSession: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface WsProviderProps {
  children: ReactNode;
}

export type IsConnectedAction = {
  type: WsContextActions.SET_IS_CONNECTED;
  payload: {
    isConnected: boolean;
  };
};

export type LastMessageAction = {
  type: WsContextActions.SET_LAST_RECEIVED_MSG | WsContextActions.SET_LAST_SENT_MSG;
  payload: {
    lastMessage: string;
  };
};

export type Action = LastMessageAction | IsConnectedAction;
