import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import {
  initMicrophone,
  isContextInitialized,
  lockAudio,
  unlockAudio,
  releaseAudio,
} from '../components/Libraries/newMicrophone';
import { processAudioFile } from '../components/Libraries/processAudioFile';

import { IWsAction, IWsOptions } from '../providers/wsTypes';
import {
  setAnimButtonState,
  setConsumptionModal,
  setEditorMode,
  setHomeFlowState,
  setLoadContentToEditor,
  setUploadedFileDuration,
  setValidRedirect,
} from '../redux/actions/actions';
import { AnimButtonStatesEnum, ConsumtionModalTemplate, EditorModeEnums, IStore } from '../redux/store/IStore';
import useWs from './useWs';
import WarnMessageContent from '../components/CustomNotistack/WarnMessageContent';
import React from 'react';

interface StartRecordingLiveOptions {
  wsOptions?: IWsOptions;
  redirectToEditor?: boolean;
}

export default function useAudioSocket() {
  const {
    sendMessage,
    openWs,
    closeWs,
    isConnected,
    lastSessionInfo,
    isLoadingWsSession,
    setIsLoadingWsSession,
  } = useWs();
  const [micError, setMicError] = useState<boolean>(false);
  const [processingAudioFile, setProcessingAudioFile] = useState<boolean>(false);
  const user = useSelector((state: IStore) => state.user);
  const [redirectFromHomeWhenReady, setRedirectFromHomeWhenReady] = useState<boolean>(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const activeQuota = useSelector((store: IStore) => store.activeQuota);
  const [wsMode, setWsMode] = useState<{
    initiated: boolean;
    uploadedWavFile: any;
    redirectToEditor?: boolean;
  }>({
    uploadedWavFile: null,
    initiated: false,
    redirectToEditor: false,
  });
  const { enqueueSnackbar } = useSnackbar();
  const forceCloseTimeout = useRef<number | null>(null);
  // Flag to allow/deny sending of audio packets.
  // To be used after sending empty packet, to be absolutely sure
  // no further audio is sent.
  const canSendAudioPackets = useRef<boolean>(false);

  useEffect(() => clearForceCloseTimeout, []);

  useEffect(() => {
    if (location.pathname !== '/' || !lastSessionInfo?.isNew || !redirectFromHomeWhenReady) return;
    setRedirectFromHomeWhenReady(false);
    dispatch(setHomeFlowState({ liveFlowInProgress: false, uploadFlowInProgress: false }));
    dispatch(setValidRedirect(true));
    setIsLoadingWsSession(false);

    history.push('/editor');
  }, [lastSessionInfo, location.pathname, redirectFromHomeWhenReady]);

  // const resamplerRef = useRef<any>(null);

  const clearForceCloseTimeout = () => {
    if (forceCloseTimeout.current) {
      clearTimeout(forceCloseTimeout.current);
      forceCloseTimeout.current = null;
    }
  };

  const startRecordingLive = useCallback(
    async ({ wsOptions, redirectToEditor }: StartRecordingLiveOptions) => {

      const isQuotaBreached = activeQuota && activeQuota.chunk.secondsLimit && activeQuota.chunk.secondsLimit <= activeQuota.chunk.secondsConsumed
      
      if (!user || !user.isAuthenticated || !user.accessToken || isQuotaBreached) {
        if (isQuotaBreached) {
          dispatch(setConsumptionModal({
            visible: true,
            template: ConsumtionModalTemplate.LIMIT
          }));  
        }
        dispatch(setHomeFlowState({ liveFlowInProgress: false }));
        return;
      }

      const isWarningQuotaBreached = activeQuota && activeQuota.chunk.secondsWarn && activeQuota.chunk.secondsWarn <= activeQuota.chunk.secondsConsumed
      if (isWarningQuotaBreached) {
        enqueueSnackbar(<WarnMessageContent />, {variant: "warn"})
      }
      
      clearForceCloseTimeout();
      setIsLoadingWsSession(true);
      console.log('from startRecordingLive', user);
      dispatch(setAnimButtonState(AnimButtonStatesEnum.WAITING_TO_START));
      canSendAudioPackets.current = true;
      if (!isContextInitialized() || micError) {
        // console.log('from startRecordingLive - will init mic');
        try {
          await initMicrophone(16000, 4096, (audioChunk: ArrayBuffer) => {
            if (canSendAudioPackets.current) {
              sendMessage(audioChunk);
            }
          });
          // console.log('from startRecordingLive  - mic inited');
        } catch (e) {
          console.error(e);
          const err = e as any;
          setMicError(true);
          dispatch(setHomeFlowState({ liveFlowInProgress: false }));
          setIsLoadingWsSession(false);
          enqueueSnackbar(
            err.message || 'Za snemanje v živo je potrebno omogočiti dovoljenje do mikrofona.',
            { variant: 'error' }
          );
          return;
        }
      }
      // console.log('from startRecordingLive  - will call openWs()');
      //TODO: change to promise and make sure to not redirect to Editor if ws fails with error
      openWs(
        wsOptions
          ? {
              token: user.accessToken,
              audioChannels: 1,
              audioSampleRate: 16000, //getSampleRateFromCtx() ||
              sessionId: redirectToEditor
                ? undefined
                : lastSessionInfo && lastSessionInfo.sessionId
                ? lastSessionInfo.sessionId
                : undefined,
              ...wsOptions,
            }
          : {
              token: user.accessToken,
              audioChannels: 1,
              audioSampleRate: 16000, //getSampleRateFromCtx() ||
              sessionId: redirectToEditor
                ? undefined
                : lastSessionInfo && lastSessionInfo.sessionId
                ? lastSessionInfo.sessionId
                : undefined,
            }
      );

      setWsMode({
        initiated: true,
        uploadedWavFile: null,
        redirectToEditor,
      });
    },
    [
      setWsMode,
      openWs,
      sendMessage,
      dispatch,
      setMicError,
      micError,
      user,
      lastSessionInfo,
      setIsLoadingWsSession,
      enqueueSnackbar,
      activeQuota
    ]
  );
  const stopRecordingLive = useCallback(async () => {
    dispatch(setAnimButtonState(AnimButtonStatesEnum.WAITING_TO_STOP));
    try {
      await lockAudio();
      canSendAudioPackets.current = false;
      sendMessage(new ArrayBuffer(0));
      // Backend should close the web socket after empty packet, but sometimes it fails to do so.
      // Therefore, we force websocket disconnect after 5s.
      forceCloseTimeout.current = window.setTimeout(() => {
        if (forceCloseTimeout.current) {
          closeWs(IWsAction.FORCE_CLOSE);
          clearTimeout(forceCloseTimeout.current);
        }
      }, 5000);
    } catch (e) {
      const err = e as any;
      enqueueSnackbar(err.message || 'Prišlo je do napake pri zajemu zvoka. Prosimo poskusite ponovno', {
        variant: 'error',
      });
    }
  }, [dispatch, enqueueSnackbar, sendMessage, closeWs]);

  const startSendingChunks = useCallback(
    (loadedWavFile: any) => {
      if (!user || !user.accessToken) {
        dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
        return;
      }
      setIsLoadingWsSession(true);
      openWs({
        token: user.accessToken,
        audioChannels: 1,
        audioSampleRate: 16000,
      });
      setWsMode({
        initiated: true,
        uploadedWavFile: loadedWavFile,
      });
    },
    [openWs, setWsMode, user]
  );

  useEffect(() => {
    if (!isConnected) return;
    if (!wsMode.initiated) return;
    setWsMode({
      uploadedWavFile: null,
      initiated: false,
    });

    if (wsMode.uploadedWavFile) {
      setProcessingAudioFile(true);
      canSendAudioPackets.current = true;
      processAudioFile(
        wsMode.uploadedWavFile,
        1,
        16000,
        4096,
        (d: any) => {
          dispatch(setUploadedFileDuration(d));
        },
        (chunk: any, progress: any) => {
          if (canSendAudioPackets.current) {
            sendMessage(chunk);
          }
        }
      )
        .then(() => {
          setProcessingAudioFile(false);
          dispatch(
            setLoadContentToEditor({
              recFinishedStartLoadingNewEditorState: false,
              recStartedLoadTextFromEditor: true,
            })
          );
          dispatch(setEditorMode(EditorModeEnums.TRANSCRIBING_UPLOAD_MODE));
          setRedirectFromHomeWhenReady(true);
          setTimeout(() => {
            canSendAudioPackets.current = false;
            sendMessage(new ArrayBuffer(0));
          }, 200);
        })
        .catch((e) => {
          dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
          setProcessingAudioFile(false);
          setIsLoadingWsSession(false);
          enqueueSnackbar('Something went wrong with file upload. Please try again.', { variant: 'error' });
          console.log(e);
        });
    } else {
      const handleLive = async () => {
        try {
          await unlockAudio();

          dispatch(setAnimButtonState(AnimButtonStatesEnum.RECORDING));
          dispatch(setEditorMode(EditorModeEnums.RECORDING_MODE));
          dispatch(
            setLoadContentToEditor({
              recFinishedStartLoadingNewEditorState: false,
              recStartedLoadTextFromEditor: true,
            })
          );

          if (wsMode.redirectToEditor) {
            setRedirectFromHomeWhenReady(true);
          }
        } catch (e) {
          console.error(e);
          const err = e as any;
          setIsLoadingWsSession(false);
          dispatch(setHomeFlowState({ liveFlowInProgress: false }));
          enqueueSnackbar(err.message || 'Prišlo je do napake pri zajemu zvoka. Prosimo poskusite ponovno', {
            variant: 'error',
          });
          return;
        }
      };
      handleLive();
    }
  }, [isConnected, wsMode]);

  return {
    startRecordingLive,
    stopRecordingLive,
    startSendingChunks,
    processingAudioFile,
    isLoadingWsSession,
    releaseAudioContext: releaseAudio,
  };
}
