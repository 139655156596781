import React from "react";
import { SvgIcon } from "@material-ui/core/";

export function PauseIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        id="pause-24px"
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <path
          id="Path_45"
          data-name="Path 45"
          d="M6,33h8V5H6ZM22,5V33h8V5Z"
          transform="translate(6 5)"
        />
      </svg>
    </SvgIcon>
  );
}
