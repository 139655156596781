import { API, LOGS, CLIENT } from './endpoints';
import axios from './axios';
import { config } from '../config';

export const backendUrl: string = config.backendApiUrl;
export const logsUrl: string = `${backendUrl}/${API}/${CLIENT}/${LOGS}`;

export enum LogLevels {
  ERROR = 'ERROR',
  INFO = 'INFO',
  TRACE = 'TRACE',
}

export interface ILog {
  logLevel: LogLevels;
  logMsg: string;
}

export const postLogs = (error: any): Promise<any> => {
  const errorObj: ILog = {
    logLevel: LogLevels.ERROR,
    logMsg: error.message,
  };

  return axios
    .post(logsUrl, errorObj, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((res) => console.log(res));
};
