import React from 'react';
import { SvgIcon } from '@material-ui/core/';

export function NextIcon(props: any) {
    return (
        <SvgIcon {...props}>
            <svg id="skip_next-24px" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                <path id="Path_49" data-name="Path 49" d="M6,30,23,18,6,6ZM26,6V30h4V6Z" transform="translate(6 6)" fill="#ccc"/>
                <path id="Path_50" data-name="Path 50" d="M0,0H48V48H0Z" fill="rgba(0,0,0,0)"/>
            </svg>
        </SvgIcon>
    );
}