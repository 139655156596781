import React from 'react';
import { Colors } from './IEditor';

// - standardno: aBc ▏
// - male tiskane: aaa ▆
// - velike tiskane: AAA █
// - velika začetnica: ▍ali ▁

export enum CursorTypesEnum {
  NORMAL = 'NORMAL',
  LOWER_CASE_ALL = 'LOWER_CASE_ALL',
  UPPER_CASE_ALL = 'UPPER_CASE_ALL',
  UPPER_CASE_FIRST = 'UPPER_CASE_FIRST',
  SPELL = 'SPELL',
  FIND = 'FIND',
}

interface LastPostitionCursorProps {
  type: CursorTypesEnum;
  spaceLeft?: boolean;
  color?: string;
  isInInput?: boolean;
}
const LastPostitionCursor = ({
  type,
  spaceLeft = true,
  color = 'rgb(248, 5, 52)',
  isInInput = false,
}: LastPostitionCursorProps) => {
  return (
    <span
      id="cursor-position"
      className="last_pos_cursor draft_word"
      style={{
        color: type === CursorTypesEnum.FIND ? 'transparent' : color,
        ...(type === CursorTypesEnum.FIND && { borderBottom: '2px solid rgb(248, 5, 52)' }),
        ...(isInInput && { borderBottom: `solid 1px ${Colors.OUTLINE_ACTIVE_INPUT}` }),
      }}
    >
      {type === CursorTypesEnum.SPELL && `${spaceLeft ? ' _' : '_'}`}
      {type === CursorTypesEnum.FIND && `${spaceLeft ? '---' : '---'}`}
      {type === CursorTypesEnum.LOWER_CASE_ALL && `${spaceLeft ? ' ▆' : '▆'}`}
      {type === CursorTypesEnum.UPPER_CASE_ALL && `${spaceLeft ? ' █' : '█'}`}
      {type === CursorTypesEnum.UPPER_CASE_FIRST && `${spaceLeft ? ' ▍' : '▍'}`}
      {type === CursorTypesEnum.NORMAL && `${spaceLeft ? ' ▏' : '▏'}`}
    </span>
  );
};

export default LastPostitionCursor;
