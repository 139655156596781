import React from 'react';
import Corpus from './Corpus';
import DictionaryHeader from './DictionaryHeader';
import Leksicon from './Leksicon';
import '../../styles/css/dictionary.css';

export default function DictionaryMain() {
  return (
    <div className="dictionary_main_wrapper">
      <DictionaryHeader />
      <div className="dictionary_content_wrapper">
        <Leksicon />
        <Corpus />
      </div>
    </div>
  );
}
