import axiosInstance from './axios';
import axios, { AxiosResponse } from 'axios';

import {
  API,
  CLIENT,
  RECORDINGS,
  SESSIONS,
  TRANSCRIPTS,
  EDITOR_CONTENT,
  LABELS,
  COPY,
  SUMMARY,
  SHARES,
  USERS,
} from './endpoints';
import { ISortObject } from '../redux/store/IStore';
import { IShare, IUser } from '../components/Dashboard/IDashboard';
import { SessionSource, SessionState } from '../components/DashboardHeader/ISearch';
import { config } from '../config';
import store from '../redux/store';

const backendUrl: string = config.backendApiUrl;
const baseUrl = `${backendUrl}/${API}/${CLIENT}`;

export interface IFilterOptions {
  name?: string;
  label?: string;
  'created-after'?: string;
  'created-before'?: string;
  'created-by-username'?: string; // admin only
  'is-discarded'?: boolean;
  source?: SessionSource;
  status?: SessionState;
}

export interface ILabelUpdateDTO {
  code: string;
  color: string;
  isDefault: boolean;
}

export const createNewEditorContentVersion = (
  content: string,
  sessionId?: number
): Promise<AxiosResponse<any>> => {
  if (!sessionId) throw new Error('sessionId is missing');

  const url = baseUrl + `/${SESSIONS}/${sessionId}/${EDITOR_CONTENT}`;
  return axiosInstance.post(
    url,
    { content },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const saveEditorContent = (content: string, editorContentId: number) => {
  const url = baseUrl + `/${EDITOR_CONTENT}/${editorContentId}`;
  return axiosInstance.patch(
    url,
    { content },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const deleteEditorContent = (editorContentId: number) => {
  const url = baseUrl + `/${EDITOR_CONTENT}/${editorContentId}`;
  return axiosInstance.delete(url);
};

export const getSessionTranscripts = (sessionId?: number) => {
  if (!sessionId) {
    throw new Error('sessionId is missing');
  }
  const baseUrl = `${backendUrl}/${API}/${CLIENT}/${SESSIONS}/${sessionId}/${TRANSCRIPTS}`;
  return axiosInstance.get(baseUrl);
};

export const getEditorContent = (sessionId: number) => {
  if (!sessionId) {
    throw new Error('sessionId is missing');
  }
  const textUrl = `${backendUrl}/${API}/${CLIENT}/${SESSIONS}/${sessionId}/${EDITOR_CONTENT}`;
  return axiosInstance.get(textUrl);
};

export const getSessions = (
  page: number,
  size: number,
  filters: URLSearchParams,
  sortObject: ISortObject
) => {
  const sort = sortObject.columnName ? `${sortObject.columnName},${sortObject.type}` : null;
  if (sort) filters.append('sort', sort.toString());
  filters.append('page', page.toString());
  filters.append('size', size.toString());
  return axiosInstance.get(`${baseUrl}/${SESSIONS}`, {
    params: filters,
  });
};

export const copySession = (sessionId: number) => {
  return axiosInstance.post(`${baseUrl}/${SESSIONS}/${sessionId}/${COPY}`);
};

/** START: Sharing with other users: */

export const removeShare = (shareId: number) => {
  return axiosInstance.delete(`${baseUrl}/${SESSIONS}/${SHARES}/${shareId}`);
};

//TODO: Filter by parameter on backend. Check swagger. Always prefix!
export const getAllUsers = () => {
  return axiosInstance.get<IUser[]>(`${baseUrl}/${USERS}`);
};

export const getSessionSharedUsers = (sessionId: number) => {
  return axiosInstance.get<IShare[]>(`${baseUrl}/${SESSIONS}/${sessionId}/${SHARES}`);
};

// Adds a single user to shared user pool of the specified session
const addSessionSharedUser = (sessionId: number, userId: number) => {
  return axiosInstance.post(`${baseUrl}/${SESSIONS}/${sessionId}/${SHARES}`, {
    userId,
  });
};

export const addMultipleSharedUsers = (sessionId: number, userIds: number[]) => {
  return axios.all(userIds.map((id) => addSessionSharedUser(sessionId, id)));
};

/** END: Sharing with other users: */

export const getClientLabels = () => {
  return axiosInstance.get(`${baseUrl}/${LABELS}`);
};

export const deleteClientLabel = (labelId: number) => {
  return axiosInstance.delete(`${baseUrl}/${LABELS}/${labelId}`);
};

export const patchClientLabel = (labelId: number, labelUpdateDTO: Partial<ILabelUpdateDTO>) => {
  return axiosInstance.patch(`${baseUrl}/${LABELS}/${labelId}`, { ...labelUpdateDTO });
};

export const registerNewClientLabel = (code: string, color: string, isDefault: boolean = false) => {
  return axiosInstance.post(`${baseUrl}/${LABELS}`, { code, color, isDefault });
};

export const addNewLabelToSession = (sessionId: number, labelId: number, isEnabled: boolean) => {
  return axiosInstance.post(`${baseUrl}/${SESSIONS}/${sessionId}/${LABELS}`, { labelId, isEnabled });
};

export const updateSessionLabel = (sessionId: number, labelId: number, isEnabled: boolean) => {
  return axiosInstance.patch(`${baseUrl}/${SESSIONS}/${sessionId}/${LABELS}/${labelId}`, {
    labelId,
    isEnabled,
  });
};

export const removeLabelFromSession = (sessionId: number, labelCode: number) => {
  return axiosInstance.delete(`${baseUrl}/${SESSIONS}/${sessionId}/${LABELS}/${labelCode}`);
};

export const getSession = (sessionId: number) => {
  return axiosInstance.get(`${baseUrl}/${SESSIONS}/${sessionId}`);
};

export const deleteSession = (sessionId: number, isHardDelete?: boolean) => {
  return axiosInstance.delete(isHardDelete ? `${baseUrl}/${SESSIONS}/${sessionId}?hard=${true}` : `${baseUrl}/${SESSIONS}/${sessionId}`);
};

export const getSessionLabels = (sessionId: number) => {
  return axiosInstance.get(`${baseUrl}/${SESSIONS}/${sessionId}/${LABELS}`);
};

export const getSessionSummary = (sessionId: number) => {
  return axiosInstance.get(`${baseUrl}/${SESSIONS}/${sessionId}/${SUMMARY}`);
};

export const getSessionDetails = (id: number) => {
  return axios.all([getSession(id), getSessionSummary(id)]);
};

export const patchSessionInfo = (info: { name?: string; notes?: string }, sessionId?: number) => {
  if (!sessionId) {
    throw new Error('sessionId is missing');
  }

  if (Object.keys(info).length === 0) throw new Error('Provide at least 1 property to patch');

  const url = baseUrl + `/${SESSIONS}/${sessionId}`;
  return axiosInstance.patch(url, info, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getSessionAudio = (id: number) => {
  return axiosInstance.get(`${baseUrl}/${SESSIONS}/${id}/audio.wav`);
};

export const getSessionRecordings = (id: number) => {
  return axiosInstance.get(`${baseUrl}/${SESSIONS}/${id}/${RECORDINGS}`);
};

export const deleteRecording = (recordingId: number) => {
  return axiosInstance.delete(`${baseUrl}/${RECORDINGS}/${recordingId}`);
};

export const getSessionRecordingsTest = (id: number) => {
  return axiosInstance.get(`${baseUrl}/${RECORDINGS}/${id}`);
};

export const getLabelSuggestions = (partialLabel: string) => {
  return axiosInstance.get(`${baseUrl}/${LABELS}?code=${partialLabel}%25`);
};

export const getAllUsersSuggestions = (username: string, limit: number) => {
  return axiosInstance.get<IUser[]>(`${baseUrl}/${USERS}?name=${username}&limit=${limit}`);
};

export const getSessionsNames = (filter: string, limit: number) => {
  return axiosInstance.get<string[]>(`${baseUrl}/${SESSIONS}/names?filter=${filter}&limit=${limit}`);
};

export const getAllDataAboutSessionAdmin = (id: number) => {
  return axios.all([
    getSession(id),
    getSessionSummary(id),
    getSessionRecordings(id),
    getSessionTranscripts(id),
  ]);
};

export const startRetranscription = (sessionId: number) => {
  const config = store.getState().config
  const {
    numberOfSpeakers,
    minNumberOfSpeakers,
    maxNumberOfSpeakers
  } = store.getState().speakerSettingsModal

  let queryParameters = ""
  if (config && config.stt.enableDiarization.value && numberOfSpeakers) {
    queryParameters += `?minSpeakers=${numberOfSpeakers}&maxSpeakers=${numberOfSpeakers}`
  } else if (config && config.stt.enableDiarization.value) {
    if (minNumberOfSpeakers) queryParameters += `?minSpeakers=${minNumberOfSpeakers}`
    if (maxNumberOfSpeakers) queryParameters += `${queryParameters.length === 0 ? "?" : "&"}maxSpeakers=${maxNumberOfSpeakers}`
  }


  return axiosInstance.post(`${baseUrl}/${SESSIONS}/${sessionId}/recreate${queryParameters}`);
}

export const cancelRetranscription = (sessionId: number) => {
  return axiosInstance.post(`${baseUrl}/${SESSIONS}/${sessionId}/cancel`);
}