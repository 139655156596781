import React, { forwardRef, memo, useEffect, useRef, useState } from 'react';
import {
  ContentBlock,
  Editor,
  EditorBlock,
  EditorState,
  DraftHandleValue,
  Modifier,
  SelectionState,
  // RichUtils,
} from 'draft-js';
import { useDispatch } from 'react-redux';
import { setSpeakersModal } from '../../redux/actions/actions';
import SpeakerSection from './SpeakerSection';
import FormatBold from '@material-ui/icons/FormatBold';
import FormatItalic from '@material-ui/icons/FormatItalic';
import FormatUnderlined from '@material-ui/icons/FormatUnderlined';
import { SpeakersModalTypeEnum } from '../../redux/store/IStore';
import { Colors } from './IEditor';
// import { getAllEntitiesKeysFromSelection } from './helpers/EditorHelpers';

interface IProps {
  editorState: EditorState;
  onEditorChange: (EditorState: EditorState) => void;
  handleBeforeInput?: (chars: string, editorState: EditorState, eventTimeStamp: number) => any;
  handlePastedText?: (text: string, html: string | undefined, editorState: EditorState) => DraftHandleValue;
  customBlockStyleFn?: any;
  readOnly: boolean;
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
  isEditorFocused?: any;
  placeHolder?: any;
  editorSelectionUpdateOnMount?: {
    offset: number;
    blockKey: string;
  } | null;
}

const SpeakerBlockWrapper = (props: any) => {
  const { block } = props;
  const dispatch = useDispatch();

  const speaker = block.getData().get('speaker'); //block. tezt, depth, type, key, data,
  const text = block.getText();
  // const isEmpty = !block.text.trim().length;
  const prevBlockKey = props.contentState.getKeyBefore(block.key);
  const prevBlock = props.contentState.getBlockForKey(prevBlockKey);
  const prevBlockData = prevBlock ? prevBlock.getData().get('speaker') : null;
  // const prevBlockIsSame = true ? false : prevBlockData ? prevBlockData.id === speaker.id : false;
  
  //TOOD: Test this
  /*const prevBlockIsSame =
    speaker && prevBlockData !== null && prevBlockData && prevBlockData.id
      ? prevBlockData.id === speaker.id
      : false;*/


  /*if (speaker && speaker.name) {
    console.log(`${speaker.name}: ${text}`)
  }*/

  return (
    
    <div className="SpeakerBlockWrapper_wrapper">
      {speaker && (
        <SpeakerSection
          isFirst={prevBlock ? false : true}
          speaker={speaker}
          onClick={(speaker) => {
            dispatch(
              setSpeakersModal({
                editorSelection: null,
                modalType: SpeakersModalTypeEnum.ADD_SPEAKER_TO_SELECTION,
                showModal: true,
                speaker: speaker,
                blockKey: block.key,
              })
            );
          }}
        />
      )}
      <EditorBlock {...props} />
    </div>
  );
};

const blockRenderer = (contentBlock: ContentBlock) => {
  const type = contentBlock.getType();
  if (type === 'customBlock') {
    return {
      component: SpeakerBlockWrapper,
      // editable: true, needs to be unset to make readOnly work properly
      props: {},
    };
  }
};

// TO-DO:
const styleMap = {
  STRIKETHROUGH: {
    textDecoration: 'line-through',
  },
};

const CustomEditor = forwardRef<Editor, IProps>(
  (
    {
      editorState,
      onEditorChange,
      handleBeforeInput,
      setEditorState,
      handlePastedText,
      customBlockStyleFn,
      readOnly,
      isEditorFocused,
      placeHolder,
      editorSelectionUpdateOnMount,
    },
    ref
  ) => {
    const handleReturn = (e: any, editorState: EditorState) => {
      const contentState = editorState.getCurrentContent();
      const selectionState = editorState.getSelection();

      const blockKey = selectionState.getAnchorKey();

      const originalBlockData = contentState.getBlockForKey(blockKey).getData();
      //@ts-ignore
      const speakerData = originalBlockData.get('speaker');

      const newData = originalBlockData.set('isFirstInRange', false);

      if (speakerData) {
        const splitContentState = Modifier.splitBlock(editorState.getCurrentContent(), selectionState);
        const newBlockKey = splitContentState.getSelectionAfter().getStartKey();

        const newSelection1 = new SelectionState({
          anchorKey: newBlockKey,
          anchorOffset: 0,
          focusKey: newBlockKey,
          focusOffset: 0,
        });

        const a = Modifier.setBlockData(splitContentState, newSelection1, newData);
        const splitEditorState = EditorState.push(editorState, a, 'split-block');

        const newSelState = EditorState.forceSelection(splitEditorState, newSelection1);
        setEditorState(newSelState);
        return 'handled';
      }

      return 'not-handled';
    };

    // const toggleInlineStyles = (inlineStyle: string) => {
    //   const newState = RichUtils.toggleInlineStyle(editorState, inlineStyle);
    //   const currentStyle = newState.getCurrentInlineStyle();
    //   const stylesToAdd: string[] = [];
    //   currentStyle.flatMap((a) => {
    //     if (a) {
    //       stylesToAdd.push(a);
    //     }
    //     return a;
    //   });

    //   const selection = editorState.getSelection();
    //   const se = getAllEntitiesKeysFromSelection(editorState, selection);

    //   se.forEach((ent) => {
    //     newState.getCurrentContent().mergeEntityData(ent.entityKey, { inlineStyles: stylesToAdd });
    //   });

    //   setEditorState(newState);
    //   if (ref) {
    //     //@ts-ignore
    //     ref.current.focus();
    //   }
    // };

    const cursorSet = useRef(false);
    useEffect(() => {
      if (!editorSelectionUpdateOnMount || cursorSet.current) return;

      setEditorState((curr) => {
        const sel = new SelectionState({
          anchorKey: editorSelectionUpdateOnMount.blockKey,
          anchorOffset: editorSelectionUpdateOnMount.offset,
          focusKey: editorSelectionUpdateOnMount.blockKey,
          focusOffset: editorSelectionUpdateOnMount.offset,
          hasFocus: true,
          isBackwards: false,
        });

        return EditorState.forceSelection(curr, sel);
      });
      cursorSet.current = true;
    }, [editorSelectionUpdateOnMount]);

    return (
      <>
        {/* <div style={{ marginBottom: 30 }}>
          <InlineStyleControls toggleInlineStyle={toggleInlineStyles} editorState={editorState} />
        </div> */}
        <Editor
          ref={ref}
          editorState={editorState}
          onChange={onEditorChange}
          handleBeforeInput={handleBeforeInput}
          blockStyleFn={customBlockStyleFn}
          handlePastedText={handlePastedText}
          readOnly={readOnly}
          blockRendererFn={blockRenderer}
          stripPastedStyles={true}
          customStyleMap={styleMap}
          placeholder={placeHolder}
          handleReturn={handleReturn}
          // onBlur={() => {
          //   console.log('Will blur');
          //  isEditorFocused && isEditorFocused.current = false;
          // }}
          // onFocus={() => {
          //   console.log('Will focus');
          //  isEditorFocused && isEditorFocused.current = true;
          // }}
        />
      </>
    );
  }
);

export const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD', icon: FormatBold },
  { label: 'Italic', style: 'ITALIC', icon: FormatItalic },
  { label: 'Underline', style: 'UNDERLINE', icon: FormatUnderlined },
];

export const InlineStyleControls = ({
  editorState,
  toggleInlineStyle,
}: {
  editorState: EditorState;
  toggleInlineStyle: any;
}) => {
  // const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <div className="editor_style_controls_wrapper">
      {INLINE_STYLES.map((type) => (
        <button
          key={type.label}
          onMouseDown={(e) => {
            toggleInlineStyle(type.style);
            e.stopPropagation();
            e.preventDefault();
          }}
          className="editor_style_control_button"
        >
          <type.icon style={{ width: '100%', height: '100%' }} />
        </button>
      ))}
    </div>
  );
};

export default memo(CustomEditor);
