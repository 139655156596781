import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store/IStore';

interface Props {
  children: JSX.Element;
}

const AuthGuard = ({ children }: Props) => {
  const user = useSelector((state: IStore) => state.user);

  if (!user || !user.isAuthenticated) {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
};

export default AuthGuard;
