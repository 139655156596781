import React from 'react';
import { WordStatusEnum } from '../DictionaryModal/IDictionaryModal';

interface Props {
  status?: WordStatusEnum;
  text: string;
}

export default function MainWord({ text, status }: Props) {
  return (
    <span className="searched_word" style={status === WordStatusEnum.NEW ? { color: '#f80534' } : {}}>
      {text}
    </span>
  );
}
