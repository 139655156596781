import React, { ChangeEvent } from 'react';
import Modal from "../Home/Modal"
import SpeakerModalInput from './SpeakerSettingsInput';
import './speaker_settings_modal.css';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../redux/store/IStore';
import { setSpeakerSettingsModal } from '../../redux/actions/actions';

interface ISpeakerSettingsModalProps {
    handleCloseModal?: () => void;
}
const SpeakerSettingsModal = ({
    handleCloseModal
}: ISpeakerSettingsModalProps) => {
    const dispatch = useDispatch();
    const speakerSettingsModal = useSelector((store: IStore) => store.speakerSettingsModal)

    const handleSpecificSpeakerChange = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(setSpeakerSettingsModal({
            numberOfSpeakers: +event.target.value,
            minNumberOfSpeakers: null,
            maxNumberOfSpeakers: null,
        }))
    }

    const handleMinSpeakerChange = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(setSpeakerSettingsModal({
            numberOfSpeakers: null,
            minNumberOfSpeakers: +event.target.value,
        }))
    }

    const handleMaxSpeakerChange = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(setSpeakerSettingsModal({
            numberOfSpeakers: null,
            maxNumberOfSpeakers: +event.target.value,
        }))
    }

    const hideSpeakerSettingsModal = () => dispatch(setSpeakerSettingsModal({ visible: false }))

    const cancelSpeakerModal = () => {
        if (speakerSettingsModal.retranscription) {
            dispatch(setSpeakerSettingsModal({ retranscription: false }))
        }
        handleCloseModal && handleCloseModal();
        hideSpeakerSettingsModal();
    }

    return (
        <Modal visible={speakerSettingsModal.visible} closeModal={cancelSpeakerModal} title="Število govorcev">
            <div className='speaker_settings_modal_container'>
                <div className='speaker_setting_container'>
                    <h4 className='speaker_setting_title'>Število govorcev</h4>
                    <SpeakerModalInput
                        value={speakerSettingsModal.numberOfSpeakers ? speakerSettingsModal.numberOfSpeakers.toString() : ''}
                        callback={handleSpecificSpeakerChange}
                    />
                </div>
                <div className='speaker_setting_container'>
                    <h4 className='speaker_setting_title'>Min. št. govorcev</h4>
                    <SpeakerModalInput
                        value={speakerSettingsModal.minNumberOfSpeakers ? speakerSettingsModal.minNumberOfSpeakers.toString() : ''}
                        callback={handleMinSpeakerChange}
                    />
                </div>
                <div className='speaker_setting_container'>
                    <h4 className='speaker_setting_title'>Maks. št. govorcev</h4>
                    <SpeakerModalInput
                        value={speakerSettingsModal.maxNumberOfSpeakers ? speakerSettingsModal.maxNumberOfSpeakers.toString() : ''}
                        callback={handleMaxSpeakerChange}
                    />
                </div>
                <button id='confirm_button' onClick={hideSpeakerSettingsModal}>
                    Potrdi
                </button>
            </div>
        </Modal>

    )
}

export default SpeakerSettingsModal;