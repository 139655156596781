import React from 'react';
import { SvgIcon } from '@material-ui/core/';

export function ExtendIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        id="_24px"
        data-name="24px"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          id="Path_20"
          data-name="Path 20"
          d="M16.59,8.59,12,13.17,7.41,8.59,6,10l6,6,6-6Z"
          fill="rgba(0,0,0,0.2)"
        />
        <path id="Path_21" data-name="Path 21" d="M0,0H24V24H0Z" fill="none" />
      </svg>
    </SvgIcon>
  );
}
