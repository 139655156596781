export enum WordStatusEnum {
  IN_DICT = 'IN_DICTIONARY',
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  SAVED = 'SAVED',
}

export enum PronaunciationStatusEnum {
  SAVED = 'SAVED',
  AUTOGENERATED = 'AUTOGENERATED',
}

export interface IDictionaryModalProps {
  open: boolean;
  word?: string;
}

export interface IWord {
  id: number;
  text: string;
  status: WordStatusEnum;
  frequencyClassId?: number;
}

export interface IPronunciationTypes {
  id: number;
  label: string;
}

export interface IFrequencyClasses {
  id: number;
  label: string;
}

export enum PronunciationChangeTypes {
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export interface IPronunciationChangelog {
  pronunciationId: number;
  changeType: PronunciationChangeTypes;
}

export interface IWordsCount {
  wordsNew?: number;
  wordsInProgress?: number; //zaenkrat NULL
  wordsInDictionary?: number; // zaenkrat NULL
}

export interface IPhonems {
  nonsilentPhones: string;
  silentPhones: string;
}

export interface IDictRegenerationStatus {}
