import { useState, useEffect, useRef } from 'react';

export type KeyState = 'up' | 'down' | 'idle';

const useKeyPress = ({
  targetKey,
  exception,
  condition,
  callback,
  preventDefault = true,
}: {
  targetKey: KeyboardEvent['key'];
  exception?: boolean;
  condition?: boolean;
  callback?: (keyState: KeyState, e?: KeyboardEvent) => void;
  preventDefault?: boolean;
}) => {
  const [keyState, setKeyState] = useState<KeyState>('up');
  const latestCallback = useRef<(keyState: KeyState, e?: KeyboardEvent) => void | undefined>();

  latestCallback.current = callback;

  useEffect(() => {
    if (exception) return;

    const downHandler = (e: KeyboardEvent) => {
      if (e.key === targetKey && (condition === undefined || condition)) {
        preventDefault && e.preventDefault();
        setKeyState('down');

        latestCallback.current && latestCallback.current('down', e);
      }
    };
    const upHandler = (e: KeyboardEvent) => {
      if (e.key === targetKey) {
        preventDefault && e.preventDefault();

        setKeyState('up');
        latestCallback.current && latestCallback.current('up', e);
      }
    };

    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
      setKeyState('idle');
      latestCallback.current && latestCallback.current('idle');
    };
  }, [exception, condition, targetKey, preventDefault]);

  return keyState;
};

export default useKeyPress;
