import Avatar from '@material-ui/core/Avatar';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ClearIcon from '@material-ui/icons/Clear';
import React, { useState, useEffect, useCallback } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Select from 'react-select';

import {
  getAllUsers,
  getSessionSharedUsers,
  addMultipleSharedUsers,
  removeShare,
} from '../../api/SessionsService';
import { IShare } from './IDashboard';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store/IStore';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 2),
    borderRadius: '3px',
    width: theme.spacing(50),
    position: 'relative',
  },
  list: {
    maxHeight: theme.spacing(30),
    overflow: 'scroll',
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: '16px',
  },
  root: {
    '& > *': {
      width: '100%',
    },
  },
  subtitle: {
    marginBottom: theme.spacing(1),
  },
  button: {
    borderRadius: 0,
  },
}));

interface IProps {
  open: boolean;
  handleClose: Function;
  modalTitle: string;
  sessionId: number;
  setSharedWithOthers: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IOthUser {
  value: number; // id
  label: string; // username
}

const ShareModal = ({ open, handleClose, modalTitle, sessionId, setSharedWithOthers }: IProps) => {
  const classes = useStyles();
  const {
    enqueueSnackbar
  } = useSnackbar();

  const [isAddEnabled, setIsAddEnabled] = useState(false);
  const [inSearchMode, setInSearchMode] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [sharedWithUsers, setSharedWithUsers] = useState<IShare[]>([]);

  const [otherUsers, setOtherUsers] = useState<IOthUser[]>([]);
  const [currentlySelectedUsers, setCurrentlySelectedUsers] = useState<IOthUser[]>([]);

  const loggedInUser = useSelector((store: IStore) => store.user);


  useEffect(() => {
    let mounted = true
    const wrapper = async () => {
      if (open) {
        try {
          const sharesResponse = await getSessionSharedUsers(sessionId)
          const allUsersResponse = await getAllUsers()
  
          const shares = sharesResponse.data
          const allUsers = allUsersResponse.data
  
          const sharedUserIds = shares.map(({ sharedWith: { id } }) => id);
          const otherUsers = allUsers.filter(
            ({ id, username }) => !sharedUserIds.includes(id) && username !== loggedInUser?.username
          );
          
          if (mounted) {
            setSharedWithUsers(shares);
            setOtherUsers(() => otherUsers.map(({ id: value, username: label }) => ({ value, label })));
          }
        } catch (error) {
          enqueueSnackbar('Pri pridobivanju uporabnikov je prišlo do napake.', {variant: 'error'})
        }
      }
    }

    wrapper()

    return () => {
      mounted = false
    }
  }, [open, sessionId, loggedInUser?.username]);

  // Hide the icon if shared user length is 0
  useEffect(() => {
    if (sharedWithUsers.length === 0) {
      setSharedWithOthers(false);
    }
  }, [sharedWithUsers.length, setSharedWithOthers]);

  const addUsersToShare = () => {
    setIsLoading(true);

    const addIds = currentlySelectedUsers.map(({ value }) => value);

    addMultipleSharedUsers(sessionId, addIds)
      .then(() => {
        setSharedWithOthers(true);
      })
      .finally(() => {
        setIsLoading(false);
        setInSearchMode(false);
        handleClose(false);
      });
  };

  const removeUserFromShare = useCallback(async (shareIdToRemove: number) => {
    try {
      await removeShare(shareIdToRemove)
      
      const removedUser = sharedWithUsers.find(({ id }) => id === shareIdToRemove)
      if (removedUser) {
        const otherUsersCopy = [...otherUsers]
        otherUsersCopy.push({
          value: removedUser.sharedWith.id,
          label: removedUser.sharedWith.username,
        })
        setOtherUsers(otherUsersCopy)
      }
      
      setSharedWithUsers(sharedWithUsers.filter(user => user.id !== shareIdToRemove));
    } catch (error) {
      enqueueSnackbar('Brisanje uporabnika ni uspelo.', {variant: 'error'})
    }
  }, [sharedWithUsers, otherUsers])

  /**
   * Whenever the dropdown select changes
   * @param e
   */
  const onSelectChange = (e) => {
    setCurrentlySelectedUsers(e);
    setIsAddEnabled(e.length !== 0);
  };

  const closeModal = () => {
    if (isLoading) return;

    setInSearchMode(false);
    handleClose(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          {isLoading && (
            <Box
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgba(150,143,240,0.1)',
                zIndex: 100,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          )}

          <Box
            flexDirection="row"
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Box
              style={{
                width: !inSearchMode ? 0 : 40,
                overflow: 'hidden',
                transition: 'width 0.3s',
              }}
            >
              <IconButton aria-label="delete" color="primary" onClick={() => setInSearchMode(false)}>
                <ArrowBackIosIcon />
              </IconButton>
            </Box>

            <h2 id="transition-modal-title">{modalTitle}</h2>

            <div style={{ flexGrow: 1 }}></div>

            <Fade in={inSearchMode}>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  className={classes.button}
                  disabled={!isAddEnabled}
                  onClick={addUsersToShare}
                >
                  Dodaj
                </Button>
              </Box>
            </Fade>
          </Box>

          <Collapse in={!inSearchMode}>
            <Typography className={classes.subtitle}> Osebe ki imajo dostop </Typography>

            {sharedWithUsers.length === 0 ? (
              <Box
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px' }}
              >
                <Typography variant="overline" style={{ fontWeight: 500 }}>
                  Seja ni deljena
                </Typography>
              </Box>
            ) : (
              <List className={classes.list}>
                {sharedWithUsers.map(({ id, sharedWith: { username } }) => (
                  <ListItem key={id}>
                    <ListItemAvatar>
                      <Avatar className={classes.small} variant="rounded">
                        {username && username.length > 0 ? username.charAt(0) : 'u'}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={username} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" onClick={() => removeUserFromShare(id)}>
                        <ClearIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            )}
          </Collapse>

          <Typography className={classes.subtitle} color={inSearchMode ? 'primary' : 'textPrimary'}>
            Dodaj nove osebe
          </Typography>
          <Select
            onChange={onSelectChange}
            options={otherUsers}
            placeholder={'Izberi...'}
            components={{ Option: CustomOption }}
            isMulti
            noOptionsMessage={() => 'Ni uporabnikov'}
            closeMenuOnSelect={false}
            onFocus={() => setInSearchMode(true)}
          />
        </div>
      </Fade>
    </Modal>
  );
};

const CustomOption = ({ innerProps, isDisabled, label, isFocused, ...oth }) => {
  if (isDisabled) return null;

  return (
    <div {...innerProps}>
      <ListItem selected={isFocused}>
        <ListItemAvatar>
          <Avatar variant="rounded" style={{ height: 30, width: 30 }}>
            {label.charAt(0) || 'u'}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={label} />
      </ListItem>
    </div>
  );
};

export default ShareModal;
