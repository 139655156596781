import React, { useEffect, useState } from 'react';
import TableHeaderItem from './TableHeaderItem';
import { DndContext, useSensor, useSensors, closestCorners, MouseSensor, TouchSensor } from '@dnd-kit/core';
import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { restrictToHorizontalAxis, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import { useDispatch, useSelector } from 'react-redux';

import '../../styles/css/dashboard.css';
import { DashboardRefreshState, IHeader, IHeaderColumn, IStore } from '../../redux/store/IStore';
import {
  setDashboardHeader,
  setDashboardTableRefresh,
  setDashboardTableWidth,
} from '../../redux/actions/actions';
import useLocalStorageSave from '../../hooks/useLocalStorageSave';

const TableHeader = () => {
  const dispatch = useDispatch();

  const header = useSelector((store: IStore) => store.dashboardHeader) as IHeader;

  const [saveHeader] = useLocalStorageSave('header');

  const [sortedItems, setSortedItems] = useState<IHeaderColumn[]>([]);

  const [allowNewColumns, setAllowNewColumns] = useState(false);
  const [allowDeletingColumns, setAllowDeletingColumns] = useState(false);

  useEffect(() => {
    setAllowDeletingColumns(sortedItems.length >= 2);

    const tableWidth = (sortedItems as IHeaderColumn[]).reduce((a, { width }) => a + width, 0);
    dispatch(setDashboardTableWidth(tableWidth));
  }, [sortedItems, dispatch]);

  useEffect(() => {
    const headerValues = Object.values(header) as IHeaderColumn[];

    setSortedItems(
      headerValues.filter(({ shown }: IHeaderColumn) => shown).sort(({ order: a }, { order: b }) => a - b)
    );

    saveHeader(header);
    setAllowNewColumns(headerValues.some(({ shown }) => !shown));
  }, [header, saveHeader]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    })
  );

  // Because we are keeping columns as an Object map we need to do some weird math :|
  const handleDragEnd = ({ active: { id: idA }, over: { id: idB } }: any) => {
    if (idA !== idB) {
      const orderA = (header[idA] as IHeaderColumn).order;
      const orderB = (header[idB] as IHeaderColumn).order;

      const fromLeft = orderA < orderB;

      const newHeader = {};

      Object.keys(header).forEach((key) => {
        if (key === idA) newHeader[key] = { ...header[key], order: orderB };
        else if (fromLeft) {
          const newOrder = header[key].order - (header[key].order <= orderB ? 1 : 0);
          newHeader[key] = { ...header[key], order: newOrder };
        } else {
          // from right
          const newOrder = header[key].order + (header[key].order >= orderB ? 1 : 0);
          newHeader[key] = { ...header[key], order: newOrder };
        }
      });


      //here header is added
      dispatch(setDashboardHeader(newHeader));
      dispatch(setDashboardTableRefresh(DashboardRefreshState.NORMAL));
    }
  };

  return (
    <div className="table-header">
      <div className="row-extender-icon-spacer"></div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToHorizontalAxis, restrictToFirstScrollableAncestor]}
      >
        <SortableContext items={sortedItems} strategy={horizontalListSortingStrategy}>
          {sortedItems.map((column: IHeaderColumn) => {
            return (
              <TableHeaderItem
                key={column.id}
                id={column.id}
                column={column}
                allowDeletingColumns={allowDeletingColumns}
                allowNewColumns={allowNewColumns}
              />
            );
          })}
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default TableHeader;
