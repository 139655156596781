const separateExtenstions = (fileName: string): string[] => {
  let fileNameSplit: string[] = fileName.split('.');
  if (fileNameSplit.length > 1) {
    const extension = fileNameSplit[fileNameSplit.length - 1];
    fileNameSplit.splice(fileNameSplit.length - 1, 1);
    return [fileNameSplit.join('.'), extension];
  }
  return [fileNameSplit.join('.')];
};

export default separateExtenstions;
