import React, { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import useWs from '../../hooks/useWs';
import { EditorModeEnums, IStore } from '../../redux/store/IStore';
import { convertSecondsToTimeString } from '../../shared/DataConverters';
import '../../styles/css/audio_player.css';
import { IReceivedTranscript, ReceivedMessageTypes } from '../Libraries/ILibraries';

interface TimebarProps {
  duration: number | null;
  onTimeUpdate: any;
}

const Timebar = (props: TimebarProps) => {
  const { duration, onTimeUpdate } = props;
  const currTime = useSelector<IStore, number>((state) => state.currentTime);
  const editorMode = useSelector((state: IStore) => state.editorMode);
  const uploadedFileDuration = useSelector((state: IStore) => state.uploadedFileDuration);
  const [currPercentage, setCurrPercentage] = React.useState<number>(0);
  const [currPercentageUpload, setCurrPercentageUpload] = React.useState<number>(0);
  const [showTimeStringPopup, setShowTimestringPopup] = React.useState<boolean>(false);
  const [hoveredTime, setHoveredTime] = React.useState<number>();
  const [popupPos, setPopupPos] = React.useState<number>(0);
  const [trianglePos, setTrianglePos] = React.useState<number>(0);
  const timeStringPopupRef = React.useRef<HTMLDivElement>(null);
  const barProgressRef = React.useRef<HTMLDivElement>(null);
  const { lastJsonMessage: receivedMessage } = useWs();
  const [currentProgressForUploadedFile, setCurrentProgressForUploadedFile] = React.useState(0);

  React.useEffect(() => {
    if (receivedMessage) {
      if (receivedMessage.messageType === ReceivedMessageTypes.TRANSCRIPT) {
        const transcriptMessage: IReceivedTranscript = receivedMessage as IReceivedTranscript;

        if (editorMode === EditorModeEnums.TRANSCRIBING_UPLOAD_MODE) {
          setCurrentProgressForUploadedFile(transcriptMessage.decoded);
        }
      }
    }
  }, [receivedMessage]);

  const intId = useRef<any>(null);

  React.useEffect(() => {
    return () => clearInterval(intId.current);
  }, []);

  React.useEffect(() => {
    if (editorMode !== EditorModeEnums.TRANSCRIBING_UPLOAD_MODE || !uploadedFileDuration) return;

    if (currentProgressForUploadedFile !== 0) return clearInterval(intId.current);
    setCurrPercentageUpload(0.1);

    intId.current = setInterval(() => {
      setCurrPercentageUpload((curr) => curr + 0.03);
    }, 500);

    return () => clearInterval(intId.current);
  }, [editorMode, currentProgressForUploadedFile, uploadedFileDuration]);

  React.useEffect(() => {
    if (editorMode !== EditorModeEnums.TRANSCRIBING_UPLOAD_MODE || !uploadedFileDuration) return;

    const newPercentage = (currentProgressForUploadedFile / uploadedFileDuration) * 100;

    newPercentage !== 0 &&
      setCurrPercentageUpload((currentProgressForUploadedFile / uploadedFileDuration) * 100);
  }, [editorMode, currentProgressForUploadedFile, uploadedFileDuration]);

  React.useEffect(() => {
    if (currTime === 0) {
      setCurrPercentage(0);
    }
    if (duration != null) {
      setCurrPercentage((currTime / duration) * 100);
    }
  }, [currTime, duration]);

  const calcClickedTime = useCallback(
    (e: any) => {
      const clickPositionInPage = e.pageX;
      // const bar: HTMLElement | null = document.querySelector('.bar__progress');
      const bar = barProgressRef.current;

      if (bar !== null) {
        const barStart = bar.getBoundingClientRect().left + window.scrollX;
        const barWidth = bar.offsetWidth;
        const clickPositionInBar = clickPositionInPage - barStart;
        if (duration != null) {
          const timePerPixel = duration / barWidth;
          return timePerPixel * clickPositionInBar;
        }
      }
    },
    [duration]
  );

  const calcTimeStringOnHover = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const hoverPos = e.pageX;
      const bar = barProgressRef.current;

      if (bar != null) {
        const barStart = bar.getBoundingClientRect().left + window.scrollX;
        const barWidth = bar.offsetWidth;
        const hoverPosInBar = hoverPos - barStart;

        const durPercent = hoverPosInBar / barWidth;

        const popUp = timeStringPopupRef.current;
        if (popUp) {
          const dim = popUp.getBoundingClientRect();
          if (dim.width / 2 > hoverPosInBar) {
            setTrianglePos(hoverPosInBar - dim.width / 2);
            setPopupPos(dim.width / 2);
          } else if (barWidth - dim.width / 2 <= hoverPosInBar) {
            setPopupPos(barWidth - dim.width / 2);
            const inti = barWidth - dim.width / 2;
            const finalFinal = hoverPosInBar - inti;
            setTrianglePos(finalFinal);
          } else {
            setPopupPos(hoverPosInBar);
            setTrianglePos(0);
          }
        }

        if (duration != null) {
          const timeString = durPercent * duration;
          setHoveredTime(timeString);
        }
      }
    },
    [setTrianglePos, setPopupPos, duration]
  );

  const handleTimeDrag = useCallback(
    (e: any) => {
      onTimeUpdate(calcClickedTime(e));

      const updateTimeOnMove = (eMove: any) => {
        onTimeUpdate(calcClickedTime(eMove));
      };

      document.addEventListener('mousemove', updateTimeOnMove);

      document.addEventListener('mouseup', () => {
        document.removeEventListener('mousemove', updateTimeOnMove);
      });
    },
    [onTimeUpdate, calcClickedTime]
  );

  return (
    <div
      onMouseEnter={() => setShowTimestringPopup(true)}
      onMouseLeave={() => setShowTimestringPopup(false)}
      style={{
        height: '8px',
        width: '100vw',
        backgroundColor: '#CCCCCC',
        position: 'relative',
        pointerEvents:
          editorMode !== EditorModeEnums.PLAYING_MODE && editorMode !== EditorModeEnums.EDIT_MODE
            ? 'none'
            : 'all',
      }}
    >
      {showTimeStringPopup &&
        (editorMode === EditorModeEnums.PLAYING_MODE || editorMode === EditorModeEnums.EDIT_MODE) && (
          <div
            ref={timeStringPopupRef}
            className="time_string_popup"
            style={{ left: `${popupPos}px`, transform: `translateX(-50%)` }}
          >
            <div className="time_string_popup_triangle" style={{ left: `calc(50% + ${trianglePos}px)` }} />
            <span>{convertSecondsToTimeString(hoveredTime ? hoveredTime : 0, false, true)}</span>
          </div>
        )}
      <div className="bar">
        {editorMode === EditorModeEnums.TRANSCRIBING_UPLOAD_MODE ? (
          <div
            className="bar__progress"
            style={{
              background: `linear-gradient(to right,#ff6666 ${currPercentageUpload}%, #cccccc 0)`,
              // backgroundColor: '#ff6666',
              // transform: `translateX(-${100 - currPercentageUpload}%)`,
            }}
          ></div>
        ) : (
          <div
            ref={barProgressRef}
            className="bar__progress"
            style={{
              background: `linear-gradient(to right, #2B8DFF ${currPercentage}%, #cccccc 0)`,
              // backgroundColor: '#2B8DFF',
              // transform: `translateX(-${100 - currPercentage}%)`,
            }}
            onMouseDown={(e) => handleTimeDrag(e)}
            onMouseMove={(e) => calcTimeStringOnHover(e)}
          >
            {/* <span className="bar__progress__knob" style={{ left: `${currPercentage}%` }} /> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Timebar;
