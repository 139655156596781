import { RouteProps } from 'react-router-dom';
import Editor from '../Editor/Editor';
import AuthGuard from '../Guards/AuthGuard';
import Home from '../Home/Home';
import Dashboard from '../Dashboard/Dashboard';
import { FunctionComponent } from 'react';
import { EditorProvider } from '../../providers/EditorProvider';
import HomescreenProvider from '../../providers/HomescreenProvider';
import DictionaryMain from '../Dictionary/DictionaryMain';
import { config } from '../../config';

export interface MainRoute extends RouteProps {
  guard?: (arg0: any) => JSX.Element;
  provider?: FunctionComponent<any>;
}

export const mainRoutes: MainRoute[] = [
  { exact: true, path: '/', component: Home, provider: HomescreenProvider },
  { exact: true, path: '/editor', guard: AuthGuard, component: Editor, provider: EditorProvider },
  { exact: true, path: '/dashboard', guard: AuthGuard, component: Dashboard },
  ...(config.environment !== 'demo'
    ? [{ exact: true, path: '/dictionary', guard: AuthGuard, component: DictionaryMain }]
    : []),
];
