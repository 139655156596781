import audioChunkProcessor from './audioChunkProcessor';
import WebWorker from './workerSetup';
import { isSafari, isMobileSafari, isIE, isFirefox } from 'react-device-detect';

// Aliases
(window as any).AudioContext = (window as any).AudioContext || (window as any).webkitAudioContext;
(window as any).OfflineAudioContext =
  (window as any).OfflineAudioContext || (window as any).webkitOfflineAudioContext;
AudioContext.prototype.createScriptProcessor =
  AudioContext.prototype.createScriptProcessor || (AudioContext.prototype as any).createJavaScriptNode;

export interface IMicrophoneOptions {
  chunkSize: number;
  sampleRate: number;
}

let audioContext: AudioContext | undefined = undefined;
let sourceNode: MediaStreamAudioSourceNode | undefined = undefined;
let scriptNode: ScriptProcessorNode | undefined = undefined;
let workletNode: AudioWorkletNode | undefined = undefined;
let worker: Worker | undefined = undefined;
let gainNode: GainNode | undefined = undefined;
let deviceStream: MediaStream | undefined = undefined;

export function getSampleRateFromCtx() {
  return audioContext ? audioContext.sampleRate : undefined;
}

export function isContextInitialized() {
  return audioContext !== undefined;
}

/** Unlock AudioContext. Must be called from inside user event! */
export async function unlockAudio() {
  if (!audioContext) return;
  await audioContext.resume();
}

/** Lock AudioContext. Must be called from inside user event! */
export async function lockAudio() {
  if (!audioContext) return;
  await audioContext.suspend();
}

export async function releaseAudio() {
  // Device (microphone) is released only after we release the audio stream.
  // Otherwise, we're still using it and e.g. Chrome displays recording icon,
  // even when we suspend the context (see `lockAudio()` above). This function
  // should be used once the recording is not needed anymore, like when Editor page
  // is closed.
  if (!audioContext) return;

  if (!!sourceNode) {
    sourceNode.disconnect();
    sourceNode = undefined;
  }

  if (!!deviceStream) {
    deviceStream.getTracks().forEach((track) => track.stop());
    deviceStream = undefined;
  }

  await audioContext.close();
  audioContext = undefined;
}

export function isRecording() {
  return audioContext ? audioContext.state === 'running' : false;
}

/** Initializes the object by asking user to select microphone. */
export async function initMicrophone(
  targetSampleRate: number,
  chunkSize: number,
  callback: (data: any) => void
) {
  if (!navigator || !navigator.mediaDevices || !navigator.mediaDevices.getUserMedia)
    throw new Error('Your browser does not support WebAudio!');

  try {
    const isSafariDevice = isSafari || isIE || isMobileSafari || isFirefox;
    const onaudiop = true;
    // console.log('audioCtx init', isSafari);
    audioContext = new window.AudioContext(!isSafariDevice ? { sampleRate: 16000 } : {});
    await lockAudio();

    // Select microphone as source node
    deviceStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });

    sourceNode = audioContext.createMediaStreamSource(deviceStream);

    if (isSafariDevice || onaudiop) {
      // Create WebWorker which is used to process each audio chunk in separate thread
      //@ts-ignore
      worker = new WebWorker(audioChunkProcessor);
      // const worker = new myWorker();
      //@ts-ignore
      worker.addEventListener('message', (e) => {
        callback(e['data']['audioChunk']);
      });

      // const chunkSize = generateChunkSizeFromSampleRate(audioContext.sampleRate);
      // const chunkSize = 2048; // 16384, 8192, 2048

      // Create audio processor node to send audio chunks to AudioProcessor (WebWorker) and register callback for processed chunks

      scriptNode = audioContext.createScriptProcessor(chunkSize, 1, 1);
      scriptNode.onaudioprocess = async (event) => {
        worker &&
          worker.postMessage({
            sourceSampleRate: audioContext ? audioContext.sampleRate : 16000,
            targetSampleRate: targetSampleRate,
            audioChunk: event.inputBuffer.getChannelData(0),
            chunkSize,
          });
      };

      // Connect source (microphone) to scriptNode
      sourceNode.connect(scriptNode);
      // Connect script node to destination
      scriptNode.connect(audioContext.destination);
    } else {
      // // WORKLET - currently not using
      // await audioContext.audioWorklet.addModule('worklet/ChunkProcessingWorklet.js');
      // workletNode = new AudioWorkletNode(audioContext, 'chunk-processing-worklet', {
      //   numberOfInputs: 1,
      //   numberOfOutputs: 1,
      //   outputChannelCount: [1],
      //   processorOptions: {
      //     sourceSampleRate: audioContext.sampleRate,
      //     targetSampleRate: targetSampleRate,
      //     chunkSize: chunkSize,
      //   },
      // });
      // if (workletNode) {
      //   workletNode.port.onmessage = (message) => {
      //     callback(message.data);
      //   };
      //   // Create gain node and mute audio before playing it to local speakers
      //   gainNode = audioContext.createGain();
      //   gainNode.gain.value = 0;
      //   sourceNode.connect(workletNode).connect(gainNode).connect(audioContext.destination);
      // }
    }
  } catch (exception) {
    throw new Error('Error initializing microphone! -> ' + exception);
  }
}

// Sample rate     |     buffer size
//    16k         ->        8192
//    22050                 16348
//    44100                 32768
//    48000                 32768
//    96000                 65536

// const srbs = [
//   {
//     sr: 16000,
//     bs: 8192,
//   },
//   {
//     sr: 22050,
//     bs: 16348,
//   },
//   {
//     sr: 44100,
//     bs: 32768,
//   },
//   {
//     sr: 48000,
//     bs: 32768,
//   },
//   {
//     sr: 96000,
//     bs: 65536,
//   },
// ];

// const generateChunkSizeFromSampleRate = (sr: number) => {
//   const f = srbs.find((s) => s.sr === sr);
//   if (f) {
//     return f.bs;
//   }

//   const f2 = srbs.find((s) => s.sr >= sr);
//   return f2 ? f2.bs : srbs[1].bs;
// };
