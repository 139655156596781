import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useSelector, useDispatch } from 'react-redux';
import {
  IStore,
  IAddColumnMenu,
  IHeader,
  IHeaderColumn,
  DashboardRefreshState,
} from '../../redux/store/IStore';
import {
  setAddColumnAnchor,
  setDashboardHeader,
  setDashboardTableRefresh,
} from '../../redux/actions/actions';

const ColumnMenuContext = () => {
  const dispatch = useDispatch();
  const addColumnsMenu = useSelector((state: IStore) => state.addColumnsMenu) as IAddColumnMenu;

  const header = useSelector((state: IStore) => state.dashboardHeader) as IHeader;

  const handleClose = () => dispatch(setAddColumnAnchor({ anchor: null, id: '' }));

  const addColumn = (idToAdd: string) => {
    handleClose();

    const currentId = addColumnsMenu.id;

    const currentOrder = header[currentId].order;
    const toAddOrder = currentOrder + 1;

    const newHeader = {};

    Object.keys(header).forEach((key) => {
      const order = header[key].order;

      if (key === idToAdd) newHeader[key] = { ...header[key], order: toAddOrder, shown: true };
      else if (order >= toAddOrder) newHeader[key] = { ...header[key], order: order + 1 };
      else newHeader[key] = header[key];
    });

    dispatch(setDashboardHeader(newHeader));
    dispatch(setDashboardTableRefresh(DashboardRefreshState.NORMAL));
  };

  return (
    <Menu
      id="add-column-menu"
      anchorEl={addColumnsMenu.anchor}
      keepMounted
      open={Boolean(addColumnsMenu.anchor)}
      onClose={handleClose}
    >
      {(Object.values(header) as IHeaderColumn[])
        .filter(({ shown }: IHeaderColumn) => !shown)
        .map((column: IHeaderColumn) => (
          <MenuItem dense key={column.id} onClick={() => addColumn(column.id)}>
            {column.label}
          </MenuItem>
        ))}
    </Menu>
  );
};

export default ColumnMenuContext;
