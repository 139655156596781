import React from 'react';
import MaterialChip, { ChipProps } from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';

interface IProps extends ChipProps {
  customColor: string;
}

const colors = ['#2B8DFF', '#005173', '#83B692', '#C65B7C', '#5B3758'];

export const testColourGenerate = (id: number): string => {
  return colors[id % colors.length];
};

const Chip = ({ customColor, ...rest }: IProps) => {
  const StyledChip = withStyles({
    root: {
      color: 'white',
      backgroundColor: `${customColor} !important`,
      '&:hover': {
        backgroundColor: customColor,
        filter: 'brightness(120%)',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: customColor,
        borderColor: customColor,
      },
    },
    outlined: {
      color: customColor,
      border: `1px solid ${customColor}`,
      backgroundColor: `transparent !important`,
    },
    icon: {
      color: rest.variant === 'outlined' ? customColor : 'white',
    },
    deleteIcon: {
      color: rest.variant === 'outlined' ? customColor : 'white',
    },
  })(MaterialChip);

  return <StyledChip {...rest} />;
};

export default Chip;
