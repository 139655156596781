import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDictionary } from '../../redux/actions/actions';
import { IStore } from '../../redux/store/IStore';

function getSearchByAndNormalizeString(str: string): {
  finalSearchString: string;
  searchBy: 'word' | 'pronunciation';
} {
  const isSearchByPronaun = str.startsWith('/') && str.endsWith('/') && str.length > 1;
  const normalizedStr = isSearchByPronaun ? str.slice(1, -1) : str;

  return {
    finalSearchString: normalizedStr,
    searchBy: isSearchByPronaun ? 'pronunciation' : 'word',
  };
}

export default function DictionaryHeader() {
  const [searchedPhrase, setSearchedPhrase] = useState<string>('');
  const searchBy = useSelector((state: IStore) => state.dictionary.searchBy);
  const [localSearchBy, setLocalSearchBy] = useState<'word' | 'pronunciation'>(searchBy);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const isSearchByPronaun = value.startsWith('/') && value.endsWith('/') && value.length > 1;
    setSearchedPhrase(value);
    setLocalSearchBy(isSearchByPronaun ? 'pronunciation' : 'word');
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (searchedPhrase === '') return;
    const { finalSearchString, searchBy } = getSearchByAndNormalizeString(searchedPhrase);
    if (finalSearchString === '') return;
    dispatch(setDictionary({ searchedString: finalSearchString, searchBy }));
  };

  return (
    <form className="dict_header_wrapper" onSubmit={handleSubmit}>
      <span>
        ISKANJE PO: <b>{localSearchBy === 'pronunciation' ? 'izgovorjavi' : 'besedah'}</b>
      </span>
      <input onChange={handleChange} value={searchedPhrase} ref={inputRef} />
    </form>
  );
}
