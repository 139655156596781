/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDmodelStatus } from '../api/DictionaryService';
import { IStore } from '../redux/store/IStore';

export default function useCheckIfModelAvailable() {
  const [isAvailable, setIsAvailable] = useState<boolean>(false);
  const [currentModel, setCurrentModel] = useState<{
    tl: string;
    td: string;
    tmv: string;
    id: number | undefined | null;
    name: string | undefined | null;
    createdAt: string | undefined | null;
  }>();
  const transcriptionLanguage = useSelector((state: IStore) => state.config?.stt.language.value);
  const transcriptionDomain = useSelector((state: IStore) => state.config?.stt.domain.value);
  const transcriptionModelVersion = useSelector((state: IStore) => state.config?.stt.model.value);

  const fetchCurrentModelStatus = useCallback(async () => {
    
    if (
      !transcriptionLanguage ||
      !transcriptionDomain ||
      !transcriptionModelVersion) return;

    try {
      const res = await getDmodelStatus({
        language: transcriptionLanguage,
        domain: transcriptionDomain,
        modelVersion: transcriptionModelVersion,
      });

      if (res.status === 200) {
        const { id, name, createdAt } = res.data;
        setCurrentModel({
          tl: transcriptionLanguage,
          td: transcriptionDomain,
          tmv: transcriptionModelVersion,
          id: id || null,
          name: name || null,
          createdAt: createdAt || null,
        });
        setIsAvailable(true);
      } else {
        setCurrentModel({
          tl: transcriptionLanguage,
          td: transcriptionDomain,
          tmv: transcriptionModelVersion,
          id: null,
          name: null,
          createdAt: null,
        });
        setIsAvailable(false);
      }
    } catch (e) {
      setIsAvailable(false);
      setCurrentModel({
        tl: transcriptionLanguage,
        td: transcriptionDomain,
        tmv: transcriptionModelVersion,
        id: null,
        name: null,
        createdAt: null,
      });
      // console.log(e);
    }
  }, [
    transcriptionLanguage,
    transcriptionDomain,
    transcriptionModelVersion,
    setIsAvailable,
    setCurrentModel,
  ]);

  useEffect(() => {
    if (!transcriptionLanguage || !transcriptionDomain || !transcriptionModelVersion) return;

    fetchCurrentModelStatus();
  }, [transcriptionModelVersion]);

  return { isAvailable, currentModel, fetchCurrentModelStatus };
}
