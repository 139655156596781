import React from "react"
import './consumption_bar.css'
interface IConsumptionBar {
    maxValue: number,
    value: number,
}

const ConsumptionBar = ({
    maxValue,
    value
}: IConsumptionBar) => {

    const percentage = Math.round((value / maxValue) * 100)

    const activeBarColour = percentage > 75 ? "#ED706B" : "#55BCA6"
    
    return (
        <div className="consumption_bar_container">
            <div style={{background: `linear-gradient(to right, ${activeBarColour} ${percentage}%, ${activeBarColour} ${percentage}%, #D9D9D9 ${percentage}%, #D9D9D9 ${100}%)`}} className="consumption_chunk"></div>
            <div className="consumption_bar_seperators_container">
                <div className="consumption_bar_seperator"/>
                <div className="consumption_bar_seperator"/>
                <div className="consumption_bar_seperator"/>
            </div>
        </div>
    )
}

export default ConsumptionBar