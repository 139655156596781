import React from 'react';
import { SvgIcon } from '@material-ui/core/';

export function DownloadIcon(props: any) {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                <g id="Group_3" data-name="Group 3" transform="translate(-4741 -2376)">
                    <rect id="Rectangle_29" data-name="Rectangle 29" width="48" height="48" transform="translate(4741 2376)" fill="none"/>
                    <path id="file_download_24px" d="M43,17.471H32.143V3H15.857V17.471H5L24,34.353ZM5,39.176V44H43V39.176Z" transform="translate(4741 2376)" fill="#ccc"/>
                </g>
            </svg>
        </SvgIcon>
    );
}