import Word, { NewWord } from '../Word';
import Draft, {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  EditorState,
  Modifier,
  SelectionState,
} from 'draft-js';
import { IEntityMap, IEntityRange, ISelectedEntities, IWordData, Mutabilities } from '../IEditor';
import { Entry } from '../SimpleEntriesEditor';

// Get all the entities(word in our case) to then wrap them in a WORD component
export const getEntityStrategy =
  (mutability: any) =>
  (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState
  ) => {
    contentBlock.findEntityRanges((character: any) => {
      const entityKey = character.getEntity();
      if (entityKey === null) {
        return false;
      }

      return contentState.getEntity(entityKey).getMutability() === mutability;
    }, callback);
  };

// Gets all the character in our block that arent entities
export const getNonEntitites = () => (contentBlock: any, callback: any) => {
  contentBlock.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();
    if (entityKey === null) {
      return true;
    }
  }, callback);
};

// Function to find ranges with regex
// const findWithRegex = (regex: any, contentBlock: any, callback: any) => {
//   const text = contentBlock.getText();
//   let matchArr, start;
//   while ((matchArr = regex.exec(text)) !== null) {
//     start = matchArr.index;
//     callback(start, start + matchArr[0].length);
//   }
// };

// decorator definition - Draftjs
// defines what to use to render the entity
// TO-DO: decide if we want to pass additional props to word with, props: {...props}
export const decorator = new CompositeDecorator([
  {
    strategy: getEntityStrategy('MUTABLE'),
    component: Word,
  },
  {
    strategy: getNonEntitites(),
    component: NewWord,
  },
]);

export const entriesDecorator = new CompositeDecorator([
  {
    strategy: getEntityStrategy('SEGMENTED'),
    component: Entry,
  },
]);

export const generateEntityRanges = (trans: IWordData[], startOffset: number) => {
  let position = startOffset;
  //   console.log(trans, startOffset);
  const enRanges: IEntityRange[] = trans.map((word, i, arr) => {
    const text = word.updatedText ? word.updatedText : word.text;
    const result = {
      key: Math.random().toString(36).substring(6),
      offset: i === 0 ? 0 : word.spaceBefore ? position + 1 : position,
      length: i === arr.length - 1 ? text.length : text.length, // +1
      word: text,
    };

    position = position + text.length; //+1
    if (i !== 0 && word.spaceBefore) position += 1;
    return result;
  });
  //   console.log(enRanges);
  return enRanges;
};

export const generateEntityRangesForEntryVars = (trans: IWordData[], startOffset: number) => {
  let position = startOffset;
  const enRanges: IEntityRange[] = [];
  //   console.log(trans, startOffset);
  trans.forEach((word, i, arr) => {
    const text = word.variableCode ? word.variableCode : word.updatedText ? word.updatedText : word.text;
    const result = {
      key: Math.random().toString(36).substring(6),
      offset: i === 0 ? 0 : word.spaceBefore ? position + 1 : position,
      length: i === arr.length - 1 ? text.length : text.length, // +1
      word: text,
    };

    position = position + text.length; //+1
    if (i !== 0 && word.spaceBefore) position += 1;
    if (word.variableCode) {
      enRanges.push(result);
    }
  });
  //   console.log(enRanges);
  return enRanges;
};

export const generateInlineStyleRanges = (words: IWordData[], startOffset: number) => {
  let position = startOffset;
  let finalRanges: { offset: number; length: number; style: string }[] = [];

  words.forEach((word, i) => {
    if (i !== 0 && word.spaceBefore) {
      position += 1;
    }

    let currLen = word.updatedText !== undefined ? word.updatedText.length : word.text.length;

    if (word.inlineStyles) {
      word.inlineStyles.forEach((style, k) => {
        finalRanges.push({ offset: position, length: currLen, style });
      });
    }

    position = position + (word.updatedText !== undefined ? word.updatedText.length : word.text.length);
  });

  return finalRanges;
};

export const generateEntityMap = (transcript: IWordData[], entityRanges: IEntityRange[]) => {
  const entityMap: IEntityMap = {};

  for (let i = 0; i < transcript.length; i++) {
    const word = transcript[i];
    entityMap[entityRanges[i].key] = {
      type: 'WORD',
      mutability: Mutabilities.MUTABLE,
      data: { ...word },
    };
  }

  return entityMap;
};

export const generateEntityMapForEntryVars = (transcript: IWordData[], entityRanges: IEntityRange[]) => {
  const entityMap: any = {};

  let counter = 0;
  for (let i = 0; i < transcript.length; i++) {
    const word = transcript[i];
    if (word.variableCode) {
      entityMap[entityRanges[counter].key] = {
        type: '{mention',
        mutability: Mutabilities.SEGMENTED,
        data: { mention: { ...word, code: word.variableCode, name: word.variableCode, value: word.text } },
      };
      counter++;
    }
  }

  return entityMap;
};

export const generateStringFromTranscript = (trans: IWordData[], addVars?: boolean) => {
  let rawString = '';

  trans.forEach((word, i) => {
    rawString +=
      `${word.spaceBefore && i !== 0 ? ' ' : ''}` +
      `${addVars && word.variableCode ? word.variableCode : word.updatedText ? word.updatedText : word.text}`;
  });

  return rawString;
};

export const getAllEntitiesKeysFromSelection = (
  editorState: EditorState,
  selection: Draft.SelectionState
) => {
  return getEntitiesInRange(
    editorState.getCurrentContent().getBlockForKey(selection.getAnchorKey()),
    editorState.getCurrentContent(),
    [selection.getStartOffset(), selection.getEndOffset()]
  );
};

export const replaceSelectionWithNewText = (
  editorState: EditorState,
  selection: Draft.SelectionState,
  newText: string,
  rangeToReplace: number[],
  entityToMerge: string,
  blockKey?: string
) => {
  // const selection = editorState.getSelection();
  const blockKeyFinal = blockKey || selection.getAnchorKey();

  const contentState = editorState.getCurrentContent();

  const forcesSelection = new SelectionState({
    anchorKey: blockKeyFinal,
    anchorOffset: rangeToReplace[0],
    focusKey: blockKeyFinal,
    focusOffset: rangeToReplace[1],
    isBackward: false,
    // hasFocus: selection.getHasFocus(),
  });
  const newContentState = Modifier.replaceText(
    contentState,
    forcesSelection,
    newText,
    undefined,
    entityToMerge
  );

  const newEditorState = EditorState.push(editorState, newContentState, 'apply-entity');
  return newEditorState;
};

export const getEntitiesInRange = (
  block: Draft.ContentBlock,
  content: ContentState,
  range: number[],
  entityType = null
) => {
  const entities: ISelectedEntities[] = [];
  let selectedEntity: any = null;
  block.findEntityRanges(
    (character) => {
      if (character.getEntity() !== null) {
        const entity = content.getEntity(character.getEntity());
        if (!entityType || (entityType && entity.getType() === entityType)) {
          selectedEntity = {
            entityKey: character.getEntity(),
            blockKey: block.getKey(),
            entity: content.getEntity(character.getEntity()),
          };
          return true;
        }
      }
      return false;
    },
    (start, end) => {
      if ((start >= range[0] && start < range[1]) || (end > range[0] && end <= range[1])) {
        entities.push({ ...selectedEntity, start, end });
      } else if (range[0] > start && range[1] < end) {
        entities.push({ ...selectedEntity, start, end });
      }
    }
  );
  return entities;
};

// function to set css class for block styling
export function customBlockStyleFn(contentBlock: ContentBlock) {
  const type = contentBlock.getType();
  if (type === 'customBlock') {
    return 'editor_block_wrapper';
  }

  return '';
}

// ANOTHER OPTION TO CHECK FOR ACTIVE WORD WITHOUT RERENDERING WORD COMPONENT FROM INSIDE
//   const currentTimeRedux = useSelector<IStore, number>(
//   (state) => state.currentTime
// );

// const getCurrentWord = React.useCallback(() => {
//   const currentWord = {
//     start: "NA",
//     end: "NA"
//   };

//     const contentState = editorState.getCurrentContent();
//     // TODO: using convertToRaw here might be slowing down performance(?)
//     const contentStateConvertEdToRaw = convertToRaw(contentState);
//     const entityMap = contentStateConvertEdToRaw.entityMap;

//     for (const entityKey in entityMap) {
//       const entity = entityMap[entityKey];
//       const word = entity.data;

//       if (
//         word.start <= currentTimeRedux &&
//         word.end >= currentTimeRedux
//       ) {
//         currentWord.start = word.start;
//         currentWord.end = word.end;
//       }
//     }

//   return currentWord;
// }, [currentTimeRedux, editorState]);

// const currentWord = getCurrentWord();
// const highlightColour = "red";
// const unplayedColor = "#767676";

// helper to keep block data on split with enter

// const handleReturn = (editorState: EditorState) => {

//   const contentState = editorState.getCurrentContent();
//   const selectionState = editorState.getSelection();

//   let newContent = Modifier.splitBlock(contentState, selectionState);

//   const originalBlock = contentState.getBlockForKey(contentState.getSelectionBefore().getStartKey());
//   const originalBlockData = originalBlock.getData();
//   const preserveDataOnSplit = originalBlockData.size
//     && selectionState.getFocusOffset() === 0;

//   /**
//   /* If the user is trying to bump a block down a row that _has_ data (like
//   /* attributions) we need to manually transfer that data along with the
//   /* block split. This code can maybe be eventually deleted if draft decides to
//   /* support this use case out of the box.
//   */
//   if (preserveDataOnSplit) {
//     const newBlockKey = newContent.getSelectionAfter().getStartKey();

//     // clear data out of the current block we're moving down
//     newContent = Modifier.setBlockData(newContent, selectionState, {});

//     // create the new block and transfer data from the current block
//     const newBlockMap = newContent.getBlockMap().update(newBlockKey, contentBlock =>
//       contentBlock.set('data', originalBlockData));
//     newContent = newContent.set('blockMap', newBlockMap);

//     // note: handleChange and getNewEditorState are just my helper methods
//     // this.handleChange(getNewEditorState({
//     //   editorState,
//     //   contentState: newContent,
//     // }));

//     /**
//     /* now that the block is split and the data has been transfered
//     /* we need to force the cursor one block down. Unfortunately it looks
//     /* as though there is a limitation in editorState where we have to do
//     /* this as a subsequent change
//     */
//     const newSelection = new SelectionState({
//       anchorKey: newBlockKey,
//       anchorOffset: 0,
//       focusKey: newBlockKey,
//       focusOffset: 0,
//     });
//     setTimeout(() => {
//       this.setState({
//         editorState: EditorState.forceSelection(this.state.editorState, newSelection),
//       });
//     }, 1);

//     return 'handled';
//   }

//   return 'not-handled';
// }
