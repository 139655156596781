import React from 'react';

export function CloseBox({ fill, height, width, ...props }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '10'}
      height={props.height || '10'}
      viewBox="0 0 10 10"
    >
      <path
        id="close-box-outline"
        d="M17,8.4,13.4,12,17,15.6,15.6,17,12,13.4,8.4,17,7,15.6,10.6,12,7,8.4,8.4,7,12,10.6,15.6,7Z"
        transform="translate(-7 -7)"
        fill={props.fill || '#f2f2f2'}
      />
    </svg>
  );
}
