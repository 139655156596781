import React, { useRef, useState } from 'react';
import { PlayIcon } from '../Icons/PlayIcon';
import { PauseIcon } from '../Icons/PauseIcon';
import { DictionaryAddIcon } from '../Icons/DictionaryAddIcon';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store/IStore';
import ReplacePhraseIcon from '../Icons/ReplacePhraseIcon';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { INLINE_STYLES } from '../Editor/CustomEditor';

// import { ISpeaker } from '../Editor/IEditor';
// import AddCircleOutlineOutlined from '@material-ui/icons/AddCircleOutlineOutlined';
// import TextField from '@material-ui/core/TextField/TextField';
// import Paper from '@material-ui/core/Paper/Paper';
// import Zoom from '@material-ui/core/Zoom/Zoom';
// import MenuItem from '@material-ui/core/MenuItem/MenuItem';
// import Tooltip from '@material-ui/core/Tooltip/Tooltip';
// import ClickAwayListener from '@material-ui/core/ClickAwayListener/ClickAwayListener';
// import Popper from '@material-ui/core/Popper/Popper';

// import { useStylesEditMode } from '../Home/AddLabelsButton';
// import { defaultSpeakers } from '../Editor/Editor';
// import { setSpeakersModal } from '../../redux/actions/actions';

export enum Directions {
  DOWN = 'DOWN',
  UP = 'UP',
}

interface IProps {
  anchorRect: ClientRect;
  direction: Directions;
  toggleInlineStyles: (styleType: string) => void;
  closeSelectionPopover: () => void;
  handleShowReplaceWordsModal: () => void;
  selectedText?: string;
  handleOpenAddSpeakerModal: () => void;
  selectionPlayAudio: () => void;
  addToDictionary: (word: string | undefined) => void;
  hideDict?: boolean;
  audioIsPlaying: boolean;
}

const SelectionPopover = ({
  anchorRect,
  direction,
  selectedText,
  selectionPlayAudio,
  addToDictionary,
  hideDict,
  toggleInlineStyles,
  handleShowReplaceWordsModal,
  audioIsPlaying,
  closeSelectionPopover,
  handleOpenAddSpeakerModal,
}: IProps) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  const [popoverZIndex, setPopoverZIindex] = useState(22);
  const editorFontSize = useSelector((state: IStore) => state.fontSize);
  const fontSize = parseInt(editorFontSize.split('px')[0]);
  useOnClickOutside(popoverRef, () => {
    closeSelectionPopover();
  });

  // const classes = useStylesEditMode();

  // const [anchorElAddLabel, setAnchorElAddLabel] = useState<null | HTMLElement>(null);
  // const addButtonOpen = Boolean(anchorElAddLabel);

  // const addButtonId = addButtonOpen ? 'simple-popper' : undefined;

  // const clickAwayHandler = () => {
  //   if (anchorElAddLabel) setAnchorElAddLabel(null);
  // };

  // const openSpeakers = (event: React.MouseEvent<any>) => {
  //   if (!anchorElAddLabel) {
  //     const target = event.currentTarget;
  //     setAnchorElAddLabel(target);
  //   } else setAnchorElAddLabel(null);
  // };

  let Arrow, topPosition;
  if (direction === Directions.DOWN) {
    Arrow = (
      <div
        style={{
          width: 0,
          height: 0,
          borderRight: '12px solid transparent',
          borderLeft: '12px solid transparent',
          borderBottom: '10px solid #2B8DFF',
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          top: 'calc(1.2vw * (-1))',
        }}
      />
    );
    topPosition = window.pageYOffset + anchorRect.top + anchorRect.height * 1.5;
  } else {
    Arrow = (
      <div
        style={{
          width: 0,
          height: 0,
          borderRight: '12px solid transparent',
          borderLeft: '12px solid transparent',
          borderTop: '10px solid #2B8DFF',
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          top: '100%',
        }}
      />
    );

    const lineHeightFontSize = fontSize * 2.34;
    const diff = lineHeightFontSize - fontSize;
    topPosition = window.pageYOffset + anchorRect.top - diff / 2 + 8;
  }

  React.useEffect(() => {
    const el: HTMLDivElement | null = document.querySelector('.editor_container_main');
    if (!el) return;
    const handleScroll = (e) => {
      const { scrollTop } = e.target;

      if (scrollTop === 0 && popoverZIndex === 20) {
        setPopoverZIindex(22);
      } else if (popoverZIndex !== 20) {
        setPopoverZIindex(20);
      }
    };
    if (el) {
      el.addEventListener('scroll', handleScroll);
    }

    return () => {
      el?.removeEventListener('scroll', handleScroll);
    };
  }, [popoverZIndex]);

  const dictionaryAddEnabled =
    selectedText !== undefined && selectedText.length > 0 && selectedText.split(' ').length === 1;

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
      }}
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      ref={popoverRef}
      style={{
        position: 'absolute',
        top: topPosition,
        left: anchorRect.left + anchorRect.width / 2,
        transform: direction === Directions.DOWN ? 'translateX(-50%)' : 'translate(-50%, -150%)',
        backgroundColor: '#2B8DFF',
        borderRadius: 4,
        zIndex: popoverZIndex,
        display: 'flex',
        flexDirection: 'row',
        boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
      }}
    >
      {Arrow}

      {audioIsPlaying ? (
        <PauseIcon
          viewBox="0 0 48 48"
          onMouseDown={(e: any) => {
            e.preventDefault();
            selectionPlayAudio();
          }}
          style={{
            minHeight: 24,
            height: '2.2vw',
            maxHeight: 34,
            minWidth: 24,
            width: '2.2vw',
            maxWidth: 34,
            cursor: 'pointer',
            fill: '#F2F2F2',
            margin: 6,
          }}
        />
      ) : (
        <PlayIcon
          viewBox="0 0 48 48"
          onMouseDown={(e: any) => {
            e.preventDefault();
            selectionPlayAudio();
          }}
          style={{
            minHeight: 24,
            height: '2.2vw',
            maxHeight: 34,
            minWidth: 24,
            width: '2.2vw',
            maxWidth: 34,
            cursor: 'pointer',
            fill: '#F2F2F2',
            margin: 6,
          }}
        />
      )}

      {!hideDict && (
        <DictionaryAddIcon
          onMouseDown={() => dictionaryAddEnabled && addToDictionary(selectedText)}
          style={{
            minHeight: 24,
            height: '2.2vw',
            maxHeight: 34,
            minWidth: 24,
            width: '2.2vw',
            maxWidth: 34,
            cursor: dictionaryAddEnabled ? 'pointer' : 'not-allowed',
            fill: '#F2F2F2',
            margin: 6,
          }}
        />
      )}

      <ReplacePhraseIcon
        onMouseDown={(e: any) => {
          e.preventDefault();
          handleShowReplaceWordsModal();
        }}
        style={{
          minHeight: 24,
          height: '2.2vw',
          maxHeight: 34,
          minWidth: 24,
          width: '2.2vw',
          maxWidth: 34,
          cursor: 'pointer',
          fill: '#F2F2F2',
          margin: 6,
        }}
      />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <RecordVoiceOverIcon
          onMouseDown={(e: any) => {
            e.preventDefault();
            handleOpenAddSpeakerModal();
          }}
          // onMouseEnter={(e) => {
          //   openSpeakers(e);
          // }}
          style={{
            minHeight: 24,
            height: '2.2vw',
            maxHeight: 34,
            minWidth: 24,
            width: '2.2vw',
            maxWidth: 34,
            cursor: 'pointer',
            fill: '#F2F2F2',
            margin: 6,
          }}
        />
      </div>
      {/* <div style={{ margin: '0 8px' }} /> */}
      {INLINE_STYLES.map((type) => (
        <type.icon
          key={type.label}
          onMouseDown={(e) => {
            toggleInlineStyles(type.style);
            e.stopPropagation();
            e.preventDefault();
          }}
          style={{
            minHeight: 24,
            height: '2.2vw',
            maxHeight: 34,
            minWidth: 24,
            width: '2.2vw',
            maxWidth: 34,
            cursor: 'pointer',
            fill: '#F2F2F2',
            margin: 6,
          }}
        />
      ))}
    </div>
  );
};

export default SelectionPopover;
