const months = [
  'Januar',
  'Februar',
  'Marec',
  'April',
  'Maj',
  'Juni',
  'Julij',
  'Avgust',
  'September',
  'Oktober',
  'November',
  'December',
];

const weekdaysLong = ['Nedelja', 'Ponedeljek', 'Torek', 'Sreda', 'Cetrtek', 'Petek', 'Sobota'];

const weekdaysShort = ['Ned', 'Pon', 'Tor', 'Sre', 'Čet', 'Pet', 'Sob'];

const CalendarLocalization = { months, weekdaysLong, weekdaysShort };

export default CalendarLocalization;
