import React from 'react';
import { SpellCheckIcon } from '../Icons/SpellCheckIcon';
import '../../styles/css/header.css';
import CustomPopover from '../../shared/CustomPopover';

interface IProps {
  spellCheck: boolean;
  toggleSpellCheck: () => void;
}

export const WordConfidenceButton = ({ spellCheck, toggleSpellCheck }: IProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open: boolean = Boolean(anchorEl);

  return (
    <button
      onClick={toggleSpellCheck}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <SpellCheckIcon
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        className="icon_button"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        viewBox="0 0 48 48"
        style={{
          fill: spellCheck ? '#2B8DFF' : '#CCCCCC',
        }}
      />

      <CustomPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        width={'inherit'}
        substractScrollbar
      >
        <div className="confidence_popup_container">
          <div className="confidence_popup_title">Zaupanje</div>
          <div className="confidence_popup_values_wrapper">
            <div className="confidence_popup_value">nizko</div>
            <div className="confidence_popup_value">srednje</div>
            <div className="confidence_popup_value">visoko</div>
          </div>
        </div>
      </CustomPopover>
    </button>
  );
};
