import React from 'react';
import { SvgIcon } from '@material-ui/core/';
import './icon.css';

export function RestartIcon(props: any) {
    return (
        <SvgIcon {...props}>
            <svg width="24px" height="24px" viewBox="0 0 24 24" fillOpacity={0} xmlns="http://www.w3.org/2000/svg"><path d="M12 2.99988C16.9706 2.99988 21 7.02931 21 11.9999C21 16.9704 16.9706 20.9999 12 20.9999C7.02944 20.9999 3 16.9704 3 11.9999C3 9.17261 4.30367 6.64983 6.34267 4.99988" stroke="#292929" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/><path d="M3 4.49988H7V8.49988" stroke="#292929" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/></svg>
        </SvgIcon>
    );
}