// sort mutates original array, so we make a copy to avoid it
const asc = (arr: number[]) => [...arr].sort((a, b) => a - b);

const sum = (arr: number[]) => arr.reduce((a, b) => a + b, 0);

/**
 * Calculates the quantile of supplied array values. For example,
 * to calculate to top quartile boundary (75th percentile) of values:
 *
 *    quantile(arr, 0.75)
 */
export const quantile = (arr: number[], q: number) => {
  const sorted = asc(arr);
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
};

export const median = (arr: number[]) => quantile(arr, 0.5);

export const mean = (arr: number[]) => sum(arr) / arr.length;
