import { useCallback, useEffect, useState } from 'react';
import { ISelectionPopoverProps } from '../components/Editor/IEditor';
import { Directions } from '../components/SelectionPopover/SelectionPopover';
// import removeSpecChars from '../shared/removeSpecChars';

export default function useSelectionPopover(isActive: boolean) {
  const [selectionPopoverProps, setSelectionPopoverProps] = useState<ISelectionPopoverProps>({
    anchorRect: undefined,
    direction: Directions.DOWN,
    selectedText: undefined,
    hideDict: false,
  });

  useEffect(() => {
    handleNativeSelection();
  });

  useEffect(() => {
    if (!isActive) return;
    window.addEventListener('resize', handleNativeSelection);

    return () => {
      window.removeEventListener('resize', handleNativeSelection);
    };
  }, [isActive]);

  useEffect(() => {
    if (!selectionPopoverProps.anchorRect) return;
    const el = document.querySelector('.editor_container_main');
    if (!isActive) return;
    if (!el) return;
    el.addEventListener('scroll', handleNativeSelection);

    return () => {
      el.removeEventListener('scroll', handleNativeSelection);
    };
  }, [isActive, selectionPopoverProps.anchorRect]);

  const openSelectionPopover = (nativeSelection: Selection, hideDict?: boolean) => {
    const range = nativeSelection.getRangeAt(0);
    const anchorRect = range.getBoundingClientRect();
    if (!isSameRect(selectionPopoverProps.anchorRect, anchorRect)) {
      // const isSubstringOfOneWord = range.commonAncestorContainer.nodeName === '#text';
      // const selectedTextRaw = isSubstringOfOneWord
      //     ? range.commonAncestorContainer.textContent || ''
      //     : range.toString();
      // TO-do: Decide how we want to add words inside dict: spaces, entitities, any text?
      const selectedTextTrimmed = range.toString().trim();
      // let selectedTextWoSpecChars = removeSpecChars(selectedTextTrimmed);

      // if (
      //     selectedTextTrimmed.includes('/') ||
      //     selectedTextTrimmed.includes('-') ||
      //     selectedTextTrimmed.includes('_') ||
      //     selectedTextTrimmed.includes('%')
      // ) {
      //     selectedTextWoSpecChars = range.toString().trim();
      // }
      // console.log(selectedTextTrimmed.split(' '));

      setSelectionPopoverProps({
        anchorRect,
        direction: Directions.UP,
        selectedText: selectedTextTrimmed,
        hideDict: hideDict || selectedTextTrimmed.split(' ').length > 1,
      });
    }
  };

  const closeSelectionPopover = useCallback(() => {
    if (selectionPopoverProps.anchorRect !== undefined) {
      setSelectionPopoverProps({
        anchorRect: undefined,
        direction: Directions.DOWN,
        selectedText: undefined,
      });
    }
  }, [selectionPopoverProps, setSelectionPopoverProps]);

  const handleNativeSelection = useCallback(() => {
    if (!isActive) return;
    const nativeSelection = window.getSelection();
    if (!nativeSelection?.anchorNode || nativeSelection.isCollapsed) {
      if (selectionPopoverProps.anchorRect) {
        closeSelectionPopover();
      }
      return;
    }

    if (nativeSelection && nativeSelection.rangeCount > 0 && !nativeSelection.isCollapsed) {
      let hideDict = false;
      const range = nativeSelection ? nativeSelection.getRangeAt(0).toString() : null;

      const regex = /^[^a-zA-Z0-9]+$/;
      if (range && range.match(regex)) {
        hideDict = true;
      }

      openSelectionPopover(nativeSelection, hideDict);
    } else {
      closeSelectionPopover();
    }
  }, [openSelectionPopover, closeSelectionPopover, isActive, selectionPopoverProps.anchorRect]);

  const isSameRect = (rectOne: ClientRect | undefined, rectTwo: ClientRect | undefined): boolean => {
    return !(
      rectOne === undefined ||
      rectTwo === undefined ||
      rectOne.left !== rectTwo.left ||
      rectOne.top !== rectTwo.top ||
      rectOne.width !== rectTwo.width ||
      rectOne.height !== rectTwo.height
    );
  };

  return {
    selectionPopoverProps,
    closeSelectionPopover,
  };
}
