import { getConfiguration, getSttStatus } from "../api/configurationService";
import { SessionState } from "../components/DashboardHeader/ISearch";
import { IConfig, ISttStatus } from "../components/Home/ISettings";

export const checkIfSelectedOptionsAreAvailable = async () => {
  try {
    const [{ data: cfg }, { data: sttStatus }] = await Promise.all([getConfiguration(), getSttStatus()]);
    const { stt } = cfg;
    const { frameworks } = sttStatus;
    
    if (stt && frameworks) {
      const transcriptionFramework = stt.framework.value;
      const transcriptionLanguage = stt.language.value;
      const transcriptionDomain = stt.domain.value;
      const transcriptionModelVersion = stt.model.value;

      const frameWorkStatus = frameworks.find((f) => f.code === transcriptionFramework);
      if (!frameWorkStatus || !frameWorkStatus.isAllowed) {
        return { isAvailable: false, message: 'Izbrano ogrodje trenutno ni na voljo.' };
      }

      const langStatus = frameWorkStatus.languages.find((l) => l.code === transcriptionLanguage);
      if (!langStatus || !langStatus.isAllowed) {
        return { isAvailable: false, message: 'Izbrani jezik trenutno ni na voljo.' };
      }

      const domainStatus = langStatus.domains.find((d) => d.code === transcriptionDomain);
      if (!domainStatus || !domainStatus.isAllowed) {
        return { isAvailable: false, message: 'Izbrana domena trenutno ni na voljo.' };
      }

      const modelStatus = domainStatus.models.find((m) => m.code === transcriptionModelVersion);
      if (!modelStatus || !modelStatus.isAllowed || !modelStatus.isAvailable) {
        return { isAvailable: false, message: 'Izbrana verzija trenutno ni na voljo.' };
      }

      return { isAvailable: true, message: '' };
    } else {
      return {
        isAvailable: false,
        message: 'Preverjanje verzije oz. modela ni uspelo. Prosimo poskusite še enkrat.',
      };
    }
  } catch (err) {
    console.log(err);
    return {
      isAvailable: false,
      message: 'Preverjanje verzije oz. modela ni uspelo. Prosimo poskusite še enkrat.',
    };
  }
};

export const isRealtime = (sttStatus: ISttStatus | null, config: IConfig | null) => {
  if (sttStatus && config) {

    const frameworkIndex = sttStatus.frameworks.findIndex(framework => framework.code === config.stt.framework.value)
    if (frameworkIndex < 0) return;

    const framework = sttStatus.frameworks[frameworkIndex]
    const languageIndex = framework.languages.findIndex(language => language.code === config.stt.language.value)
    if (languageIndex < 0) return;

    const language = framework.languages[languageIndex]
    const domainIndex = language.domains.findIndex(domain => domain.code === config.stt.domain.value)
    if (domainIndex < 0) return;

    const domain = language.domains[domainIndex]

    const modelIndex = domain.models.findIndex(model => model.code === config.stt.model.value)
    if (modelIndex < 0) return;

    const model = domain.models[modelIndex]

    return model.isRealtime
  } else {
    return true;
  }
}

export const isUpdating = (sttStatus: ISttStatus | null, config: IConfig | null) => {
  if (sttStatus && config) {

    const frameworkIndex = sttStatus.frameworks.findIndex(framework => framework.code === config.stt.framework.value)
    if (frameworkIndex < 0) return;

    const framework = sttStatus.frameworks[frameworkIndex]
    const languageIndex = framework.languages.findIndex(language => language.code === config.stt.language.value)
    if (languageIndex < 0) return;

    const language = framework.languages[languageIndex]
    const domainIndex = language.domains.findIndex(domain => domain.code === config.stt.domain.value)
    if (domainIndex < 0) return;

    const domain = language.domains[domainIndex]

    const modelIndex = domain.models.findIndex(model => model.code === config.stt.model.value)
    if (modelIndex < 0) return;

    const model = domain.models[modelIndex]

    return model.isUpdating
  } else {
    return false;
  }
}

export const convertStatusToSlovenian = (status: SessionState) => {
  switch (status) {
    case SessionState.INITIALIZING: return "Inicializacija"
    case SessionState.INITIALIZED: return "Inicializirana"
    case SessionState.IN_QUEUE: return "V vrsti"
    case SessionState.IN_PROGRESS: return "V procesiranju"
    case SessionState.FINISHED: return "Zaključena"
    case SessionState.ERROR: return "Napaka"
    case SessionState.DELETED: return "Izbrisana"
    case SessionState.CANCELED: return "Prekinjena"
  }
}

export const convertStatusToOrReturnIt = (status: any) => {
  switch (status) {
    case SessionState.INITIALIZING: return "Inicializacija"
    case SessionState.INITIALIZED: return "Inicializirana"
    case SessionState.IN_QUEUE: return "V vrsti"
    case SessionState.IN_PROGRESS: return "V procesiranju"
    case SessionState.FINISHED: return "Zaključena"
    case SessionState.ERROR: return "Napaka"
    case SessionState.DELETED: return "Izbrisana"
    case SessionState.CANCELED: return "Prekinjena"
    default: return status;
  }
}