import React, { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDmodelStats,
  // getFrequencyClasses,
  getPhonems,
  getWordsAndPronaunsByText,
  IAddToDictWord,
  postWord,
} from '../../api/DictionaryService';
import Sync from '@material-ui/icons/Sync';
import useSelectedModelData from '../../hooks/useSelectedModelData';
import { setDictionary, setWordsInDict } from '../../redux/actions/actions';
import { IStore } from '../../redux/store/IStore';
import { ABIcon, BigABIcon, IconNumbers, SignsIcon, SmallABIcon, TwoFAIcon } from '../Icons/DictionaryIcons';
import OptionsLine from './OptionsLine';
import WordLine from './WordLine';
import checkPronaunciationWithPhonems from '../../shared/checkPronaunciationsWithPhonems';
import { useSnackbar } from 'notistack';

async function postWords(words: IAddToDictWord[], modelData: any) {
  return Promise.all(
    words
      .filter((w) => w.pronunciations.length)
      .map((word) =>
        postWord({
          word: {
            text: word.text,
            frequencyClassId: word.frequencyClassId || 3,
            pronunciations: word.pronunciations.map((p) => {
              return {
                text: p.text,
              };
            }),
          },
          modelData,
        })
      )
  );
}

export default function Leksicon() {
  // const [phones, setPhones] = useState<IPhonems | null>(null);
  const dispatch = useDispatch();

  const modelData = useSelectedModelData();
  const { data, error, isLoading, isFetching } = useQuery(
    'dmodel-stats',
    () => {
      return modelData && getDmodelStats(modelData);
    },
    { enabled: !!modelData }
  );

  const { data: phones } = useQuery(
    `dmodel-phones-${modelData?.language}-${modelData?.domain}-${modelData?.modelVersion}`,
    () => {
      return modelData && getPhonems(modelData);
    },
    { enabled: !!modelData }
  );

  const { enqueueSnackbar } = useSnackbar();

  const { searchedString, caseSensitive, words, autoSave, searchBy } = useSelector(
    (state: IStore) => state.dictionary
  );

  const {
    data: wordsData,
    isLoading: isLoadingWords,
    isIdle: isIdleWords,
    isError: isWordsError,
    refetch,
  } = useQuery(
    `leksicon-text-${searchedString || ''}-${caseSensitive}-${searchBy}`,
    () => {
      if (!modelData || !searchedString) return null;

      return getWordsAndPronaunsByText(searchedString, modelData, searchBy);
    },
    { enabled: !!modelData && !!searchedString }
  );

  // const { data: freqData } = useQuery(`freq-classes`, () => {
  //   return getFrequencyClasses();
  // });

  const queryClient = useQueryClient();
  const { mutate: mutateWord } = useMutation(postWord, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(`leksicon-text-${searchedString || ''}-${caseSensitive}-${searchBy}`);
      enqueueSnackbar(`Beseda uspešno posodobljena`, { variant: 'success' });
    },
    onError: (error) => {
      const errmsg = (error as any).response?.data?.message;
      queryClient.invalidateQueries(`leksicon-text-${searchedString || ''}-${caseSensitive}-${searchBy}`);
      enqueueSnackbar(`Pri posodabljanju besede se je zgodila napaka: ${errmsg || ''}`, { variant: 'error' });
    },
  });

  const handleSyncWords = async () => {
    if (!words) return;
    let isOk = true;
    let indexesOfWrong: number[] = [];
    // const isIn = freqData?.data.find((fc) => fc.id === dictionaryWord?.frequencyClassId);
    // if (!dictionaryWord?.frequencyClassId || dictionaryWord.frequencyClassId < 0 || !isIn) {
    //   enqueueSnackbar('Za uspešno shranjevanje izberite frekvenco.', { variant: 'error' });
    //   isOk = false;
    //   return;
    // }

    // if (!pronunciations || pronunciations.length === 0) {
    //   enqueueSnackbar('Za uspešno shranjevanje morate dodati vsaj 1 izgovorjavo.', { variant: 'error' });
    //   return;
    // }
    if (phones?.data) {
      words.forEach((w) => {
        w.pronunciations.forEach((p, i) => {
          if (phones?.data && p.text.length > 0) {
            if (!checkPronaunciationWithPhonems(p.text, phones.data)) {
              indexesOfWrong = [...indexesOfWrong, i];
            }
          }
          if (!p.text || p.text === '') {
            isOk = false;
          }
        });
      });
    }

    if (indexesOfWrong.length > 0) {
      // setPronIndexesFailed(indexesOfWrong);

      enqueueSnackbar('Za uspešno shranjevanje preverite izgovorjave.', {
        variant: 'error',
      });
      return;
    }

    if (!isOk || !modelData) {
      enqueueSnackbar('Za uspešno shranjevanje besede in izgovorjav izpolnite vsa polja.', {
        variant: 'error',
      });
      return;
    }

    try {
      const r = await postWords(words, modelData);
      if (r.length) {
        refetch();
        enqueueSnackbar('Besede uspešno posodobljene.', {
          variant: 'success',
        });
      }
    } catch (e) {
      enqueueSnackbar('Dodajanje v slovar trenutno ni možno zaradi napake v povezavi z zalednim sistemom.', {
        variant: 'error',
      });
      console.log(e);
    }
  };

  useEffect(() => {
    if (!autoSave) return;
    dispatch(setDictionary({ autoSave: null }));

    let isOk = true;
    let indexesOfWrong: number[] = [];
    // const isIn = freqData?.data.find((fc) => fc.id === dictionaryWord?.frequencyClassId);
    // if (!dictionaryWord?.frequencyClassId || dictionaryWord.frequencyClassId < 0 || !isIn) {
    //   enqueueSnackbar('Za uspešno shranjevanje izberite frekvenco.', { variant: 'error' });
    //   isOk = false;
    //   return;
    // }

    // if (!pronunciations || pronunciations.length === 0) {
    //   enqueueSnackbar('Za uspešno shranjevanje morate dodati vsaj 1 izgovorjavo.', { variant: 'error' });
    //   return;
    // }

    if (!autoSave.pronunciations.length || !autoSave.pronunciations) {
      enqueueSnackbar('Za uspešno shranjevanje besede mora biti izpolnjena vsaj ena izgovorjava', {
        variant: 'error',
      });
      refetch();
      return;
    }

    if (phones?.data) {
      autoSave.pronunciations.forEach((p, i) => {
        if (phones.data && p.text.length > 0) {
          if (!checkPronaunciationWithPhonems(p.text, phones.data)) {
            indexesOfWrong = [...indexesOfWrong, i];
          }
        }
        if (!p.text || p.text === '') {
          isOk = false;
        }
      });
    }

    if (indexesOfWrong.length > 0) {
      // setPronIndexesFailed(indexesOfWrong);
      enqueueSnackbar('Za uspešno shranjevanje preverite izgovorjave in uporabljene znake.', {
        variant: 'error',
      });
      refetch();
      return;
    }

    if (!isOk || !modelData) {
      enqueueSnackbar('Za uspešno shranjevanje besede in izgovorjav izpolnite vsa polja.', {
        variant: 'error',
      });
      refetch();
      return;
    }

    if (modelData) {
      mutateWord({
        word: {
          frequencyClassId: autoSave.frequencyClassId || 3,
          text: autoSave.text,
          pronunciations: autoSave.pronunciations.map((p) => {
            return {
              text: p.text,
            };
          }),
        },
        modelData,
      });
    }
  }, [autoSave, modelData, dispatch, mutateWord, phones, enqueueSnackbar, refetch]);

  useEffect(() => {
    dispatch(setWordsInDict(wordsData?.data));
  }, [wordsData, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setDictionary({ searchBy: 'word' }));
    };
  }, [dispatch]);

  return (
    <div className="dictionary_section_wrapper">
      <OptionsLine
        isLoading={false}
        titles={[
          { text: 'Leksikon' },
          { text: `${data?.data?.numPronunciations} izgovorjav` },
          { text: `${data?.data?.numWords} besed` },
        ]}
        mainCommands={[
          {
            children: <IconNumbers />,
            onClick: () => console.log('123 click'),
            disabled: true,
          },
          {
            children: <SmallABIcon />,
            onClick: () => console.log('123 click'),
            disabled: true,
          },
          {
            children: <BigABIcon />,
            onClick: () => console.log('123 click'),
            disabled: true,
          },
          {
            children: <SignsIcon />,
            onClick: () => console.log('123 click'),
            disabled: true,
          },

          {
            children: <TwoFAIcon />,
            onClick: () => console.log('123 click'),
            disabled: true,
          },
        ]}
        middleCommands={[
          {
            isOn: caseSensitive,
            children: <ABIcon />,
            onClick: () => dispatch(setDictionary({ caseSensitive: !caseSensitive })),
          },
          {
            isOn: false,
            children: <Sync className="sync_dict_leksicon" />,
            onClick: handleSyncWords,
            separate: true,
          },
        ]}
      />

      <WordLine words={words} isLoading={isLoadingWords} isError={isWordsError} isIdle={isIdleWords} />
    </div>
  );
}
