import {
  CharacterMetadata,
  ContentState,
  convertToRaw,
  DraftHandleValue,
  EditorState,
  Modifier,
  SelectionState,
} from 'draft-js';
import { useCallback } from 'react';

interface UseDraftJs {
  editorState: EditorState;
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
  hasEditorContentChanged: React.MutableRefObject<boolean>;
}

export default function useDraftJsFns({ editorState, setEditorState, hasEditorContentChanged }: UseDraftJs) {
  const onEditorChange = (newState: EditorState) => {
    // onChange() triggers for content changes, but also for selection changes,
    // focus changes, mouse input, etc. But we want to know if the actual content
    // has changed.
    if (newState.getCurrentContent() !== editorState.getCurrentContent()) {
      // References to editor content have changed, which means that actual content
      // has changed. More info: https://github.com/facebook/draft-js/issues/830
      hasEditorContentChanged.current = true;
    }
    setEditorState(newState);
  };

  const handleBeforeInput = useCallback(
    (char: string, currentState: EditorState, eventTimestamp: number): DraftHandleValue => {
      let startOfWord = false;

      const selection = currentState.getSelection();

      // No selection
      if (selection.isCollapsed()) {
        const blockKey = selection.getAnchorKey();
        const caretOffset = selection.getAnchorOffset();
        const contentState = currentState.getCurrentContent();
        const entityKey = contentState.getBlockForKey(blockKey).getEntityAt(caretOffset);
        if (entityKey) {
          // console.log('here #1');
          contentState.getBlockForKey(blockKey).findEntityRanges(
            (value: CharacterMetadata) => {
              const currentEntityKey = value.getEntity();
              if (currentEntityKey && entityKey === currentEntityKey) {
                return true;
              }
              return false;
            },
            (start: number, end: number) => {
              // Start of word
              if (caretOffset === start) {
                // console.log('here #2.1');
                startOfWord = true;
                const entityRemoveSelection = new SelectionState({
                  anchorKey: blockKey,
                  anchorOffset: start,
                  focusKey: blockKey,
                  focusOffset: end,
                  isBackward: false,
                  hasFocus: selection.getHasFocus(),
                });
                // Remove entity
                const contentStateWoEntity = Modifier.applyEntity(contentState, entityRemoveSelection, null);
                const charSelection = new SelectionState({
                  anchorKey: blockKey,
                  anchorOffset: start,
                  focusKey: blockKey,
                  focusOffset: start,
                  isBackward: false,
                  hasFocus: selection.getHasFocus(),
                });
                // Insert new char
                const contentStateWithChar = Modifier.insertText(
                  contentStateWoEntity,
                  charSelection,
                  char,
                  editorState.getCurrentInlineStyle()
                );
                const entityaddSelection = new SelectionState({
                  anchorKey: blockKey,
                  anchorOffset: start,
                  focusKey: blockKey,
                  focusOffset: end + 1,
                  isBackward: false,
                  hasFocus: selection.getHasFocus(),
                });
                // Apply entity again
                const contentStateWithEntity = Modifier.applyEntity(
                  contentStateWithChar,
                  entityaddSelection,
                  entityKey
                );
                const contentStateWithCaret = contentStateWithEntity.set(
                  'selectionAfter',
                  new SelectionState({
                    anchorKey: blockKey,
                    anchorOffset: start + 1,
                    focusKey: blockKey,
                    focusOffset: start + 1,
                    isBackward: false,
                    hasFocus: selection.getHasFocus(),
                  })
                ) as ContentState;
                // Push changes
                const editorStateWithChar = EditorState.push(
                  currentState,
                  contentStateWithCaret,
                  'apply-entity'
                );

                let newtext = contentState.getEntity(entityKey).getData().updatedText
                  ? contentState.getEntity(entityKey).getData().updatedText
                  : contentState.getEntity(entityKey).getData().text;
                newtext = char + newtext;
                contentState.mergeEntityData(entityKey, { updatedText: newtext });

                setEditorState(editorStateWithChar);
              } else {
                // console.log('here #2', contentState.getEntity(entityKey));

                const newcharpos = caretOffset - start;
                let newtext = contentState.getEntity(entityKey).getData().updatedText
                  ? contentState.getEntity(entityKey).getData().updatedText
                  : contentState.getEntity(entityKey).getData().text;
                newtext = newtext.substring(0, newcharpos) + char + newtext.substring(newcharpos);
                contentState.mergeEntityData(entityKey, { updatedText: newtext }); // a way to add data to entityMap

                // console.log(
                //   contentState.getEntity(entityKey).getData().updatedTExt,
                //   'here #2.1',
                //   newtext,
                //   start,
                //   end,
                //   caretOffset - start,
                //   caretOffset,
                //   contentState.getEntity(entityKey).getData(),
                //   char,
                //   contentState.getEntityMap(),
                //   contentState.getEntity(entityKey)
                // );
                // startOfWord = true;
                // const entityRemoveSelection = new SelectionState({
                //   anchorKey: blockKey,
                //   anchorOffset: start,
                //   focusKey: blockKey,
                //   focusOffset: end,
                //   isBackward: false,
                //   hasFocus: selection.getHasFocus(),
                // });
                // Remove entity
                // const contentStateWoEntity = Modifier.applyEntity(contentState, entityRemoveSelection, null);
                // const charSelection = new SelectionState({
                //   anchorKey: blockKey,
                //   anchorOffset: start,
                //   focusKey: blockKey,
                //   focusOffset: start,
                //   isBackward: false,
                //   hasFocus: selection.getHasFocus(),
                // });
                // Insert new char
                // const contentStateWithChar = Modifier.insertText(contentStateWoEntity, charSelection, char);
                const entityaddSelection = new SelectionState({
                  anchorKey: blockKey,
                  anchorOffset: caretOffset,
                  focusKey: blockKey,
                  focusOffset: caretOffset + 1,
                  isBackward: false,
                  hasFocus: selection.getHasFocus(),
                });
                // Apply entity again
                const contentStateWithEntity = Modifier.applyEntity(
                  contentState,
                  entityaddSelection,
                  entityKey
                );
                // const contentStateWithCaret = contentStateWithEntity.set(
                //   'selectionAfter',
                //   new SelectionState({
                //     anchorKey: blockKey,
                //     anchorOffset: start + 1,
                //     focusKey: blockKey,
                //     focusOffset: start + 1,
                //     isBackward: false,
                //     hasFocus: selection.getHasFocus(),
                //   })
                // ) as ContentState;
                // Push changes
                const editorStateWithChar = EditorState.push(
                  currentState,
                  contentStateWithEntity,
                  'apply-entity'
                );
                // console.log(convertToRaw(editorStateWithChar.getCurrentContent()), 'entity');

                setEditorState(editorStateWithChar);
              }

              // console.log(convertToRaw(editorState.getCurrentContent()));
            }
          );
        } else {
          const currBlock = contentState.getBlockForKey(blockKey);

          const selectedBlock = convertToRaw(contentState).blocks.find((b) => b.key === blockKey);
          // console.log('DIDNT FIND ENITITY KEY ---', currBlock, selectedBlock);

          if (selectedBlock?.entityRanges && selectedBlock.entityRanges.length !== 0) {
            // const correctRange = selectedBlock
            //   ? selectedBlock.entityRanges[selectedBlock.entityRanges.length - 1]
            //   : null;
            const correctRange = selectedBlock
              ? selectedBlock.entityRanges.find((er) => er.offset + er.length === caretOffset)
              : null;
            const start = correctRange ? correctRange.offset : 0;
            const end = correctRange ? correctRange.offset + correctRange.length : 0;
            const lastEntityKey = currBlock.getEntityAt(caretOffset - 1);
            // const lastEntityKeyMinus1 = currBlock.getEntityAt(caretOffset - 2);
            // const lastEntityKeyMinus2 = currBlock.getEntityAt(caretOffset - 3);

            // console.log(
            //   caretOffset,
            //   'caretOffset',
            //   currBlock.getEntityAt(caretOffset - 1),
            //   currBlock.getEntityAt(caretOffset - 2),
            //   currBlock.getEntityAt(caretOffset)
            // );

            // console.log('3.1', lastEntityKey, lastEntityKeyMinus1, lastEntityKeyMinus2);
            let specificEntity = lastEntityKey;
            // ? lastEntityKey
            // : lastEntityKeyMinus1
            // ? lastEntityKeyMinus1
            // : lastEntityKeyMinus2
            // ? lastEntityKeyMinus2
            // : lastEntityKey;

            if (specificEntity) {
              startOfWord = true;
              const entityRemoveSelection = new SelectionState({
                anchorKey: blockKey,
                anchorOffset: start,
                focusKey: blockKey,
                focusOffset: end,
                isBackward: false,
                hasFocus: selection.getHasFocus(),
              });

              // Remove entity
              const contentStateWoEntity = Modifier.applyEntity(contentState, entityRemoveSelection, null);

              const charSelection = new SelectionState({
                anchorKey: blockKey,
                anchorOffset: end,
                focusKey: blockKey,
                focusOffset: end,
                isBackward: false,
                hasFocus: selection.getHasFocus(),
              });

              // Insert new char
              const contentStateWithChar = Modifier.insertText(
                contentStateWoEntity,
                charSelection,
                char,
                editorState.getCurrentInlineStyle()
              );

              const entityaddSelection = new SelectionState({
                anchorKey: blockKey,
                anchorOffset: start,
                focusKey: blockKey,
                focusOffset: end + 1,
                isBackward: false,
                hasFocus: selection.getHasFocus(),
              });

              // Apply entity again
              const contentStateWithEntity = Modifier.applyEntity(
                contentStateWithChar,
                entityaddSelection,
                lastEntityKey
              );
              const contentStateWithCaret = contentStateWithEntity.set(
                'selectionAfter',
                new SelectionState({
                  anchorKey: blockKey,
                  anchorOffset: end + 1,
                  focusKey: blockKey,
                  focusOffset: end + 1,
                  isBackward: false,
                  hasFocus: selection.getHasFocus(),
                })
              ) as ContentState;

              const editorStateWithChar = EditorState.push(
                currentState,
                contentStateWithCaret,
                'apply-entity'
              );
              let newtext = contentState.getEntity(specificEntity).getData().updatedText
                ? contentState.getEntity(specificEntity).getData().updatedText
                : contentState.getEntity(specificEntity).getData().text;
              newtext = newtext + char;
              contentState.mergeEntityData(specificEntity, { updatedText: newtext });

              setEditorState(editorStateWithChar);
            } else {
              const currOFfset = selection.getFocusOffset();
              // const prev = currBlock.getEntityAt(currOFfset - 1);
              // const after = currBlock.getEntityAt(currOFfset + 1);

              const entityaddSelection = new SelectionState({
                anchorKey: blockKey,
                anchorOffset: currOFfset,
                focusKey: blockKey,
                focusOffset: currOFfset,
                isBackward: false,
                hasFocus: selection.getHasFocus(),
              });
              const contentStateWithChar = Modifier.insertText(
                contentState,
                entityaddSelection,
                char,
                editorState.getCurrentInlineStyle()
              );
              const newContent = contentStateWithChar.createEntity('WORD', 'MUTABLE', {
                text: '',
                updatedText: char,
              });
              const entityKey = newContent.getLastCreatedEntityKey();
              const entityaddSelectionChar = new SelectionState({
                anchorKey: blockKey,
                anchorOffset: currOFfset,
                focusKey: blockKey,
                focusOffset: currOFfset + 1,
                isBackward: false,
                hasFocus: selection.getHasFocus(),
              });
              const contentStateWithWord = Modifier.applyEntity(
                newContent,
                entityaddSelectionChar,
                entityKey
              );
              // const newEditorState = EditorState.set(editorState, {
              //   currentContent: contentStateWithLink,
              // });
              const editorStateWithChar = EditorState.push(
                currentState,
                contentStateWithWord,
                'apply-entity'
              );
              const newState = EditorState.forceSelection(
                editorStateWithChar,
                new SelectionState({
                  anchorKey: blockKey,
                  anchorOffset: currOFfset + 1,
                  focusKey: blockKey,
                  focusOffset: currOFfset + 1,
                  isBackward: false,
                  // hasFocus: selection.getHasFocus(),
                })
              );

              // contentState.mergeEntityData(entityKey, { startTime:  });
              // const newState1 = EditorState.createWithContent(newState.getCurrentContent(), decorator);
              // var newEditorState = EditorState.createWithContent(newState.getCurrentContent(), decorator);

              setEditorState(newState);

              // const contentState1 = newState.getCurrentContent();
              // const decorator = newState.getDecorator();
              // const newState1 = EditorState.createWithContent(contentState1, decorator);
              // const newEditorState1 = EditorState.push(newState1, contentState, 'apply-entity');
              // setEditorState(newEditorState1);

              hasEditorContentChanged.current = true;
              return 'handled';
            }

            //         contentState.createEntity(
            //           type: DraftEntityType,
            // mutability: DraftEntityMutability,
            // data?: Object
            //         )

            // setEditorState(editorStateWithChar);
          } else {
          }
        }
      }

      if (startOfWord) {
        hasEditorContentChanged.current = true;
        return 'handled';
      }

      return 'not-handled';
    },
    [editorState, setEditorState]
  );

  const handlePastedText = useCallback(
    (text: string, html: string | undefined, editorState: EditorState): DraftHandleValue => {
      const selection = editorState.getSelection();
      if (!selection.isCollapsed()) return 'not-handled';

      const blockKey = selection.getAnchorKey();
      const caretOffset = selection.getStartOffset();
      const contentState = editorState.getCurrentContent();

      const entityKey = contentState.getBlockForKey(blockKey).getEntityAt(caretOffset);
      const prevEntityKey = contentState.getBlockForKey(blockKey).getEntityAt(caretOffset - 1);

      if (entityKey && !prevEntityKey) {
        //append to current entity to start

        const contentStateWithChar = Modifier.insertText(
          contentState,
          selection,
          text,
          editorState.getCurrentInlineStyle()
        );
        const entityaddSelection = new SelectionState({
          anchorKey: blockKey,
          anchorOffset: selection.getStartOffset(),
          focusKey: blockKey,
          focusOffset: selection.getStartOffset() + text.length,
          isBackward: false,
          // hasFocus: selection.getHasFocus(),
        });

        const contentStateWithEntity = Modifier.applyEntity(
          contentStateWithChar,
          entityaddSelection,
          entityKey
        );

        const editorStateWithChar = EditorState.push(editorState, contentStateWithEntity, 'apply-entity');
        const newState = EditorState.forceSelection(
          editorStateWithChar,
          new SelectionState({
            anchorKey: blockKey,
            anchorOffset: selection.getStartOffset() + text.length,
            focusKey: blockKey,
            focusOffset: selection.getStartOffset() + text.length,
            isBackward: false,
            // hasFocus: selection.getHasFocus(),
          })
        );
        const entityData = editorState.getCurrentContent().getEntity(entityKey).getData();
        contentStateWithEntity.mergeEntityData(entityKey, {
          updatedText: entityData.updatedText ? text + entityData.updatedText : text + entityData.text,
        });

        setEditorState(newState);

        hasEditorContentChanged.current = true;
        return 'handled';
      } else if (entityKey) {
        return 'not-handled';
      } else if (!entityKey && prevEntityKey) {
        const contentStateWithChar = Modifier.insertText(
          contentState,
          selection,
          text,
          editorState.getCurrentInlineStyle()
        );
        const entityaddSelection = new SelectionState({
          anchorKey: blockKey,
          anchorOffset: selection.getStartOffset(),
          focusKey: blockKey,
          focusOffset: selection.getStartOffset() + text.length,
          isBackward: false,
          // hasFocus: selection.getHasFocus(),
        });

        const contentStateWithEntity = Modifier.applyEntity(
          contentStateWithChar,
          entityaddSelection,
          prevEntityKey
        );

        const editorStateWithChar = EditorState.push(editorState, contentStateWithEntity, 'apply-entity');
        const newState = EditorState.forceSelection(
          editorStateWithChar,
          new SelectionState({
            anchorKey: blockKey,
            anchorOffset: selection.getStartOffset() + text.length,
            focusKey: blockKey,
            focusOffset: selection.getStartOffset() + text.length,
            isBackward: false,
            // hasFocus: selection.getHasFocus(),
          })
        );
        const entityData = editorState.getCurrentContent().getEntity(prevEntityKey).getData();
        contentStateWithEntity.mergeEntityData(prevEntityKey, {
          updatedText: entityData.updatedText ? entityData.updatedText + text : entityData.text + text,
        });

        setEditorState(newState);

        hasEditorContentChanged.current = true;
        return 'handled';
      } else if (!entityKey) return 'not-handled';

      hasEditorContentChanged.current = true;
      return 'handled';
    },
    [setEditorState, editorState]
  );

  return {
    onEditorChange,
    handleBeforeInput,
    handlePastedText,
  };
}
