import axiosInstance from './axios';
import { AxiosResponse } from 'axios';

import { API, CLIENT } from './endpoints';
import { ISpeaker } from '../components/Editor/IEditor';
import { config } from '../config';

const backendUrl: string = config.backendApiUrl;
const baseUrl = `${backendUrl}/${API}/${CLIENT}/speakers`;

export const getSpeakers = (search?: string, limit?: number): Promise<AxiosResponse<any>> => {
  return axiosInstance.get(`${baseUrl}?search=${search || ''}&limit=${limit || 8}`);
};

export const getSpeaker = (id: number): Promise<AxiosResponse<any>> => {
  return axiosInstance.get(`${baseUrl}/${id}`);
};

export const deleteSpeaker = (id: number): Promise<AxiosResponse<any>> => {
  return axiosInstance.delete(`${baseUrl}/${id}`);
};

export const postNewSpeaker = (data: {
  speaker: Partial<ISpeaker>;
  updateLastSelectedAt?: boolean;
}): Promise<AxiosResponse<any>> => {
  const { speaker, updateLastSelectedAt } = data;
  return axiosInstance.post(
    baseUrl,
    {
      ...speaker,
      updateLastSelectedAt: updateLastSelectedAt || false,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const patchSpeaker = (data: {
  speaker: Partial<ISpeaker>;
  id: number;
  updateLastSelectedAt?: boolean;
}): Promise<AxiosResponse<any>> => {
  const { speaker, id, updateLastSelectedAt } = data;
  return axiosInstance.patch(
    `${baseUrl}/${id}`,
    {
      ...speaker,
      updateLastSelectedAt: updateLastSelectedAt || false,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};
