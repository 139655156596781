import React from 'react';
import { CssBaseline } from '@material-ui/core';
import Routes from '../Routes/Routes';
import { mainRoutes } from '../Routes/mainRoutes';

const Layout = () => {
  return (
    <div style={{ display: 'flex' }}>
      <CssBaseline />

      <main style={{ flexGrow: 1, width: '100%' }}>
        <Routes routes={mainRoutes} />
      </main>
    </div>
  );
};

export default Layout;
