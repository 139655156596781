import React from 'react';

interface Props {
  onClick: (e: any) => void;
  children: any;
  isOn?: boolean;
  className?: string;
  disabled?: boolean;
  isPronaunHelper?: boolean;
}

export default function CommandButton({
  onClick,
  children,
  isPronaunHelper,
  isOn,
  className,
  disabled,
}: Props) {
  return (
    <button
      className={`main_command_button${className ? ` ${className}` : ''}${isOn ? ' on' : ''}`}
      onClick={onClick}
      onMouseDown={(e) => {
        isPronaunHelper && e.preventDefault();
      }}
      disabled={disabled}
      style={disabled ? { opacity: 0.4, pointerEvents: 'none', touchAction: 'none' } : {}}
    >
      {children}
    </button>
  );
}
