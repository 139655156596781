/* eslint-disable no-unreachable */
import { IReceivedTranscript, ReceivedMessageTypes } from '../components/Libraries/ILibraries';
import {
  Commands,
  EntryVariables,
  ICursor,
  IFinal,
  IFinalBlock,
  ITranscriptWord,
  IWordData,
  IWordDataLive,
  LiveOptions,
  MergeNewTrToLWDOptions,
} from '../components/Editor/IEditor';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import {
  decorator,
  generateEntityMap,
  generateEntityMapForEntryVars,
  generateEntityRanges,
  generateEntityRangesForEntryVars,
  generateInlineStyleRanges,
  generateStringFromTranscript,
} from '../components/Editor/helpers/EditorHelpers';
import { cloneDeep } from 'lodash';
import { CursorTypesEnum } from '../components/Editor/LastPositionCursor';
import {
  allSubsString,
  cArr,
  cArrS,
  mainModeCommandsStrings,
  hardSubs,
} from '../components/Editor/constants';
import { isNumericRegex } from '../components/Editor/helpers/commandModesHelpers';
import { quantile } from './mathUtil';

export const capitalize = (s: any) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const lowerCaseFirst = (s: any) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toLowerCase() + s.slice(1);
};

export function removeNWords(words: string, n: number) {
  // console.log('from remove n words', words.split(' '), n, words);
  return n === 0 ? words : words.split(' ').slice(0, -n).join(' ');
}

export function stringOnlyContainsWhiteSpaces(s: string) {
  if (!s.replace(/\s/g, '').length) return true;

  return false;
}

export const convertReceivedTranscriptToWordDataLiveNew = (
  transcript: IReceivedTranscript,
  currentLiveWordData: IWordDataLive,
  liveOptions: LiveOptions,
  changeCursor?: (cursorType: CursorTypesEnum) => void
) => {
  const {
    postProcessingLive,
    startWithCapital,
    upperCaseAllNext,
    lowerCaseAllNext,
    capitalizeNextWord: capNext,
    isFromUpload,
    capitalizeNextWordCommandPrev,
    isNewRecordingSession,
    isInFindMode,
    cursorPosition,
    boldNext,
    boldOn,
    underlineNext,
    underlineOn,
    italicNext,
    italicOn,
  } = liveOptions;

  const isFinal = transcript.isFinal;
  const finalId = transcript.transcript.id;

  let goToFixMode = false;
  let goToSpellMode = false;
  let goToInsMode = false;
  let goToNextInput = false;
  let goToPrevInput = false;
  let goToInput = false;
  let goToInputIndex = 0;
  let numberOfWordsInFixMode = 1;
  let goToFindMode = isInFindMode ? isInFindMode : false;
  let textToSearchInFindMode = '';
  let fixModeWordIndexes: number[] = [-1, -1];

  let startWithCapitalFromPrev = startWithCapital;
  let capitalizeNextWord = capNext;
  let capitalizeNextWordCommand = capitalizeNextWordCommandPrev;
  let capitalizeNextWordCommandInterim = capitalizeNextWordCommandPrev;
  let upperCaseAll = upperCaseAllNext;
  let lowerCaseAll = lowerCaseAllNext;
  let isBoldOn = boldOn;
  let isItalicOn = italicOn;
  let isUnderlineOn = underlineOn;
  let isBoldNext = boldNext;
  let isItalicNext = italicNext;
  let isUnderlineNext = underlineNext;
  let dontAddNextWord = false;
  let newCursorPosition: number[] | null = null;
  let processedTr: IWordData[] = [];
  let newWordData: ITranscriptWord[] = [];

  let deleteParagraph = false;

  let deleteLastWordInPrevFinals = false;
  let deleteLastWordInPrevFinalsCounter = 0;

  let deleteToLastDotInPrevFinals = false;
  let deleteToLastDotInPrevFinalsCounter = 0;

  let transcriptToReturn: IWordData[] = [];
  let newLiveWorDataToReturn: IWordDataLive = cloneDeep(currentLiveWordData);

  if (typeof transcript.transcript.content === 'string') {
    processedTr = JSON.parse(transcript.transcript.content);
    newWordData = JSON.parse(transcript.transcript.content);
  } else {
    processedTr = transcript.transcript.content as ITranscriptWord[];
    newWordData = transcript.transcript.content as ITranscriptWord[];
  }

  newWordData.forEach((word, i) => {
    const { text } = word;

    let wordToAdd = { ...word };
    let addThisWord = true;

    const isCommandOrSub = text.startsWith('<') && text.endsWith('>');
    const isCommand = mainModeCommandsStrings.includes(text) || allSubsString.includes(text);
    const removeWord = isCommandOrSub && !isCommand;

    if (!dontAddNextWord) {
      if (dontAddNextWord) {
        dontAddNextWord = false;
      }
      if (postProcessingLive) {
        if (dontAddNextWord) {
          dontAddNextWord = false;
        }
        // if prev word in final was FIX then check next one for numof word to fix
        if (goToFixMode && !isFromUpload) {
          if (isFinal && isNumericRegex(text) && numberOfWordsInFixMode === 1) {
            numberOfWordsInFixMode = parseInt(text.charAt(0));
          }
        } else if (goToFindMode && !isFromUpload && !textToSearchInFindMode) {
          if (isFinal) {
            textToSearchInFindMode = text;
            // goToFindMode = false;
          }
        } else if (!removeWord) {
          if (text === Commands.NEW_PARAGRAPH || text === Commands.NEW_LINE) {
            capitalizeNextWord = true;
          } else if (text === Commands.DELETE_SENTENCE) {
            addThisWord = false;

            if (isFinal) {
              let found = false;
              let counter = 0;
              for (let j = transcriptToReturn.length - 1; j >= 0; j--) {
                counter += 1;
                if (transcriptToReturn[j].text === '.') {
                  found = true;
                  break;
                }
              }

              if (found) {
                transcriptToReturn.splice(-1, counter);
              } else {
                deleteToLastDotInPrevFinals = true;
                deleteToLastDotInPrevFinalsCounter = +1;
              }
            }
          } else if (text === Commands.DELETE_PARAGRAPH) {
            addThisWord = false;

            if (isFinal) {
              deleteParagraph = true;
            }
          } else if (text === Commands.DELETE_WORD) {
            addThisWord = false;
            if (isFinal) {
              const lastEl = transcriptToReturn[transcriptToReturn.length - 1];
              const nextEl = newWordData[i + 1];

              if (nextEl && isNumericRegex(nextEl.text)) {
                deleteLastWordInPrevFinalsCounter = parseInt(nextEl.text);
                dontAddNextWord = true;
              } else if (
                lastEl &&
                (lastEl.text === Commands.NEW_PARAGRAPH || lastEl.text === Commands.NEW_LINE)
              ) {
              } else if (lastEl) {
                transcriptToReturn.splice(-1, 1);
              } else {
                deleteLastWordInPrevFinals = true;
                deleteLastWordInPrevFinalsCounter += 1;
                // deleteLastWordInPrevFinalsCounter = 4;
              }
            }
          } else if (text === Commands.CAPITALIZE_NEXT) {
            capitalizeNextWord = true;
            capitalizeNextWordCommand = true;
            capitalizeNextWordCommandInterim = true;
            addThisWord = false;
            // console.log('found capitalize next command');

            changeCursor && changeCursor(CursorTypesEnum.UPPER_CASE_FIRST);
          } else if (text === Commands.UPPER_CASE) {
            // console.log('found capitalize all command');
            if (isFinal) {
              upperCaseAll = true;
              lowerCaseAll = false;

              changeCursor && changeCursor(CursorTypesEnum.UPPER_CASE_ALL);
            }
            addThisWord = false;
          } else if (text === Commands.END_UPPER_CASE) {
            // console.log('found  end capitalize all command');
            if (isFinal) {
              upperCaseAll = false;
            }
            addThisWord = false;
          } else if (text === Commands.LOWER_CASE) {
            // console.log('found   lower case all command');
            if (isFinal) {
              lowerCaseAll = true;
              upperCaseAll = false;
            }

            changeCursor && changeCursor(CursorTypesEnum.LOWER_CASE_ALL);
            addThisWord = false;
          } else if (text === Commands.END_LOWER_CASE) {
            // console.log('found end  lower case all command');
            if (isFinal) {
              lowerCaseAll = false;
            }
            addThisWord = false;
          } else if (text === Commands.BOLD_NEXT) {
            if (isFinal) {
              isBoldNext = true;
            }
            addThisWord = false;
          } else if (text === Commands.BOLD_ON) {
            if (isFinal) {
              isBoldOn = true;
            }
            addThisWord = false;
          } else if (text === Commands.BOLD_OFF) {
            if (isFinal) {
              isBoldOn = false;
            }
            addThisWord = false;
          } else if (text === Commands.ITALIC_ON) {
            if (isFinal) {
              isItalicOn = true;
            }
            addThisWord = false;
          } else if (text === Commands.ITALIC_OFF) {
            if (isFinal) {
              isItalicOn = false;
            }
            addThisWord = false;
          } else if (text === Commands.ITALIC_NEXT) {
            if (isFinal) {
              isItalicNext = true;
            }
            addThisWord = false;
          } else if (text === Commands.UNDERLINE_NEXT) {
            if (isFinal) {
              isUnderlineNext = true;
            }
            addThisWord = false;
          } else if (text === Commands.UNDERLINE_OFF) {
            if (isFinal) {
              isUnderlineOn = false;
            }
            addThisWord = false;
          } else if (text === Commands.UNDERLINE_ON) {
            if (isFinal) {
              isUnderlineOn = true;
            }
            addThisWord = false;
          } else if (text === Commands.FIX) {
            addThisWord = false;
            if (isFinal && !isFromUpload) {
              goToFixMode = true;
              if (transcriptToReturn.length > 0) {
                // transcriptToReturn[transcriptToReturn.length - 1].isSelected = true;

                if (newLiveWorDataToReturn.finalsBlocks.length > 0) {
                  const lastBlockI = newLiveWorDataToReturn.finalsBlocks.length - 1;
                  const lastWordInLastBlockI =
                    newLiveWorDataToReturn.finalsBlocks[lastBlockI].words.length - 1;
                  fixModeWordIndexes = [
                    lastWordInLastBlockI,
                    lastWordInLastBlockI + transcriptToReturn.length - 1,
                  ];
                } else {
                  const lastWordInLastBlockI = transcriptToReturn.length - 1;
                  fixModeWordIndexes = [0, lastWordInLastBlockI];
                }
              } else if (
                newLiveWorDataToReturn.finalsBlocks[newLiveWorDataToReturn.finalsBlocks.length - 1]
              ) {
                const lastBlockI = newLiveWorDataToReturn.finalsBlocks.length - 1;
                const lastWordInLastBlockI = newLiveWorDataToReturn.finalsBlocks[lastBlockI].words.length - 1;

                if (newLiveWorDataToReturn.finalsBlocks[lastBlockI].words.length > 0) {
                  fixModeWordIndexes = [lastWordInLastBlockI, lastWordInLastBlockI];
                  newLiveWorDataToReturn.finalsBlocks[lastBlockI].words[lastWordInLastBlockI].isSelected =
                    true;
                }
              }
            }
          } else if (text === Commands.FIND_WORD) {
            addThisWord = false;

            if (isFinal && !isFromUpload) {
              goToFindMode = true;
            }
          } else if (text === Commands.SPELL) {
            addThisWord = false;
            if (isFinal && !isFromUpload) {
              goToSpellMode = true;
            }
          } else if (text === Commands.INSERT_ENTRY) {
            addThisWord = false;
            if (isFinal && !isFromUpload) {
              goToInsMode = true;
            }
          } else if (text === Commands.NEXT) {
            addThisWord = false;
            if (isFinal) {
              goToNextInput = true;
            }
          } else if (text === Commands.PREV) {
            addThisWord = false;
            if (isFinal) {
              goToPrevInput = true;
            }
          } else if (text === Commands.INPUT) {
            addThisWord = false;
            if (isFinal) {
              goToInput = true;
            }
          } else if (goToInput) {
            const value = parseInt(text);
            if (!isNaN(value)) {
              addThisWord = false;
              goToInputIndex = value;
            }
          }

          if (addThisWord) {
            if (i === 0 && isNewRecordingSession) {
              const lastPI =
                newLiveWorDataToReturn.finalsBlocks.length > 0
                  ? newLiveWorDataToReturn.finalsBlocks.length - 1
                  : null;
              if (lastPI !== null && lastPI >= 0) {
                const lastWI =
                  newLiveWorDataToReturn.finalsBlocks[lastPI].words.length > 0
                    ? newLiveWorDataToReturn.finalsBlocks[lastPI].words.length - 1
                    : null;

                if (lastWI !== null && lastWI >= 0) {
                  let w = newLiveWorDataToReturn.finalsBlocks[lastPI].words[lastWI];

                  if (cArr.includes(w.text)) {
                    wordToAdd.text = capitalize(word.text);
                  } else {
                    if (wordToAdd.metadata?.postCapitalized) {
                      wordToAdd.text = lowerCaseFirst(word.text);
                    }
                  }

                  wordToAdd.spaceBefore = true;

                  for (const sub of hardSubs) {
                    if (
                      (wordToAdd.text === sub.to && sub.leftHanded) ||
                      (w.text === sub.to && sub.rightHanded)
                    ) {
                      wordToAdd.spaceBefore = false;
                      break;
                    }
                  }
                }
              }
            }

            if ((capitalizeNextWord || startWithCapitalFromPrev || capitalizeNextWordCommand) && !isCommand) {
              // console.log('From capitalize enxt word - will cap word:', wordToAdd);
              if (isFinal) {
                wordToAdd.text = capitalize(word.text);
              } else {
                // wordToAdd.text = capitalize(word.text);
                // capitalizeNextWordInterim = false;
              }

              if (isFinal) {
                capitalizeNextWord = false;
              }
              if (startWithCapitalFromPrev && isFinal) {
                startWithCapitalFromPrev = false;
              }
              if (capitalizeNextWordCommand && isFinal) {
                capitalizeNextWordCommand = false;
              }
            }
            if (upperCaseAll && !isCommand) {
              wordToAdd.text = word.text.toUpperCase();
            }

            if (lowerCaseAll && !isCommand) {
              wordToAdd.text = word.text.toLowerCase();
            }

            if (!isCommand) {
              const isB = isBoldNext || isBoldOn ? ['BOLD'] : [];
              const isU = isUnderlineNext || isUnderlineOn ? ['UNDERLINE'] : [];
              const isI = isItalicNext || isItalicOn ? ['ITALIC'] : [];
              const curr = wordToAdd.inlineStyles ? [...wordToAdd.inlineStyles] : [];
              wordToAdd.inlineStyles = [...(curr && curr), ...(isB && isB), ...(isU && isU), ...(isI && isI)];

              if (isBoldNext && isFinal) {
                isBoldNext = false;
              }
              if (isItalicNext && isFinal) {
                isItalicNext = false;
              }
              if (isUnderlineNext && isFinal) {
                isUnderlineNext = false;
              }
            }

            transcriptToReturn.push({
              ...wordToAdd,
              firstInRecordingSession: i === 0 && isNewRecordingSession,
              id: finalId,
            });
          }

          if (!capitalizeNextWordCommand && !upperCaseAll && !lowerCaseAll) {
            changeCursor && changeCursor(CursorTypesEnum.NORMAL);
          }
        }
      } else {
        if (isCommandOrSub) {
          // do nothing
        } else {
          transcriptToReturn.push(wordToAdd);
        }
      }
    }
  });

  if (isFinal) {
    const { data, newCursorPos } = mergeNewWordDataToLiveWordDataParagraphs(
      currentLiveWordData.finalsBlocks,
      transcriptToReturn,
      { deleteToLastDotInPrevFinals, deleteLastWordInPrevFinalsCounter, cursorPosition, deleteParagraph }
    );
    if (newCursorPos) {
      newCursorPosition = newCursorPos;
    }
    newLiveWorDataToReturn.finalsBlocks = data;
    newLiveWorDataToReturn.lastInterim = '';
  } else {
    newLiveWorDataToReturn.lastInterim = generateNewLiveInterimString(
      transcriptToReturn,
      newLiveWorDataToReturn.finalsBlocks.length === 0 ||
        newLiveWorDataToReturn.finalsBlocks[newLiveWorDataToReturn.finalsBlocks.length - 1].words.length === 0
    );
  }

  return {
    newWordData: transcriptToReturn,
    newLiveWordData: newLiveWorDataToReturn,
    capitalizeNextWord,
    capitalizeNextWordCommand,
    upperCaseAll,
    lowerCaseAll,
    deleteLastWordInPrevFinals,
    deleteLastWordInPrevFinalsCounter,
    deleteToLastDotInPrevFinals,
    deleteToLastDotInPrevFinalsCounter,
    deleteParagraph,
    goToFixMode,
    goToSpellMode,
    goToFindMode,
    goToInsMode,
    isBoldNext,
    isBoldOn,
    isItalicNext,
    isItalicOn,
    isUnderlineNext,
    isUnderlineOn,
    textToSearchInFindMode,
    fixModeWordIndexes,
    numberOfWordsInFixMode,
    newCursorPosition,
    goToNextInput,
    goToPrevInput,
    goToInput,
    goToInputIndex,
  };
};

export const generateNewLiveInterimString = (t: IWordData[], isNewLine: boolean) => {
  let newString = '';

  t.forEach((w, i) => {
    if (!mainModeCommandsStrings.includes(w.text)) {
      if (isNewLine && i === 0) {
        newString += w.text;
      } else if (w.spaceBefore) {
        newString += ' ' + w.text;
      } else {
        newString += w.text;
      }
    }
  });

  return newString;
};

/**
 * When we are merging entries/templates we want to insert them into existing transcription.
 * There are two main cases:
 *
 *  1. cursor is not defined: entry is simply placed to the end of the transcription.
 *  2. cursor is defined:
 *     a) single-line entry is inserted inline at cursor position,
 *     b) multi-line entry is placed at cursor position, but with empty line before & after.
 */
export const mergeEntryToLiveWordDataParagraphs = (
  currentLiveWordDataFinals: IFinalBlock[],
  entry: IWordData[],
  cursorPosition: number[] | null
) => {
  let newLiveWordDataParagraphs = generateParagraphsFromWordData(entry);

  if (cursorPosition) {
    const isMultiline = !!entry.find(
      (w) => w.text === Commands.NEW_PARAGRAPH || w.text === Commands.NEW_LINE
    );
    const [line, word] = cursorPosition;
    const { words, speaker } = currentLiveWordDataFinals[line];

    const wordsBeforeCursor = words.slice(0, word + 1);
    const wordsAfterCursor = words.slice(word + 1);

    const isInInput =
      wordsBeforeCursor[wordsBeforeCursor.length - 1]?.isInputWord && wordsAfterCursor[0]?.isInputWord;
    if (isInInput) {
      newLiveWordDataParagraphs = newLiveWordDataParagraphs.map((block) => ({
        ...block,
        words: block.words.map((word) => ({
          ...word,
          isInputWord: true,
          inlineStyles: [...(wordsAfterCursor[0].inlineStyles || [])],
        })),
      }));
    }

    let newCursor: number[];

    // Remove the existing line ...
    currentLiveWordDataFinals.splice(line, 1);

    if (isMultiline) {
      // ... and replace it with:
      //  1. words up to cursor position,
      //  2. entry paragraphs
      //  3. words from cursor position to end of the line
      currentLiveWordDataFinals.splice(
        line,
        0,
        { speaker, words: wordsBeforeCursor },
        { speaker, words: [] },
        ...newLiveWordDataParagraphs,
        { speaker, words: [] },
        { speaker, words: wordsAfterCursor }
      );

      newCursor = [line + newLiveWordDataParagraphs.length + 2, -1];
    } else {
      // However, we insert single line entries inline.
      currentLiveWordDataFinals.splice(line, 0, {
        speaker,
        words: [...wordsBeforeCursor, ...newLiveWordDataParagraphs[0].words, ...wordsAfterCursor],
      });

      newCursor = [line, word + newLiveWordDataParagraphs[0].words.length];
    }

    return { data: currentLiveWordDataFinals, newCursorPos: newCursor };
  }

  const data =
    currentLiveWordDataFinals.length === 1 && currentLiveWordDataFinals[0].words.length < 1
      ? newLiveWordDataParagraphs
      : [...currentLiveWordDataFinals, ...newLiveWordDataParagraphs];

  return { data, newCursorPos: null };
};

const deleteWords = (currentLiveWordDataFinals: IFinalBlock[], count: number, line: number, word: number) => {
  let remaining = count;
  let lineIndex = line;
  let wordIndex = word;
  while (remaining > 0 && lineIndex >= 0) {
    remaining--;
    if (wordIndex >= 0) {
      currentLiveWordDataFinals[lineIndex].words.splice(wordIndex, 1);
      wordIndex--;
      continue;
    }

    // We've reached the start of the line, so we need to join current line
    // with the one above.
    lineIndex--;
    if (lineIndex < 0) {
      break;
    }

    wordIndex = currentLiveWordDataFinals[lineIndex].words.length - 1;

    // Then join the words from next line to current line
    currentLiveWordDataFinals[lineIndex].words.push(...currentLiveWordDataFinals[lineIndex + 1].words);
    // ... and remove the next line.
    currentLiveWordDataFinals.splice(lineIndex + 1, 1);
  }

  return [lineIndex, wordIndex];
};

export const mergeNewWordDataToLiveWordDataParagraphs = (
  currentLiveWordDataFinals: IFinalBlock[],
  newWordData: IWordData[],
  options: MergeNewTrToLWDOptions
) => {
  const { deleteLastWordInPrevFinalsCounter, deleteToLastDotInPrevFinals, cursorPosition, deleteParagraph } =
    options;

  let newLiveWordDataParagraphs = generateParagraphsFromWordData(newWordData);
  // console.log('new paragraphs:', generateParagraphsFromWordData(newWordData));

  if (deleteParagraph && !cursorPosition) {
    if (newLiveWordDataParagraphs.length) {
      newLiveWordDataParagraphs.splice(-1);
    } else {
      currentLiveWordDataFinals.splice(-1);
    }
    return { data: [...currentLiveWordDataFinals, ...newLiveWordDataParagraphs], newCursorPos: null };
  }

  if (
    currentLiveWordDataFinals.length > 0 &&
    deleteLastWordInPrevFinalsCounter &&
    deleteLastWordInPrevFinalsCounter > 0 &&
    !cursorPosition
  ) {
    const line = currentLiveWordDataFinals.length - 1;
    const word = currentLiveWordDataFinals[line].words.length - 1;
    deleteWords(currentLiveWordDataFinals, deleteLastWordInPrevFinalsCounter, line, word);
  }

  if (deleteToLastDotInPrevFinals && currentLiveWordDataFinals.length > 0 && !cursorPosition) {
    let foundDot = false;
    let pToDelete = 0;
    let wordsToDelete = 0;
    let isFirst = true;
    loop_1: for (let i = currentLiveWordDataFinals.length - 1; i >= 0; i--) {
      for (let j = currentLiveWordDataFinals[i].words.length - 1; j >= 0; j--) {
        if (currentLiveWordDataFinals[i].words[j] === undefined) {
          break loop_1;
        }
        const wordText =
          typeof currentLiveWordDataFinals[i].words[j].updatedText === 'string'
            ? (currentLiveWordDataFinals[i].words[j].updatedText as string)
            : currentLiveWordDataFinals[i].words[j].text;
        if (
          (cArr.includes(wordText) || cArrS.includes(wordText)) &&
          !(i === currentLiveWordDataFinals.length - 1 && j === currentLiveWordDataFinals[i].words.length - 1)
        ) {
          foundDot = true;
          break loop_1;
        }

        wordsToDelete += 1;
      }

      pToDelete += 1;
      wordsToDelete = 0;
    }

    if (pToDelete > 0) {
      currentLiveWordDataFinals.splice(-pToDelete, pToDelete);
      if (currentLiveWordDataFinals.length > 0) {
        currentLiveWordDataFinals[currentLiveWordDataFinals.length - 1].words.splice(
          -wordsToDelete,
          wordsToDelete
        );
      }
    } else if (pToDelete === currentLiveWordDataFinals.length) {
      return { data: newLiveWordDataParagraphs, cursorPos: null };
    } else {
      currentLiveWordDataFinals[currentLiveWordDataFinals.length - 1].words.splice(
        -wordsToDelete,
        wordsToDelete
      );
    }
  }

  if (cursorPosition) {
    let interCurPos = [...cursorPosition];

    if (deleteParagraph) {
    } else if (deleteLastWordInPrevFinalsCounter && deleteLastWordInPrevFinalsCounter > 0) {
      interCurPos = deleteWords(
        currentLiveWordDataFinals,
        deleteLastWordInPrevFinalsCounter,
        interCurPos[0],
        interCurPos[1]
      );
    }

    newLiveWordDataParagraphs.forEach((p) => {
      const [line, word] = interCurPos;
      const currentWords = currentLiveWordDataFinals[line].words;
      if (p.words.length === 0) {
        // empty/new line handling
        const newArr1 = currentWords.slice(0, word + 1);
        const newArr2 = currentWords.slice(word + 1);
        const speakerToAdd = currentLiveWordDataFinals[line].speaker;

        // Remove the existing line ...
        currentLiveWordDataFinals.splice(line, 1);

        // ... and replace it with two lines:
        //  1. words up to cursor pos,
        //  2. words from cursor position to end of the line
        currentLiveWordDataFinals.splice(
          line,
          0,
          { speaker: speakerToAdd, words: newArr1 },
          { speaker: speakerToAdd, words: newArr2 }
        );

        interCurPos = [line + 1, -1];
      } else {
        // If previous and next word have sticky styles, then we apply
        // the same styles to new words as well. This is useful for highlighting in
        // {input}s.
        // Previous word lookup is a bit tricky, because it may be on the previous line/paragraph,
        // if we are at the beginning of the line.
        const previousWords =
          currentLiveWordDataFinals[line - 1]?.words?.length > 0
            ? currentLiveWordDataFinals[line - 1]?.words
            : currentLiveWordDataFinals[line - 2]?.words;

        const prevWord = currentWords[word] || previousWords?.[previousWords.length - 1];
        const newWords =
          prevWord?.stickyInlineStyles && currentWords[word + 1]?.stickyInlineStyles
            ? p.words.map((w, i) => {
                let spaceBefore = w.spaceBefore;
                if (i === 0 && word === 0) {
                  spaceBefore = false;
                }

                return {
                  ...w,
                  isInputWord: prevWord.isInputWord,
                  inlineStyles: prevWord.inlineStyles,
                  stickyInlineStyles: true,
                  spaceBefore,
                };
              })
            : p.words;

        currentLiveWordDataFinals[line].words.splice(word + 1, 0, ...newWords);

        interCurPos = [line, word + newWords.length];
      }
    });

    if (deleteParagraph) {
      const newDataOriginal = [...currentLiveWordDataFinals, ...newLiveWordDataParagraphs];
      let newData = [...currentLiveWordDataFinals, ...newLiveWordDataParagraphs];

      const cursorToReturnBlock = cursorPosition[0] - 1 >= 0 ? cursorPosition[0] - 1 : 0;
      const cursorToReturnWord = newDataOriginal[cursorToReturnBlock].words.length;

      newData.splice(cursorPosition[0], 1);

      return { data: newData, newCursorPos: [cursorToReturnBlock, cursorToReturnWord - 1] };
    }

    if (interCurPos) {
      return { data: currentLiveWordDataFinals, newCursorPos: [...interCurPos] };
    }

    // if (newLiveWordDataParagraphs[0] && newLiveWordDataParagraphs[0].length !== 0) {
    //   currentLiveWordDataFinals[cursorPosition[0]].splice(
    //     cursorPosition[1] + 1,
    //     0,
    //     ...newLiveWordDataParagraphs[0]
    //   );
    // } else if (newLiveWordDataParagraphs[0] && newLiveWordDataParagraphs[0].length === 0) {
    //   const indexToSplit = cursorPosition[1] + 1;
    //   const newArr1 = currentLiveWordDataFinals[cursorPosition[0]].slice(0, indexToSplit);
    //   const newArr2 = currentLiveWordDataFinals[cursorPosition[0]].slice(indexToSplit);
    //   currentLiveWordDataFinals.splice(cursorPosition[0], 1);
    //   currentLiveWordDataFinals.splice(cursorPosition[0], 0, newArr1);
    //   // currentLiveWordDataFinals.splice(cursorPosition[0] + 1, 0, newLiveWordDataParagraphs[0]);
    //   currentLiveWordDataFinals.splice(cursorPosition[0] + 1, 0, newArr2);
    //   console.log(currentLiveWordDataFinals, newArr1, newArr2, newLiveWordDataParagraphs);
    //   return { data: currentLiveWordDataFinals, newCursorPos: [cursorPosition[0] + 1, -1] };
    // }

    // if (newParLen > 1) {
    //   return { data: currentLiveWordDataFinals, newCursorPos: null };
    // }

    // console.log('1.5 ------>');

    // if (newLiveWordDataParagraphs.length === 1)
    //   return {
    //     data: currentLiveWordDataFinals,
    //     newCursorPos: [cursorPosition[0], cursorPosition[1] + newLiveWordDataParagraphs[0].length],
    //   };

    return { data: currentLiveWordDataFinals, newCursorPos: null };
  }

  if (newLiveWordDataParagraphs[0] && newLiveWordDataParagraphs[0].words.length !== 0) {
    if (currentLiveWordDataFinals.length === 0) {
      return { data: newLiveWordDataParagraphs, newCursorPos: null };
    } else {
      currentLiveWordDataFinals[currentLiveWordDataFinals.length - 1].words = [
        ...currentLiveWordDataFinals[currentLiveWordDataFinals.length - 1].words,
        ...newLiveWordDataParagraphs[0].words,
      ];
    }

    newLiveWordDataParagraphs.shift();

    if (newLiveWordDataParagraphs) {
      return { data: [...currentLiveWordDataFinals, ...newLiveWordDataParagraphs], newCursorPos: null };
    } else {
      return { data: currentLiveWordDataFinals, newCursorPos: null };
    }
  }

  return { data: [...currentLiveWordDataFinals, ...newLiveWordDataParagraphs], newCursorPos: null };
};

export const generateParagraphsFromWordData = (wordData: IWordData[]) => {
  let newLiveWordDataParagraphs: IFinalBlock[] = [];
  wordData.forEach((word) => {
    if (word.text === Commands.NEW_PARAGRAPH) {
      newLiveWordDataParagraphs = [
        ...newLiveWordDataParagraphs,
        { speaker: null, words: [] },
        { speaker: null, words: [] },
      ];
    } else if (word.text === Commands.NEW_LINE) {
      newLiveWordDataParagraphs = [...newLiveWordDataParagraphs, { speaker: null, words: [] }];
    } else {
      const lastParI = newLiveWordDataParagraphs.length > 0 ? newLiveWordDataParagraphs.length - 1 : 0;
      if (newLiveWordDataParagraphs.length === 0) {
        newLiveWordDataParagraphs = [{ speaker: null, words: [word] }];
      } else {
        newLiveWordDataParagraphs[lastParI].words.push(word);
      }
    }
  });

  return newLiveWordDataParagraphs;
};

export const convertSecondsToTimeString = (
  secondsAndMilliseconds: number,
  alwaysShowMillis: boolean,
  hideMilis?: boolean
) => {
  const allSeconds = Math.floor(secondsAndMilliseconds);
  const milliseconds = secondsAndMilliseconds - allSeconds;

  const hours = Math.floor(allSeconds / 3600);

  const remainingSeconds = allSeconds - 3600 * hours;
  const minutes = Math.floor(remainingSeconds / 60);

  let seconds = remainingSeconds - 60 * minutes;
  if (!hideMilis) {
    seconds += milliseconds;
  }
  seconds = Math.round((seconds + 0.00001) * 100) / 100;

  let timeString = '';

  if (hours > 0) {
    timeString += hours < 10 ? '0' + hours : hours;
    timeString += ':';
  }

  timeString += minutes < 10 ? '0' + minutes : minutes;
  timeString += ':';

  timeString += seconds < 10 ? '0' + seconds : seconds;

  if (alwaysShowMillis && Math.floor(seconds) === seconds) {
    timeString += '.0';
  }

  return timeString;
};

export const convertEditorStateToTranscript = (
  editorState: EditorState,
  options: {
    toLiveWordData?: boolean;
    toFinalsContent?: boolean;
    setNewCursorPos?: boolean;
    fromEntries?: boolean;
  }
) => {
  const { toLiveWordData, toFinalsContent, setNewCursorPos, fromEntries } = options;
  let transcript: IWordData[] = [];
  let liveWordTranscript: IFinalBlock[] = [];
  let toAddLive: IWordData[] = [];
  let lastWordData;
  let newCursorPos: ICursor = { cursorPosition: null, cursorType: CursorTypesEnum.NORMAL };

  // TO-DO:
  // let newFinalsToSave: IFinalToSave[] = [];
  // let lastFinalID = -1;
  // let newContent: IWordData[] = [];

  const contentStateRaw = convertToRaw(editorState.getCurrentContent());

  const sel = editorState.getSelection();
  const selOffset = sel.getFocusOffset();
  const bk = sel.getAnchorKey();
  if (setNewCursorPos) {
    const bI = contentStateRaw.blocks.findIndex((b) => b.key === bk);
    if (bI !== -1) {
      let wI = contentStateRaw.blocks[bI].entityRanges.findIndex(
        (er) => er.offset <= selOffset && er.offset + er.length >= selOffset
      );

      if (wI === 0 && selOffset === 0) {
        newCursorPos = { cursorPosition: [bI, -1], cursorType: CursorTypesEnum.NORMAL };
      } else if (wI !== -1) {
        if (
          contentStateRaw.blocks[bI].entityRanges[wI].offset === selOffset &&
          contentStateRaw.blocks[bI].entityRanges[wI - 1] !== undefined
        ) {
          wI = wI - 1;
        }
        if (wI >= 0) {
          newCursorPos = { cursorPosition: [bI, wI], cursorType: CursorTypesEnum.NORMAL };
        }
      } else {
        newCursorPos = { cursorPosition: [bI, -1], cursorType: CursorTypesEnum.NORMAL };
      }
    }
  }

  const { blocks, entityMap } = contentStateRaw;

  let updateCursorFor = 0;
  blocks.forEach((block, i) => {
    if (!fromEntries) {
      block.entityRanges.forEach((entityRange, j) => {
        const wordData = entityMap[entityRange.key].data as IWordData;
        lastWordData = wordData;

        const wordToSplit = wordData.updatedText ? wordData.updatedText : wordData.text;
        // if a word is a space character, then we don't split it, because
        // ' '.split(' ') produces an array with two words of length zero.
        // Which corrupts {input} handling.
        const splitWord = wordToSplit === ' ' ? [' '] : wordToSplit.split(' ');

        if (splitWord.length > 1) {
          if (
            newCursorPos.cursorPosition &&
            i === newCursorPos.cursorPosition[0] &&
            newCursorPos.cursorPosition[1] === j
          ) {
            const diff = selOffset - entityRange.offset;
            let counter = 0;

            let set = false;
            splitWord.forEach((w, k) => {
              counter += w.length + 1;

              if (diff <= counter && newCursorPos.cursorPosition !== null && !set) {
                updateCursorFor += k;
                set = true;
              }
            });
          } else if (
            newCursorPos.cursorPosition !== null &&
            i === newCursorPos.cursorPosition[0] &&
            newCursorPos.cursorPosition[1] > j
          ) {
            if (newCursorPos.cursorPosition !== null) {
              updateCursorFor += splitWord.length - 1;
            }
          }

          splitWord.forEach((newWord, k) => {
            toAddLive.push({
              ...wordData,
              text: newWord,
              updatedText: newWord,
              ...(k !== 0 && { spaceBefore: true }),
              confidence: 1,
              variableCode: undefined,
              metadata: undefined,
            });
            transcript.push({
              ...wordData,
              text: newWord,
              updatedText: newWord,
              ...(k !== 0 && { spaceBefore: true }),
              confidence: 1,
              variableCode: undefined,
              metadata: undefined,
            });
          });
        } else {
          toAddLive.push({ ...wordData });
          transcript.push({ ...wordData });
        }
      });
    }

    if ((block.entityRanges.length === 0 && block.text !== '') || fromEntries) {
      if (toLiveWordData) {
        toAddLive.push({ ...lastWordData, text: block.text, updatedText: block.text });
        transcript.push({ ...lastWordData, text: block.text, updatedText: block.text });
      }
    }

    liveWordTranscript.push({ speaker: block.data?.speaker, words: toAddLive });
    toAddLive = [];
  });

  if (newCursorPos.cursorPosition) {
    newCursorPos.cursorPosition[1] += updateCursorFor;
  }

  return { transcript, liveWordTranscript, newCursorPos };
};

const generateFinalTranscripts = (transcript: IWordData[], makeBlocks: boolean): IWordData[][] => {
  if (!makeBlocks) {
    return [transcript];
  }
  const newArr: IWordData[][] = [];
  const indexes: number[] = [];
  //[[{word},{word}], [], []]
  let prevIndex = 0;

  transcript.forEach((word, i) => {
    if (word.lastInFinal) {
      indexes.push(i);
    }
  });

  indexes.forEach((index) => {
    const subArr = transcript.slice(prevIndex === 0 ? prevIndex : prevIndex + 1, index + 1);
    newArr.push(subArr);
    prevIndex = index;
  });

  return newArr;
};

// Check word start/end times to calculate the pause between words.
// If the pause is longer than 75th percentile of pause lengths, then
// assume it's a new paragraph. The percentile is calculated over 10
// previous words.
const breakFinalBlocksByTimeDiff = (blocks: IFinalBlock[]) => {
  const blocksByTimeDiff: IFinalBlock[] = [];

  for (const block of blocks) {
    if (block.words.length < 1) {
      blocksByTimeDiff.push({ ...block });
      continue;
    }

    let prevEnd = 0;
    let rollingPauses = [0.5];
    let newWords: IWordData[] = [];
    for (const word of block.words) {
      const pause = (word.startTime || 0) - prevEnd;
      console.log(
        'delta =',
        pause.toFixed(2),
        `, "${word.text}"`,
        ', start time =',
        word.startTime.toFixed(2),
        ', end time =',
        word.endTime.toFixed(2)
      );
      if (rollingPauses.length >= 10) {
        rollingPauses.shift();
      }
      if (pause > 0.1) {
        rollingPauses.push(pause);
      }
      const q = quantile(rollingPauses, 0.75);
      if (pause > q) {
        console.log(
          ` > will break, quantile ${q.toFixed(2)}, rolling pauses`,
          JSON.parse(JSON.stringify(rollingPauses.map((x) => x.toFixed(2))))
        );
        blocksByTimeDiff.push({ ...block, words: newWords });
        blocksByTimeDiff.push({ ...block, words: [] });
        newWords = [];
      }
      newWords.push({ ...word });
      prevEnd = word.endTime;
    }
    if (newWords.length > 0) {
      blocksByTimeDiff.push({ ...block, words: newWords });
    }
  }

  return blocksByTimeDiff;
};

const breakFinalBlocksByTimeDiff2 = (blocks: IFinalBlock[], transcripts: ITranscriptWord[][]) => {
  let pauseIndexes: { [key: number]: boolean } = {};

  let prevEnd = 0;
  let rollingPauses = [0.5];
  let wordCount = 0;
  for (const final of transcripts) {
    if (!final?.length) {
      continue;
    }

    const pause = final[0].startTime - prevEnd;

    if (rollingPauses.length >= 10) {
      rollingPauses.shift();
    }
    rollingPauses.push(pause);
    const q = quantile(rollingPauses, 0.75);
    if (wordCount > 0 && pause > q) {
      console.log(
        ` > will break, quantile ${q.toFixed(2)}, rolling pauses`,
        JSON.parse(JSON.stringify(rollingPauses.map((x) => x.toFixed(2))))
      );
      pauseIndexes[wordCount] = true;
    }

    prevEnd = final[final.length - 1].endTime;
    wordCount = wordCount + final.length;
  }

  const blocksByTimeDiff: IFinalBlock[] = [];
  wordCount = 0;
  for (const block of blocks) {
    let newWords: IWordData[] = [];
    for (const word of block.words) {
      if (pauseIndexes[wordCount]) {
        // should break
        blocksByTimeDiff.push({ ...block, words: newWords });
        blocksByTimeDiff.push({ ...block, words: [] });
        newWords = [];
      }
      wordCount = wordCount + 1;
      newWords.push({ ...word });
    }
    if (newWords.length > 0) {
      blocksByTimeDiff.push({ ...block, words: newWords });
    }
  }

  return blocksByTimeDiff;
};

export const convertFinalBlocksToAlignedBlocks = (blocks: IFinalBlock[], maxWordsPerBlock?: number) => {
  const mW = maxWordsPerBlock ?? 155;

  const alignedBlocks: IFinalBlock[] = [];

  let addEmptyOnNext = false;
  for (const block of blocks) {
    let c = 0;

    let newLine: IWordData[] = [];

    if (block.words.length === 0) {
      alignedBlocks.push({ ...block, words: [] });

      continue;
    }

    for (const word of block.words) {
      if (c + 1 >= mW) {
        if (word.spaceBefore === false) {
          c += 1;
          newLine.push(word);
          continue;
        }

        if (addEmptyOnNext) {
          alignedBlocks.length && alignedBlocks.push({ ...block, words: [] });
          addEmptyOnNext = false;
        }
        addEmptyOnNext = true;
        alignedBlocks.push({ ...block, words: newLine });

        newLine = [];
        c = 0;
      }
      newLine.push(word);
      c += 1;
    }
    if (newLine.length) {
      if (addEmptyOnNext) {
        alignedBlocks.push({ ...block, words: [] });
        addEmptyOnNext = false;
      }
      alignedBlocks.push({ ...block, words: newLine });
    }
  }

  return alignedBlocks;
};

// New converter that takes transcript and makes many blocks based on finals
export const convertTranscriptToEditorState = (
  transcript?: IWordData[],
  makeBlocks?: boolean,
  liveWordData?: IFinalBlock[],
  transcriptData?: ITranscriptWord[][],
  entryVarsOnly?: boolean,
  disableAutoBlocks?: boolean
): EditorState => {
  const transcripts = transcript && generateFinalTranscripts(transcript, makeBlocks || false);

  const generateRawData = () => {
    let blocks: any = [];
    let entityMap = {};

    if (liveWordData) {
      if (!entryVarsOnly && !disableAutoBlocks) {
        // liveWordData = convertFinalBlocksToAlignedBlocks(liveWordData);
        // liveWordData = breakFinalBlocksByTimeDiff(liveWordData);
        console.log('Auto-breaking transcripts:', transcriptData);
        liveWordData = breakFinalBlocksByTimeDiff2(liveWordData, transcriptData || []);
      }

      liveWordData.forEach(({ speaker, words }, i) => {
        const newEntityRanges = entryVarsOnly
          ? generateEntityRangesForEntryVars(words, 0)
          : generateEntityRanges(words, 0);
        const newBlock = {
          key: `customBlock_${i.toString()}`,
          text: generateStringFromTranscript(words, entryVarsOnly ? true : false),
          type: 'customBlock',
          depth: 0,
          inlineStyleRanges: generateInlineStyleRanges(words, 0),
          entityRanges: newEntityRanges,
          data: {
            speaker,
          },
        };
        const newMap = entryVarsOnly
          ? generateEntityMapForEntryVars(words, newEntityRanges)
          : generateEntityMap(words, newEntityRanges);
        blocks.push(newBlock);
        entityMap = { ...entityMap, ...newMap };
      });
    } else {
      transcripts &&
        transcripts.forEach((transcript, i) => {
          const newEntityRanges = generateEntityRanges(transcript, 0);
          const newBlock = {
            key: `customBlock_${i.toString()}`,
            text: generateStringFromTranscript(transcript),
            type: 'customBlock',
            depth: 0,
            inlineStyleRanges: generateInlineStyleRanges(transcript, 0),
            entityRanges: newEntityRanges,
            data: { speaker: null },
          };
          const newMap = generateEntityMap(transcript, newEntityRanges);
          blocks.push(newBlock);
          entityMap = { ...entityMap, ...newMap };
        });
    }

    return {
      blocks,
      entityMap,
    };
  };

  const d = generateRawData();

  const rawData = {
    blocks: d.blocks,
    entityMap: d.entityMap,
  };

  //@ts-ignore
  const contentState = convertFromRaw(rawData);
  const editorStateNew = EditorState.createWithContent(contentState, entryVarsOnly ? undefined : decorator);

  // console.log("Will return new editorstate", convertToRaw(editorStateNew.getCurrentContent()));
  return editorStateNew;
};

export const transformNotSavedSessionTranscriptsToLiveWordData = (sessionTranscripts: IFinal[]) => {
  return new Promise<{ newEditorState: EditorState; currentLiveWordData: IWordDataLive }>(
    (resolve, reject) => {
      try {
        let capitalizeNextWord = false;
        let capitalizeNextWordCommandPrev = false;
        let lowerCaseAllNext = false;
        let upperCaseAllNext = false;
        let currentLiveWordData: IWordDataLive = { finalsBlocks: [], lastInterim: '' };

        sessionTranscripts.forEach((tr: IFinal, i: number) => {
          const {
            newLiveWordData,
            capitalizeNextWord: capNextWord,
            capitalizeNextWordCommand: capNextCommand,
            upperCaseAll,
            lowerCaseAll,
          } = convertReceivedTranscriptToWordDataLiveNew(
            {
              isFinal: true,
              decoded: 100,
              transcript: tr,
              messageType: ReceivedMessageTypes.TRANSCRIPT,
            },
            currentLiveWordData,
            {
              postProcessingLive: true,
              capitalizeNextWord,
              isFromUpload: true,
              capitalizeNextWordCommandPrev,
              lowerCaseAllNext,
              upperCaseAllNext,
              isNewRecordingSession: true,
              isInFindMode: false,
            }
          );

          currentLiveWordData = newLiveWordData;
          capitalizeNextWord = capNextWord || false;
          capitalizeNextWordCommandPrev = capNextCommand || false;
          upperCaseAllNext = upperCaseAll || false;
          lowerCaseAllNext = lowerCaseAll || false;
        });

        const newEditorState = convertTranscriptToEditorState(
          undefined,
          false,
          currentLiveWordData.finalsBlocks
        );

        resolve({ currentLiveWordData, newEditorState });
      } catch (e) {
        reject(e);
      }
    }
  );
};

export const expandWordVariables = (words: IWordData[]): IWordData[] => {
  const result: IWordData[] = [];

  for (const word of words) {
    if (word.text === EntryVariables.DATE) {
      result.push({
        ...word,
        text: new Date().toLocaleDateString(),
      });
    } else if (word.text === EntryVariables.TIME) {
      result.push({
        ...word,
        text: new Date().toLocaleTimeString(),
      });
    } else if (word.text === EntryVariables.INPUT) {
      // {input}s are expanded into two spaces
      const spaceWord = {
        ...word,
        text: ' ',
        spaceBefore: true,
        isInputWord: true,
        stickyInlineStyles: true,
        inlineStyles: [...(word.inlineStyles || [])],
      };

      result.push({ ...spaceWord }, { ...spaceWord });
    } else {
      result.push(word);
    }
  }

  return result;
};

// Find next occurence of {input} from `cursorPosition`.
export const findNextInputPosition = (
  currentLiveWordDataFinals: IFinalBlock[],
  cursorPosition: number[] | null
) => {
  const [lineIndex, wordIndex] = cursorPosition ? cursorPosition : [0, 0];

  // We find next {input} by searching for next word that is input word.
  // There are two cases: we start from inside input or outside of one.
  // In first scenario, we first search for regular word and after that for
  // first occurence of input word.
  let cursorInInput = currentLiveWordDataFinals[lineIndex]?.words[wordIndex + 1]?.isInputWord;

  for (let i = lineIndex; i < currentLiveWordDataFinals.length; i++) {
    const block = currentLiveWordDataFinals[i];
    if (!block) {
      continue;
    }

    const wordStart = i === lineIndex ? wordIndex + 1 : 0;
    for (let k = wordStart; k < block.words.length; k++) {
      const word = block.words[k];
      const nextWord = getNextNonEmptyWord(currentLiveWordDataFinals, i, k + 1);

      if (word.isInputWord && !nextWord?.isInputWord) {
        if (cursorInInput) {
          cursorInInput = false;
          continue;
        }
        return [i, k - 1];
      }
    }
  }

  return null;
};

// Find previous occurence of {input} from `cursorPosition`.
export const findPreviousInputPosition = (
  currentLiveWordDataFinals: IFinalBlock[],
  cursorPosition: number[] | null
) => {
  const lastLineIndex = currentLiveWordDataFinals.length;
  const lastWordIndex = currentLiveWordDataFinals?.[lastLineIndex - 1]?.words.length || 0;
  const [lineIndex, wordIndex] = cursorPosition ? cursorPosition : [lastLineIndex, lastWordIndex];

  // We find previous {input} by searching for previous word that is input word.
  // There are two cases: we start from inside input or outside of one.
  // In first scenario, we first search for regular word and after that for
  // first occurence of input word.
  let cursorInInput = currentLiveWordDataFinals[lineIndex].words[wordIndex + 1].isInputWord;

  for (let i = lineIndex; i >= 0; i--) {
    const block = currentLiveWordDataFinals[i];
    if (!block) {
      continue;
    }

    const wordStart = i === lineIndex ? wordIndex : block.words.length - 1;
    for (let k = wordStart; k >= 0; k--) {
      const word = block.words[k];
      if (word.isInputWord && !cursorInInput) {
        return [i, k - 1];
      }

      if (!word.isInputWord && cursorInInput) {
        cursorInInput = false;
      }
    }
  }

  return null;
};

export const findInputByIndex = (currentLiveWordDataFinals: IFinalBlock[], index: number) => {
  let inputCount = 0;

  for (let i = 0; i < currentLiveWordDataFinals.length; i++) {
    const block = currentLiveWordDataFinals[i];
    if (!block) {
      continue;
    }

    for (let k = 0; k < block.words.length; k++) {
      const word = block.words[k];
      const nextWord = getNextNonEmptyWord(currentLiveWordDataFinals, i, k + 1);

      if (word.isInputWord && !nextWord?.isInputWord) {
        inputCount++;
      }

      if (inputCount === index) {
        return [i, k - 1];
      }
    }
  }

  return null;
};

export const findInputIndexByPosition = (
  currentLiveWordDataFinals: IFinalBlock[],
  lineIndex: number,
  wordIndex: number
) => {
  let inputCount = 0;
  let isInInput = currentLiveWordDataFinals[0].words[0].isInputWord;
  for (let i = 0; i < currentLiveWordDataFinals.length; i++) {
    const block = currentLiveWordDataFinals[i];
    for (let k = 0; k < block.words.length; k++) {
      const word = block.words[k];
      if (word.isInputWord && !isInInput) {
        isInInput = true;
        inputCount++;
      }

      if (!word.isInputWord && isInInput) {
        isInInput = false;
      }

      if (i === lineIndex && k === wordIndex && isInInput) {
        return inputCount;
      }
    }
  }

  return 0;
};

// Get the next word with length > 0, search from supplied
// lineIndex and wordIndex.
export const getNextNonEmptyWord = (
  currentLiveWordDataFinals: IFinalBlock[],
  lineIndex: number,
  wordIndex: number
) => {
  for (let i = lineIndex; i < currentLiveWordDataFinals.length; i++) {
    const block = currentLiveWordDataFinals[i];
    const wordStart = i === lineIndex ? wordIndex : 0;
    for (let k = wordStart; k < block.words.length; k++) {
      const word = block.words[k];
      if (word.text?.length > 0) {
        return word;
      }
    }
  }

  return null;
};

// Get the previous word with length > 0, search from supplied
// lineIndex and wordIndex.
export const getPreviousNonEmptyWord = (
  currentLiveWordDataFinals: IFinalBlock[],
  lineIndex: number,
  wordIndex: number
) => {
  for (let i = lineIndex; i >= 0; i--) {
    const block = currentLiveWordDataFinals[i];
    const wordStart = i === lineIndex ? wordIndex : block.words.length - 1;
    for (let k = wordStart; k >= 0; k--) {
      const word = block.words[k];
      if (word.text?.length > 0) {
        return word;
      }
    }
  }

  return null;
};
