import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { IHeader, IStore } from '../redux/store/IStore';

type ISaveKey = 'header' | 'page-size';
type ISaveType = IHeader | number;

export default function useLocalStorageSave(key: ISaveKey) {
  const userName = useSelector((state: IStore) => state.user?.username);

  const save = useCallback(
    (value: ISaveType) => {
      if (!userName) return;

      localStorage.setItem(`editor-${userName}-${key}`, JSON.stringify(value));
    },
    [userName, key]
  );

  return [save];
}
