import React, { useRef } from 'react';
import { Colors, IWordData } from './IEditor';

interface Props {
  word: IWordData;
  previousWord: IWordData | null;
  nextWord: IWordData | null;
  i: number;
  k: number;
  isSelectedForFixMode?: boolean | null;
  isSelectedForFindMode?: boolean | null;
  id: string;
  onClickCallback?: (pos: number[]) => void;
  findInputIndex: (lineIndex: number, wordIndex: number) => number;
  inputIndexForCursor: number;
}

const styleMap = {
  STRIKETHROUGH: {
    textDecoration: 'line-through',
  },
  BOLD: {
    fontWeight: 'bold',
  },
  ITALIC: {
    fontStyle: 'italic',
  },
  UNDERLINE: {
    textDecoration: 'underline',
  },
  HIGHLIGHT_INPUT: {
    backgroundColor: Colors.HIGHLIGHT_INPUT,
  },
  OUTLINE_INPUT: {
    borderBottom: `solid 1px ${Colors.OUTLINE_INPUT}`,
  },
  OUTLINE_ACTIVE_INPUT: {
    borderBottom: `solid 1px ${Colors.OUTLINE_ACTIVE_INPUT}`,
  },
};

const genStyles = (type: 'find' | 'fix' | 'spell' | 'replacement' | '') => {
  if (type === '') return {};

  switch (type) {
    case 'find':
      return { animation: 'heartbeat-border-red 800ms infinite ease-in', borderBottom: '2px solid' };
    case 'fix':
      return { animation: 'heartbeat-border-blue 800ms infinite ease-in', borderBottom: '2px solid' };
    case 'spell':
      return {};
    case 'replacement':
      return { borderBottom: '2px dotted rgb(248, 5, 52)' };

    default:
      return {};
  }
};

const getStylesForInputWord = (inlineStyles: string[], isActive: boolean) => {
  const outlinePrefix = isActive ? 'OUTLINE_ACTIVE' : 'OUTLINE';

  return {
    ...genStylesForWord(inlineStyles),
    ...styleMap[`${outlinePrefix}_INPUT`],
  };
};

const genStylesForWord = (inlineStyles: string[]) => {
  let newStyles = {};
  inlineStyles.forEach((style) => {
    newStyles = { ...newStyles, ...styleMap[style] };
  });

  return newStyles;
};

const LiveWord = ({
  word,
  previousWord,
  nextWord,
  isSelectedForFixMode,
  i,
  k,
  isSelectedForFindMode,
  id,
  onClickCallback,
  findInputIndex,
  inputIndexForCursor,
}: Props) => {
  const { inlineStyles, spaceBefore, metadata, text, updatedText, isInputWord } = word;

  const wordRef = useRef<HTMLSpanElement | null>(null);

  const handleClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    try {
      const rect = wordRef.current?.getBoundingClientRect();
      if (!rect) return;
      const contains = wordRef.current && wordRef.current.contains(e.target as Node);
      let finalI = i;

      if (contains) {
        const clientXClick = e.clientX;
        const isClickRightSide = clientXClick >= rect.x + rect.width / 2;
        finalI = isClickRightSide ? i : i - 1;
      } else {
        finalI = i - 1;
      }
      onClickCallback && onClickCallback([k, finalI]);
    } catch (err) {}
  };

  const inputIndex = findInputIndex(k, i);
  const replacement = metadata?.source === 'REPLACEMENT';

  const styles =
    inputIndex > 0
      ? getStylesForInputWord(inlineStyles || [], inputIndex === inputIndexForCursor)
      : genStylesForWord(inlineStyles || []);

  const hideSpace = !previousWord?.isInputWord && isInputWord;
  return (
    <>
      <span ref={wordRef} style={{ ...(!hideSpace && styles) }} onClick={handleClick}>
        {spaceBefore && i > 0 && ' '}

        <span
          style={{
            ...genStyles(
              isSelectedForFindMode ? 'find' : isSelectedForFixMode ? 'fix' : replacement ? 'replacement' : ''
            ),
            ...(hideSpace && styles),
          }}
          id={id}
        >
          {updatedText || text}
        </span>

        {!nextWord?.isInputWord && inputIndex > 0 && (
          <span
            style={{
              position: 'relative',
              fontSize: 'small',
              fontWeight: 'bold',
              zIndex: 1,
              top: '4px',
              color: Colors.OUTLINE_INPUT,
            }}
          >
            {inputIndex}
          </span>
        )}
      </span>
    </>
  );
};

export default LiveWord;
