import { SnackbarContent, CustomContentProps } from 'notistack'
import React from 'react'
import { forwardRef } from 'react'
import './warn_message.css'

interface ReportCompleteProps extends CustomContentProps {
  allowDownload: boolean
}

const WarnMessage = forwardRef<HTMLDivElement, ReportCompleteProps>((props, ref) => {
  const {
    // You have access to notistack props and options 👇🏼
    id,
    message,
    // as well as your own custom props 👇🏼
    allowDownload,
    ...other
  } = props

  return (
    <SnackbarContent className='warn_message' ref={ref}>
      {message}
    </SnackbarContent>
  )
})

export default WarnMessage;