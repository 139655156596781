import { API, COMMON, INFO } from './endpoints';
import axiosAuth from './axios';
import { config } from '../config';

const backendUrl: string = config.backendApiUrl;

const baseUrl: string = `${backendUrl}/${API}/${COMMON}`;
const aboutUrl: string = `${baseUrl}/${INFO}`;

export const getAbout = (): Promise<any> => {
  return axiosAuth.get(aboutUrl);
};
