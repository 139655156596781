// Aliases
window.AudioContext = window.AudioContext || window.webkitAudioContext;
window.OfflineAudioContext = window.OfflineAudioContext || window.webkitOfflineAudioContext;
AudioContext.prototype.createScriptProcessor =
  AudioContext.prototype.createScriptProcessor || AudioContext.prototype.createJavaScriptNode;

function floatTo16BitPCM(input) {
  // Each 32bit (4byte) float from input is converted to one 16bit (2byte) integer.
  // Each element needs 2 bytes
  let buffer = new ArrayBuffer(input.length * 2);

  // Define view to raw buffer so we can set values as int16.
  let view = new DataView(buffer);

  for (let i = 0; i < input.length; i++) {
    // Limit input to [-1, -1]
    const s = Math.max(-1, Math.min(1, input[i]));

    // Convert float32 to int16 and force little endian
    view.setInt16(2 * i, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }

  return buffer;
}

export async function processAudioFile(
  originalBuffer,
  numChannels,
  sampleRate,
  chunkSize,
  onDurationReady,
  onChunkReady
) {
  const ctx = new AudioContext();
  const chunksToSend = [];

  const decodedBuffer = await ctx.decodeAudioData(originalBuffer);
  onDurationReady(decodedBuffer.duration);
  const offlineCtx = new OfflineAudioContext({
    numberOfChannels: numChannels,
    sampleRate: sampleRate,
    length: decodedBuffer.duration * sampleRate,
  });

  const bufferSource = offlineCtx.createBufferSource();
  bufferSource.buffer = decodedBuffer;
  bufferSource.connect(offlineCtx.destination);
  bufferSource.start();

  const decodedAndResampledBuffer = await offlineCtx.startRendering();

  let audioData = decodedAndResampledBuffer.getChannelData(0);
  for (let i = 0; i < audioData.length; i += chunkSize) {
    let sliceStart = i;
    let sliceEnd = i + chunkSize;

    if (audioData.length < sliceEnd) {
      sliceEnd = audioData.length;
    }

    const audioChunk = floatTo16BitPCM(audioData.slice(sliceStart, sliceEnd));

    const progress = Math.floor(((i + 1) / audioData.length) * 100);

    chunksToSend.push(audioChunk);

    onChunkReady(audioChunk, progress);
  }

  return chunksToSend;
}
