import React from 'react';

import { useSelector } from 'react-redux';

import '../../styles/css/dashboard.css';
import { IStore } from '../../redux/store/IStore';

const ResizableRow = ({ attr, children, ...rest }: any) => {
  const headerWidth = useSelector((store: IStore) => store.dashboardHeaderWidths?.[attr]) as number;

  return (
    <div {...rest} style={{ width: headerWidth }}>
      {children}
    </div>
  );
};

export default ResizableRow;
