import React from 'react';
import '../../styles/css/header.css';
import CustomPopover from '../../shared/CustomPopover';
import { useDispatch, useSelector } from 'react-redux';
import { SET_FONT_SIZE } from '../../redux/actions/actions';
import { FontSize, IStore } from '../../redux/store/IStore';
import { SpeedTimes } from '../Icons/SpeedTimes';
import Slider from '../../shared/Slider';
import { IAction } from '../../redux/actions/IActions';
import {
  convertFontSizeToPercent,
  convertFontSizeToString,
  convertPercentToFontSize,
  convertPercentToString,
} from '../../shared/FontSizeLib';

export const FontSizeButton = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const dispatch = useDispatch();
  const currentFontSize = useSelector<IStore, string>((state) => state.fontSize);

  const fontSizeNum: number = parseFloat(currentFontSize.split('px')[0]);
  const fontSizePercent: number = convertFontSizeToPercent(fontSizeNum);
  const fontSizePercentString = convertPercentToString(fontSizePercent);
  const translatex = fontSizePercentString.length === 4 ? '2' : '8';

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const getTextColor = () => {
    if (open) {
      return '#2B8DFF';
    } else if (fontSizePercent > 100) {
      return '#2B8DFF';
    } else if (fontSizePercent <= 50) {
      return '#FF6666';
    }

    return '#CCCCCC';
  };

  const onMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onMouseLeave = () => {
    setAnchorEl(null);
  };

  const handleValueChange = (newValue: number) => {
    const fontSize = convertPercentToFontSize(newValue);
    const fontSizeString = convertFontSizeToString(fontSize);
    dispatch({ type: SET_FONT_SIZE, fontSize: fontSizeString } as IAction);
  };

  const handleDecreaseClick = () => {
    if (fontSizePercent > 25) {
      dispatch({
        type: SET_FONT_SIZE,
        fontSize: convertFontSizeToString(fontSizeNum - 1),
      } as IAction);
    }
  };

  const handleIncreaseClick = () => {
    if (fontSizePercent < 400) {
      dispatch({
        type: SET_FONT_SIZE,
        fontSize: convertFontSizeToString(fontSizeNum + 1),
      } as IAction);
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onMouseLeave={onMouseLeave}
      >
        <SpeedTimes
          aria-describedby={id}
          className="icon_button"
          onMouseEnter={onMouseEnter}
          viewBox="0 0 48 48"
          style={{
            fill: getTextColor(),
          }}
          text={fontSizePercentString}
          translatex={translatex}
        />

        <CustomPopover
          open={open}
          anchorEl={anchorEl}
          onClose={onMouseLeave}
          width={'245px'}
          backgroundColor="white"
          substractScrollbar
        >
          <Slider
            defaultValue={fontSizePercent}
            handleValueChange={handleValueChange}
            handleDecreaseClick={handleDecreaseClick}
            handleIncreaseClick={handleIncreaseClick}
            step={'1'}
            minValue="25"
            maxValue="400"
          />
        </CustomPopover>
      </div>
    </>
  );
};
