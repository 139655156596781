const _env = process.env.NODE_ENV === 'development' ? process.env : (window as any)._env_;
export const config = {
  environment: _env.REACT_APP_ENVIRONMENT || 'dev',
  appVersion: _env.REACT_APP_VERSION || 'local',
  appName: _env.REACT_APP_NAME || 'truebar-editor',
  commitHash: _env.REACT_APP_COMMIT_HASH || 'tbd',
  backendApiUrl: _env.REACT_APP_BACKEND_URL || 'https://dev-editor-backend.true-bar.si',
  backendWsUrl: _env.REACT_APP_WEBSOCKET_URL || 'wss://dev-editor-backend.true-bar.si/ws',
  keycloakUrl: _env.REACT_APP_KEYCLOAK_URL || 'https://dev-keycloak.vitasis.si',
  keycloakAuthReam: _env.REACT_APP_KEYCLOAK_AUTH_REALM || 'truebar',
  keycloakClientId: _env.REACT_APP_KEYCLOAK_CLIENT_ID || 'truebar-editor',
  monitoringEnabled: _env.REACT_APP_MONITORING_ENABLED,
  monitoringApmServerUrl: _env.REACT_APP_MONITORING_APM_SERVER_URL || 'https://apm.vitasis.si',
  prepareTranscripts: _env.REACT_APP_PREPARED_TRANSCRIPTS,
  historyRefreshIntervalMs: _env.REACT_APP_HISTORY_REFRESH_INTERVAL_MS || 5000,
  showLogo: _env.REACT_APP_SHOW_LOGO === 'true' ? true : false
};
