import React from 'react';

export default function MicIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 42.426 57.578"
    >
      <path
        id="Path_157"
        data-name="Path 157"
        d="M26.213,38.365a9.053,9.053,0,0,0,9.061-9.091l.03-18.182a9.091,9.091,0,0,0-18.182,0V29.274A9.079,9.079,0,0,0,26.213,38.365ZM22.576,10.788a3.636,3.636,0,0,1,7.273,0l-.03,18.788a3.621,3.621,0,1,1-7.243,0V10.788Zm19.7,18.485c0,9.091-7.7,15.455-16.061,15.455S10.152,38.365,10.152,29.274H5c0,10.334,8.243,18.879,18.182,20.364v9.94h6.061v-9.94c9.94-1.455,18.182-10,18.182-20.364Z"
        transform="translate(-5 -2)"
        fill={props.fill}
      />
    </svg>
  );
}
