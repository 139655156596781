/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { getCorpusInfoByText, getDmodelCorpusStats } from '../../api/DictionaryService';
import useSelectedModelData from '../../hooks/useSelectedModelData';
import Pagination from '@material-ui/lab/Pagination';
import { IStore } from '../../redux/store/IStore';
import { LessContext, MoreContext } from '../Icons/DictionaryIcons';
import ContextLine from './ContextLine';
import OptionsLine from './OptionsLine';
import { setDictionary } from '../../redux/actions/actions';

function generateNumSentencesText(num: number | null | undefined) {
  if (num === null || num === undefined) return '';
  let stringNum = `${num}`;
  const len = stringNum.length;
  const ext = len >= 10 ? 'B' : len >= 7 ? 'M' : len >= 4 ? 'K' : '';

  return ext
    ? `${stringNum[0]},${stringNum[1]}${ext} stavkov`
    : `${stringNum} ${stringNum === '1' ? 'stavek' : stringNum === '2' ? 'stavka' : 'stavkov'}`;
}

export default function Corpus() {
  const [contextValue, setContextValue] = useState(2);
  const { searchedString, caseSensitive, pageSize, searchBy } = useSelector(
    (state: IStore) => state.dictionary
  );
  const [page, setPage] = useState({
    size: 5,
    page: 0,
    totalPages: 0,
  });
  const modelData = useSelectedModelData();
  const wrapperRef = useRef(null);

  const dispatch = useDispatch();

  const { data: corpusStatsData, isLoading: isLoadingCorpusStats } = useQuery(
    'dmodel-stats-corpus',
    () => {
      return modelData && getDmodelCorpusStats(modelData);
    },
    { enabled: !!modelData }
  );

  const { data, error, isLoading, isRefetching, isIdle } = useQuery(
    `corpus-text-${searchedString || ''}-${contextValue}-${caseSensitive}-${pageSize}-${page.page}`,
    () => {
      if (!modelData || !searchedString) return null;
      return getCorpusInfoByText({
        modelData,
        text: searchedString,
        caseSensitive: caseSensitive,
        contextSize: contextValue,
        page: page.page,
        size: pageSize,
      });
    },
    { enabled: !!modelData && !!searchedString }
    // { enabled: false }
  );
  const maxCtx = data?.data.alvailableContextSize;

  const ctxs = useMemo(() => {
    if (isIdle) return null;
    if (!data || !data.data.content || !data.data.content.length || !searchedString) return [];

    return data.data.content.map((ctx, i) => {
      return {
        contextPercentage: `${ctx.numOccurrences}`,
        contextString: {
          contextLeft: ctx.leftContext,
          contextRight: ctx.rightContext,
          mainPhrase: ctx.mainPhrase,
        },
      };
    });
  }, [data, searchedString, isIdle]);

  useEffect(() => {
    return () => {
      dispatch(setDictionary({ searchedString: null }));
    };
  }, []);

  useEffect(() => {
    if (!data) return;
    setPage((curr) => {
      return {
        ...curr,
        totalPages: data.data.totalPages,
      };
    });
  }, [data]);

  const handleCtxChange = useCallback(
    (type: 'm' | 'l') => {
      if (isLoading) return;
      if (type === 'm' && maxCtx && maxCtx <= contextValue) return;

      setContextValue((curr) => {
        if (curr < 1) return 1;
        const newC = type === 'm' ? curr + 1 : curr - 1;
        return newC >= 0 ? newC : 0;
      });
    },
    [maxCtx, isLoading, contextValue]
  );

  useEffect(() => {}, []);

  if (searchBy === 'pronunciation') return null;

  return (
    <div className="dictionary_section_wrapper" style={{ height: '100%', marginBottom: 0 }} ref={wrapperRef}>
      <OptionsLine
        titles={[
          { text: 'Korpus' },
          { text: `${generateNumSentencesText(corpusStatsData?.data.numSentences)}` },
          // { text: '232k 3-gramov' },
          {
            text:
              data?.data.numSentences && searchedString
                ? `${data?.data?.numSentences} ${searchedString}`
                : '',
            isLoading: isLoading,
          },
        ]}
        mainCommands={[]}
        middleCommands={[
          {
            disabled: false,
            children: <MoreContext />,
            onClick: () => {
              handleCtxChange('m');
            },
          },
          {
            disabled: contextValue <= 1,
            children: <LessContext />,
            onClick: () => {
              handleCtxChange('l');
            },
          },
        ]}
        isLoading={isLoading}
      />
      {error && (
        <span className="ctx_text">Pri iskanju je šlo nekaj narobe. Prosimo poskusite še enkrat</span>
      )}
      {!error && <ContextLine contexts={ctxs} isLoading={isLoading} pageSize={pageSize} />}
      <div className="ctx_pagination_wrapper">
        <Pagination
          page={page.page + 1}
          onChange={(e, pn) => {
            if (isLoading) return;
            setPage({ ...page, page: pn - 1 });
          }}
          count={page.totalPages}
          variant="outlined"
          shape="rounded"
          color="primary"
        />
      </div>
    </div>
  );
}
