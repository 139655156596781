import React, { useRef } from 'react';
import { SpeedTimes } from '../Icons/SpeedTimes';

import Slider from '../../shared/Slider';
import '../../styles/css/header.css';
import CustomPopover from '../../shared/CustomPopover';

interface IProps {
  handleDynamicPlaybackRate: (playbackRate: number) => void;
  handlePlaybackRateLower: () => void;
  handlePlaybackRateHigher: () => void;
  playbackRatio: number;
}

export const PlaybackRateButton = ({
  handleDynamicPlaybackRate,
  playbackRatio,
  handlePlaybackRateLower,
  handlePlaybackRateHigher,
}: IProps) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const onMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    setOpen(true);
  };

  const onMouseLeave = () => {
    setOpen(false);
  };

  const getPlaybackColor = () => {
    const num = parseFloat(playbackRatio.toFixed(1));
    if (open) {
      if (num > 1) {
        return '#2B8DFF';
      } else if (num <= 0.5) {
        return '#FF6666';
      } else {
        return '#2B8DFF';
      }
    } else if (num > 1) {
      return '#2B8DFF';
    } else if (num <= 0.5) {
      return '#FF6666';
    }

    return '#CCCCCC';
  };

  // const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div
      onMouseLeave={onMouseLeave}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <SpeedTimes
        aria-describedby={id}
        className="icon_button"
        onMouseEnter={onMouseEnter}
        viewBox="0 0 48 48"
        style={{
          fill: getPlaybackColor(),
        }}
        text={`${playbackRatio.toFixed(1)}×`}
        translatex={'6'}
      />

      <CustomPopover
        open={open}
        anchorEl={null}
        onClose={onMouseLeave}
        backgroundColor="white"
        fullWidth
        leftInitial
        minWidth={200}
        transform="none"
        customStyle={{ top: '100%', left: 0 }}
      >
        <Slider
          handleValueChange={handleDynamicPlaybackRate}
          defaultValue={playbackRatio}
          handleDecreaseClick={handlePlaybackRateLower}
          handleIncreaseClick={handlePlaybackRateHigher}
          minValue="0.24"
          maxValue="4"
        />
      </CustomPopover>
    </div>
  );
};
