import React from 'react';

export interface ICustomModalProps {
  message?: string;
  yesButtonName?: string;
  noButtonName?: string;
  onClickYes?: () => void;
  onClickNo?: () => void;
  children?: React.ReactNode;
}

const CustomDialog = ({
  message,
  onClickNo,
  onClickYes,
  yesButtonName,
  noButtonName,
  children,
}: ICustomModalProps) => {
  return (
    <div style={{ backgroundColor: '#f2f2f2', padding: '30px' }}>
      {children && children}
      {message && <p className="modal_message">{message}</p>}
      {yesButtonName && onClickNo && onClickYes && noButtonName && (
        <div className="modal_buttons_wrapper">
          <button onClick={onClickNo} className="modal_button_disaggre">
            {noButtonName}
          </button>
          <button onClick={onClickYes} className="modal_button_aggre">
            {yesButtonName}
          </button>
        </div>
      )}
    </div>
  );
};

export default CustomDialog;
