import { AxiosResponse } from 'axios';
import { IConfig, IConfigPatch, ISttStatus } from '../components/Home/ISettings';
import { config } from '../config';
import axios from './axios';
import { API, CLIENT, CONFIG, STATUS, STT } from './endpoints';

const backendUrl: string = config.backendApiUrl;
const baseUrl = `${backendUrl}/${API}/${CLIENT}/${CONFIG}`;

export const getConfiguration = (): Promise<AxiosResponse<IConfig>> => axios.get(`${baseUrl}`);

export const setConfiguration = (cfg: IConfigPatch) => axios.patch(`${baseUrl}`, cfg);

const sttUrl = `${backendUrl}/${API}/${STT}/${STATUS}`;
export const getSttStatus = (): Promise<AxiosResponse<ISttStatus>> => axios.get(`${sttUrl}`);
