import { config } from '../config';
import axios from './axios';
import { API, CLIENT, REPLACEMENTS } from './endpoints';

const backendUrl: string = config.backendApiUrl;
const baseUrl = `${backendUrl}/${API}/${CLIENT}/${REPLACEMENTS}`;

export interface IReplacementWord {
  text: string;
  spaceBefore: boolean;
}

export interface INewReplacement {
  source: IReplacementWord[];
  target: IReplacementWord;
}

export const getReplacements = () => {
  return axios.get(baseUrl);
};

export const getReplacement = (id: number) => {
  return axios.get(`${baseUrl}/${id}`);
};

export const deleteReplacement = (id: number) => {
  return axios.delete(`${baseUrl}/${id}`);
};

export const postNewReplacement = (newReplacement: INewReplacement) => {
  return axios.post(`${baseUrl}`, newReplacement, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
